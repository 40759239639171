import { Expected } from "@/services/requests.js";

function cleanPayload(payload) {
   if (!payload) return;
   if (payload.hasOwnProperty("balance") && payload.balance) {
      let contribution = String(payload.balance).replace(",", ".");
      contribution = parseFloat(contribution) * 100;
      payload.balance = Math.round(contribution);
   }

   if (payload.hasOwnProperty("salary") && payload.balance) {
      payload.salary = payload.salary.toString().replace(/\D/gim, "") * 100;
   }
   if (payload.hasOwnProperty("monthly_contribution") && payload.monthly_contribution) {
      let contribution = String(payload.monthly_contribution).replace(",", ".");
      contribution = parseFloat(contribution) * 100;
      payload.monthly_contribution = Math.round(contribution);
   }
   if (
      payload.hasOwnProperty("susep_process") &&
      payload.susep_process &&
      payload.susep_process.length > 0
   ) {
      payload.susep_process = payload.susep_process.replace(/\D/gim, "");
   }
   if (payload.hasOwnProperty("cnpj") && payload.cnpj) {
      payload.cnpj = payload.cnpj.replace(/\D/gim, "");
   }

   if (payload.hasOwnProperty("cnpb") && payload.cnpj) {
      payload.cnpb = payload.cnpb.replace(/\D/gim, "");
   }
   if (payload.hasOwnProperty("gross_balance") && payload.gross_balance) {
      let contribution = String(payload.gross_balance).replace(",", ".");
      contribution = parseFloat(contribution) * 100;
      payload.gross_balance = Math.round(contribution);
   }
   if (payload.hasOwnProperty("fund") && payload.fund && payload.fund.length > 0) {
      payload.fund.forEach(fund => {
         if (fund.cnpj) {
            fund.cnpj = fund.cnpj.replace(/\D/gim, "");
         }
      });
   }
}

function createRequest(request, method, urlTemplate, expected, isPayload = true) {
   return async payloadOrId => {
      let payloadCopy;
      if (isPayload) {
         payloadCopy = JSON.parse(
            JSON.stringify(method === "PUT" ? payloadOrId.body : payloadOrId)
         );
         cleanPayload(payloadCopy);
      }
      const url = urlTemplate.replace("${id}", isPayload ? payloadOrId.id : payloadOrId);

      const requestOptions = isPayload ? { url, body: payloadCopy } : { url };

      return await request[method](requestOptions, expected);
   };
}

export default class Investments {
   constructor(request) {
      this.moduleName = "investments";
      this.prefix = "investments/";
      this.edit = "edit/";
      this.extend = {
         create: "create/",
         privatePension: "private-pension/",
         funds: "funds/",
         fixedIncome: "fixed-income/",
         stocks: "stocks/",
         coe: "coe/",
         pension: "pension/"
      };

      this.request = request;

      const createMethods = path => ({
         create: createRequest(
            request,
            "POST",
            this.prefix + path + this.extend.create,
            Expected.add({ 201: `${path} created` }),
            true
         ),
         edit: createRequest(
            request,
            "PUT",
            this.prefix + path + this.edit + "${id}/",
            Expected.add({ 200: `${path} edit` }),
            true
         ),
         get: createRequest(
            request,
            "GET",
            this.prefix + path + this.edit + "${id}",
            Expected.add({ 200: `${path} get` }),
            false
         ),
         delete: createRequest(
            request,
            "DELETE",
            this.prefix + path + this.edit + "${id}/",
            Expected.add({ 204: `${path} delete` }),
            false
         )
      });

      this.routes = {
         "private-pension": createMethods(this.extend.privatePension),
         "investment-funds": createMethods(this.extend.funds),
         "fixed-income": createMethods(this.extend.fixedIncome),
         stocks: createMethods(this.extend.stocks),
         coe: createMethods(this.extend.coe),
         pension: createMethods(this.extend.pension)
      };
   }
}
