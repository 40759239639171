<template>
   <div id="helpDeskButton" :modal="getHasModal" @click="() => hasModal()">
      <v-tooltip
         v-if="$vuetify.breakpoint.mdAndUp && !chatUserEnable"
         color="primary"
         right
         z-index="999"
      >
         <template v-slot:activator="{ on, attrs }">
            <v-btn
               class="helpDeskButton"
               fab
               @click="() => help('show')"
               v-bind="attrs"
               v-on="on"
               color="primary"
               small
            >
               <v-icon color="secondary">mdi-help</v-icon>
            </v-btn>
         </template>
         <span>Ajuda</span>
      </v-tooltip>
      <v-btn
         v-else-if="$vuetify.breakpoint.smAndDown && onlyDefault && !chatUserEnable"
         class="helpDeskButton"
         fab
         @click="() => help('show')"
         color="primary"
         small
      >
         <v-icon color="secondary">mdi-help</v-icon>
      </v-btn>
      <v-speed-dial
         v-else-if="moreDefault && $vuetify.breakpoint.smAndDown"
         v-model="fab"
         :top="top"
         :bottom="bottom"
         :right="right"
         :left="left"
         :direction="direction"
         :transition="transition"
      >
         <template v-slot:activator>
            <v-btn v-model="fab" color="primary" dark fab small :disabled="getHasModal">
               <v-icon color="secondary" v-if="fab"> mdi-close </v-icon>
               <v-icon color="secondary" v-else> mdi-plus </v-icon>
            </v-btn>
         </template>
         <template v-for="(option, index) in allOptions">
            <v-tooltip left v-model="tooltips" :color="option.color" :key="index">
               <template v-slot:activator="{ on, attrs }">
                  <v-btn
                     fab
                     @click="clicked(option)"
                     v-bind="attrs"
                     v-on="on"
                     :color="option.color"
                     small
                  >
                     <v-icon v-text="option.icon" color="secondary"></v-icon>
                  </v-btn>
               </template>
               <span class="secondary--text" v-text="option.tooltipText"></span>
            </v-tooltip>
         </template>
      </v-speed-dial>
   </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
   name: "HelpDeskButton",
   methods: {
      ...mapMutations(["help"]),
      clicked({ name, clicked }) {
         if (name === "help") {
            this.help("show");
         } else {
            clicked();
         }
      },
      hasModal() {
         if (this.getHasModal) {
            this.fab = false;
         }
      }
   },
   computed: {
      ...mapGetters(["allOptions", "getHasModal", "chatUserEnable"]),
      onlyDefault() {
         return this.allOptions.length === 1;
      },
      moreDefault() {
         return this.allOptions.length > 1;
      }
   },
   data: () => ({
      direction: "top",
      fab: false,
      fling: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
      tooltips: true,
      overlay: true
   }),

   watch: {
      fab(val) {
         this.tooltips = false;
         val &&
            setTimeout(() => {
               this.tooltips = true;
               this.$nextTick(() => (this.tooltipsDisabled = true));
            }, 250);
      },
      top(val) {
         this.bottom = !val;
      },
      right(val) {
         this.left = !val;
      },
      bottom(val) {
         this.top = !val;
      },
      left(val) {
         this.right = !val;
      }
   }
};
</script>
