import { Requests } from "@/services/requests";

const ENDPOINTS = {
   SESSION: "core/log"
};

const MSG = {
   CREATE_SUCCESS: "Criado com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class Logging {
   constructor(request) {
      this.request = request;
      this.moduleName = "Logging";
      this.routes = {
         logging: {
            create: this.createLogging.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async createLogging(payload) {
      return this.makeRequest({
         url: ENDPOINTS.SESSION,
         method: "post",
         body: { ...payload },
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}
