import { Expected } from "@/services/requests.js";

export default class Notification {
   constructor(request) {
      this.moduleName = "notification";
      this.prefix = "notification/";

      this.request = request;

      this.routes = {
         notification: {
            notifications: async () => {
               return await this.request.GET(
                  {
                     url: this.prefix
                  },
                  Expected.add({
                     200: "Notifications listed successfully"
                  })
               );
            },
            visualized: async id_notification => {
               return this.request.PATCH(
                  {
                     url: this.prefix + id_notification + "/"
                  },
                  Expected.add({
                     200: "Notification vizualized"
                  })
               );
            }
         }
      };
   }
}
