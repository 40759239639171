<template>
   <div class="menuBuilder">
      <organization-logo
         :logo="organization.logo"
         :name="organization.corporate_name"
         :link="organization.link_redirect"
      />
      <div class="d-none d-sm-flex justify-center">
         <ClientAvatar v-if="isPlanner || existClient" :clientData="mixinData" />
      </div>
      <v-divider />
      <menu-links :links="menuLinks" />
   </div>
</template>

<script>
import MenuLinks from "../atoms/MenuLinks";
import OrganizationLogo from "../atoms/OrganizationLogo";
import Links from "../../helpers/link-generator";
import WatchClientId from "@/shared/mixins/WatchClientId";
import ClientAvatar from "@/shared/components/atomicDesign/molecules/ClientAvatar";

export default {
   name: "MenuBuilder",
   components: {
      OrganizationLogo,
      MenuLinks,
      ClientAvatar
   },
   computed: {
      menuLinks() {
         return Links[this.menuType].call(Links);
      },
      mixinData() {
         if (this.clientData) {
            return this.clientData;
         } else {
            return false;
         }
      },
      existClient() {
         return typeof this.$route.query.clientId === "number" && this.$route.name !== "perfil";
      }
   },
   mixins: [WatchClientId],
   props: {
      organization: {
         type: Object,
         required: true
      },
      "menu-type": {
         type: String,
         required: true
      },
      isPlanner: {
         type: [Boolean, Object, Array]
      }
   },
   methods: {},
   mounted() {
      if (this?.organization) {
         this.$store.dispatch("setFavicon", this.organization.favicon);
      }
   }
};
</script>
