import vuetify from "@/plugins/vuetify";
import { Mod } from "@/services/api";

function editVuetifyTheme(theme) {
   const THEME = vuetify.framework.theme.themes.light;

   if (theme.primary && theme.secondary) {
      THEME.primary = `#${theme.primary}`;
      THEME.secondary = `#${theme.secondary}`;
      THEME.anchor = `#${theme.primary}`;
   }
}

export default {
   actions: {
      async changeThemeColor({ commit }, theme) {
         await Mod.find("organization", "theme").update(theme);

         commit("updateTheme", theme);
      },
      async changeLogo(_commit, payload) {
         await Mod.find("organization", "theme").updateLogo(payload);
      },
      editTheme({ commit }, theme) {
         commit("updateTheme", theme);
      },
      readConfigTheme({ commit }) {
         const THEME = localStorage.themeConfig ? JSON.parse(localStorage.themeConfig) : false;

         if (THEME) {
            commit("updateTheme", THEME);
            return true;
         } else {
            commit("useDefaultTheme");
            return false;
         }
      }
   },
   mutations: {
      updateTheme(state, theme) {
         const THEME = { primary: theme.primary, secondary: theme.secondary };

         state.theme = THEME;

         localStorage.themeConfig = JSON.stringify(THEME);

         editVuetifyTheme(THEME);
      },
      useDefaultTheme(state) {
         editVuetifyTheme(state.theme);
      }
   },
   state: {
      theme: {
         primary: "ffffff",
         secondary: "ffffff"
      }
   },
   getters: {
      colorTheme: state => state.theme
   }
};
