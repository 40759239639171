import Router from "@/router";

export default function sendTo(pageName) {
   let orgConfig = localStorage.organizationConfig;
   let isOrgValid = orgConfig ? orgConfig.valid : false;

   let currentPageName = Router.currentRoute.name;

   if (currentPageName == pageName) {
      return;
   } else {
      Router.push({
         name: pageName,
         ...(isOrgValid && {
            params: { organization: JSON.parse(orgConfig).name }
         })
      }).catch(() => {});
   }
}
