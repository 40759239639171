import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class Module {
   constructor(request) {
      this.moduleName = "charts";
      this.prefix = "chart-data/";

      this.request = request;

      this.routes = {
         cashflow: {
            get: async slug => {
               const query = Query.build(slug);
               const endpoint = "cash-flow/";

               return await this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            }
         },
         cashflowValue: {
            get: async slug => {
               const query = Query.build(slug);
               const endpoint = "cash-flow-value/";

               return await this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            }
         },
         description: {
            get: async slug => {
               const query = Query.build(slug);
               const endpoint = "description/";

               return await this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            }
         },
         category: {
            get: async slug => {
               const query = Query.build(slug);
               const endpoint = "category/";

               return await this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            }
         },
         types: {
            get: async slug => {
               const query = Query.build(slug);
               const endpoint = "types/";

               return await this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            }
         }
      };
   }
}
