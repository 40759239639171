import { Mod } from "@/services/api";
import filterClient from "@/views/managerClients/utils/filterClientList.js";

export default {
   state: {
      clientList: [],
      planList: {},
      clientListFiltered: null
   },
   getters: {
      managerClientList: state => state.clientListFiltered || state.clientList,
      planIdList: state => state.planList
   },
   actions: {
      async getPlannerClients({ dispatch, commit }) {
         let clientList = await dispatch("getActiveClients", "manager");

         commit("updateClientList", clientList);
      },
      async getActivePlans({ commit }) {
         let planList = await Mod.find("payment", "plans").get();

         commit("updatePlanList", planList.data.data);
      }
   },
   mutations: {
      updateClientList(state, clientList) {
         state.clientList = clientList;
         state.clientList.map(c => (c.id = c.user.id));
      },
      updatePlanList(state, planList) {
         for (let key in planList) {
            state.planList[planList[key].id] = planList[key].name;
         }
      },
      filterClientList(state, props) {
         state.clientListFiltered = filterClient(state.clientList, props) || null;
      }
   }
};
