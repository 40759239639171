import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class Balance {
   constructor(request) {
      this.moduleName = "balance";
      this.prefix = "balance/";

      this.extends = {
         create: "create/",
         update: "edit/"
      };

      this.request = request;

      this.routes = {
         balance: {
            create: async payload => {
               return await this.request.POST(
                  {
                     url: this.prefix + this.extends.create,
                     body: { ...payload }
                  },
                  Expected.add({
                     201: "Saldo criado"
                  })
               );
            },
            get: async reference_month => {
               const query = Query.build(reference_month);
               return this.request.GET(
                  {
                     url: this.prefix + query
                  },
                  Expected.add({
                     200: "Get balance"
                  })
               );
            },
            update: async payload => {
               return await this.request.PUT(
                  {
                     url: this.prefix + this.extends.update + payload.id + "/",
                     body: { ...payload }
                  },
                  Expected.add({
                     200: "Saldo atualizado"
                  })
               );
            }
         }
      };
   }
}
