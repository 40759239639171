export default class Me {
   constructor(request) {
      this.moduleName = "me";
      this.prefix = "me/";

      this.request = request;

      this.routes = {
         info: {
            get: this.getMeInfo
         }
      };
      this.bind(this.routes, this);
   }

   async getMeInfo(headers) {
      const expectedStatus = {
         200: "Informações coletadas",
         default: "Sistema indisponível. Tente novamente mais tarde."
      };

      return this.request.GET(
         {
            url: this.prefix,
            ...(headers && { headers })
         },
         expectedStatus
      );
   }
}
