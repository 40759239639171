import { Requests } from "@/services/requests";

const ENDPOINTS = {
   RESET: "tour/"
};

const MSG = {
   CREATE_SUCCESS: "Criado com sucesso",
   GET_ALL_SUCCESS: "Retornados com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class Tour {
   constructor(request) {
      this.request = request;
      this.moduleName = "Tour";
      this.routes = {
         reset: {
            create: this.createReset.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async createReset() {
      return this.makeRequest({
         url: ENDPOINTS.RESET,
         method: "post",
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}
