import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class Me {
   constructor(request) {
      this.moduleName = "helpDesk";
      this.prefix = "help/article/";

      this.alternative = {
         userPreference: "help/user-preference/"
      };

      this.request = request;

      this.routes = {
         slugSearch: {
            get: async slug => {
               const query = Query.build(slug);

               return await this.request.GET(
                  {
                     url: this.prefix + query
                  },
                  Expected.add({
                     200: "Help desk recebido"
                  })
               );
            },
            autoPlay: async slug => {
               const query = Query.build({ ...slug, autoplay: "true" });

               return await this.request.GET(
                  {
                     url: "help/onboarding/" + query
                  },
                  Expected.add({
                     200: "Help desk recebido"
                  })
               );
            },
            updatePreference: async articleId => {
               return await this.request.POST(
                  {
                     url: this.alternative.userPreference,
                     body: {
                        articleId
                     }
                  },
                  Expected.add({
                     201: "User preference updated"
                  })
               );
            }
         }
      };
   }
}
