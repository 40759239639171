const initial = 0,
   pending = 1,
   finished = 2;

export default class Loader {
   constructor() {
      this.status = initial;
      this.delay = 500;
   }
   isLoading() {
      this.status = pending;
   }
   isDone() {
      this.status = finished;

      setTimeout(() => (this.status = initial), this.delay);
   }
   changeToInitial() {
      this.status = initial;
   }
   set(command) {
      switch (command) {
         case "initial":
            this.changeToInitial();
            break;
         case "loading":
            this.isLoading();
            break;
         case "done":
            this.isDone();
            break;
         case "error":
            this.changeToInitial();
            break;
         default:
            this.isDone();
      }
   }
   setter($s) {
      $s = $s || "loading";
      this.set($s);
   }
}
