//Mock
import pendency from "@/__mocks__/pendency-list-mock";
import { Mod } from "@/services/api";

const TESTING = false;

export default {
   state: {
      file_errors: {}
   },
   getters: {
      listErrors: state => state.file_errors
   },
   actions: {
      async getPendencys({ commit }, payload) {
         let files = TESTING ? pendency : await Mod.find("pendency", "pendencys").get(payload);
         let pendencys = files.data.data;

         if (TESTING) {
            commit("setFilesErrorsList", files);
            return;
         } else {
            commit("setFilesErrorsList", pendencys);
            return;
         }
      },
      updateFileInfo(_commit, payload) {
         return Mod.find("pendency", "pendencys").update(payload);
      }
   },
   mutations: {
      setFilesErrorsList(state, payload) {
         state.file_errors = payload;
      }
   }
};
