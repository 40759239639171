export default class Client {
   constructor(request) {
      this.moduleName = "client";
      this.prefix = "client/";

      this.request = request;

      this.routes = {
         subscription: {
            cancel: this.cancelSubscription
         }
      };
      this.bind(this.routes, this);
   }

   async cancelSubscription(payload) {
      const expectedStatus = {
         200: "Assinatura cancelada",
         default: "Não foi possível cancelar sua assinatura"
      };

      return await this.request.POST(
         {
            url: this.prefix + "cancel-subscription/",
            body: { ...payload.body }
         },
         expectedStatus
      );
   }
}
