import Vue from "vue";
import Vuex from "vuex";

import { ReturnError } from "@/utils/error";

import stats from "@/utils/stats";
import Api from "../services/api";
import sessionLocal from "../utils/session";

// Mocks
import organizationMock from "../__mocks__/organization-mock";

// Modules
import budgetPlan from "./modules/budgetPlan";
import client from "./modules/client";
import clientTransaction from "./modules/clientTransaction";
import planner from "./modules/planner";
import prospects from "./modules/prospects";
import review from "./modules/review";
import session from "./modules/session";
import tasks from "./modules/tasks";
import uploader from "./modules/uploader";
import user from "./modules/user";

// New Module Version
import AuthValidation from "./authValidation";
import Accordions from "./modules/accordions";
import Alert from "./modules/alert";
import Assets from "./modules/assets";
import Theme from "./modules/core/theme";
import FileErrors from "./modules/fileErrors";
import Filters from "./modules/filters";
import FutureFlow from "./modules/futureFlow";
import HelpDesk from "./modules/helpDesk";
import Integration from "./modules/integration";
import Manager from "./modules/manager";
import MobileMenu from "./modules/mobileMenu";
import Notices from "./modules/notices";
import Notifications from "./modules/notifications";
import Origin from "./modules/origin";
import Performance from "./modules/performance";
import Register from "./modules/register";
import Reminder from "./modules/reminder";
import BankingV2 from "@/store/modules/bankingV2";
import CustomEmail from "@/store/modules/customEmail";
import Favicon from "@/store/modules/favicon";
import Bank from "@/store/modules/bank";
import PlannerChat from "./modules/plannerChat";
import Billing from "./modules/billing";
import CreditCard from "./modules/creditCard";
import Tour from "@/store/modules/tour";

Vue.use(Vuex);

const DEFAULT = {
   primary: "#fff",
   secondary: "#fff"
};

const DEPRECATED_MODULES = {
   budgetPlan,
   client,
   clientTransaction,
   planner,
   prospects,
   review,
   user,
   tasks,
   uploader,
   session
};

const MODULES = {
   Accordions,
   Assets,
   AuthValidation,
   Filters,
   FutureFlow,
   HelpDesk,
   Integration,
   Manager,
   Performance,
   Register,
   Theme,
   Notifications,
   Notices,
   FileErrors,
   Origin,
   MobileMenu,
   Reminder,
   Alert,
   BankingV2,
   CustomEmail,
   Favicon,
   Bank,
   PlannerChat,
   Billing,
   CreditCard,
   Tour
};

export default new Vuex.Store({
   modules: {
      ...DEPRECATED_MODULES,
      ...MODULES
   },
   state: {
      clientToken: "",
      userInfo: {
         name: "",
         level: null,
         email: "",
         tel: "",
         organization: {}
      },
      theme: {
         light: {
            primary: DEFAULT.primary,
            secondary: DEFAULT.secondary
         }
      },
      organizationData: null,
      filtered: false
   },

   getters: {
      getCompanyName: state => state.userInfo.organization.corporate_name || null,
      getUserHash: state => state.userInfo.hash,
      isCategorizing: state => {
         return { start: state.userInfo.dateStart, end: state.userInfo.dateEnd };
      },
      organizationData: state => state.organizationData,
      plannerHash: state => state.userInfo.hash,
      userInfo: state => state.userInfo,
      userPermissions: state =>
         state.userInfo.permissions
            ? state.userInfo.permissions.map(permission => permission.codename)
            : [],
      userFullName: state => `${state.userInfo.first_name || ""} ${state.userInfo.last_name || ""}`,
      isDevelopmentEnviornment: () => process.env.VUE_APP_IS_LOCAL === "true"
   },

   mutations: {
      brandOrganization(state, orgSlug) {
         if (orgSlug) {
            localStorage.setItem("organization", orgSlug);
         }
      },
      downloadXLSTemplate(state, xlsLink) {
         let aTag = document.createElement("a");
         aTag.href = xlsLink;
         aTag.setAttribute("download", "template.xlsx");
         aTag.click();
      },
      forceClearAuth() {
         this.userInfo = {};
         sessionLocal.clear();
      },
      newOrganization(state, org) {
         state.organizationData = org;
      },
      setMe: function(state, userData) {
         state.userInfo = { ...userData };
      },
      setToken(state, token) {
         state.clientToken = token;
         sessionLocal.set(token);
      }
   },

   actions: {
      async sendCreditCard(store, cardData) {
         return await Api.creditCardUpdate(cardData);
      },

      async editProfile({ dispatch }, profile) {
         let request = await Api.editProfile({ ...profile });

         if (request.status === 200) {
            dispatch("doUpdateMe");
         } else {
            throw new ReturnError({
               text: request.data.old_password[0],
               status: request.status
            });
         }
      },

      fetchUser(store, header) {
         return Api.fetchUser({
            headers: { ...header }
         });
      },

      findCep(store, cep) {
         return Api.findCep(cep);
      },

      async getSessionStatus({ commit }) {
         const hasToken = sessionLocal.token();

         if (!hasToken) {
            throw new ReturnError(stats.noToken);
         } else {
            let userInfo = await Api.getMe();
            if (!userInfo) {
               throw new ReturnError({
                  text: "Sem conexão com servidor",
                  status: 502
               });
            }
            if (!userInfo.data.organization) {
               throw new ReturnError({
                  text: "Usuário sem organização",
                  status: 403
               });
            }
            commit("setMe", userInfo.data);
         }
      },

      sendEmail(store, emailData) {
         const { header, url } = emailData;

         return Api.sendEmail({
            ...(header && {
               header
            }),
            ...(url && {
               url
            })
         });
      },

      /**
       * @deprecated
       * @param commit
       * @param dispatch
       * @param {*} organizationName
       * ? This function shouldn't be used anymore
       */
      async setOrganizationLayout({ commit, dispatch }, organizationName) {
         const getDefaultColorTheme = savedOrg => ({
            primary: savedOrg ? savedOrg.primary_color : organizationMock.primary_color,
            secondary: savedOrg ? savedOrg.secondary_color : organizationMock.secondary_color
         });

         const getSavedOrganization = () => {
            const savedOrg = localStorage.org ? JSON.parse(localStorage.org) : {};
            return {
               savedOrg,
               savedSlug: localStorage.getItem("organization")
            };
         };

         const { savedOrg, savedSlug } = getSavedOrganization();
         let colorTheme;

         try {
            const response = await Api.org(organizationName);
            const orgData = response.data;

            colorTheme = {
               primary: orgData.primary_color,
               secondary: orgData.secondary_color
            };

            commit("newOrganization", orgData);
            commit("brandOrganization", orgData.slug);

            commit("updateTheme", colorTheme);
            dispatch("setFavicon", orgData.favicon);
            return orgData;
         } catch (error) {
            colorTheme = getDefaultColorTheme(savedOrg);

            commit("newOrganization", savedOrg || organizationMock);
            commit("brandOrganization", savedSlug ? savedSlug.slug : organizationMock.slug);

            commit("updateTheme", colorTheme);
            return savedOrg || organizationMock;
         }
      },

      async doUpdateMe({ commit }) {
         const _me = await Api.getMe();
         commit("setMe", _me.data);
      },

      async registerCompany(store, data) {
         let registerResponse = await Api.registerCompany(data);

         switch (registerResponse.status) {
            case 201:
               return registerResponse;
            default:
               throw new ReturnError({
                  text: registerResponse.data.detail,
                  status: registerResponse.status
               });
         }
      },

      async updateCompany(store, data) {
         let registerResponse = await Api.updateCompany(data);

         switch (registerResponse.status) {
            case 200:
               return registerResponse;
            default:
               throw new ReturnError({
                  text: registerResponse.data.detail,
                  status: registerResponse.status
               });
         }
      }
   }
});
