import { Utils } from "@/utils/Utils";

/**
 * Common error
 *
 */
const error = {
   INVALID_LIST: "Received unexpected route list.",
   INVALID_ROUTE: "Received unexpected route param."
};

/**
 * Returns the menu index
 *
 * @param {string} slug The route name
 * @param {Array<object>} list The route list
 * @returns {number} The menu index
 */
const findLinkIndex = (slug, list) => {
   return list.findIndex(route => route.name === slug);
};

/**
 * Returns the active menu index, using the $route.name and list index.
 *
 * @param {object} param
 * @param {object} route The $route config
 * @param {Array<object>} list The menu list
 * @returns {number} The active menu index
 */
const activeMenuIndex = ({ route, list = [] }) => {
   if (!Utils.isArray(list)) {
      throw Error(error.INVALID_LIST);
   }
   if (!Utils.isObject(route)) {
      throw Error(error.INVALID_ROUTE);
   }

   return findLinkIndex(route.name, list);
};

export default activeMenuIndex;
