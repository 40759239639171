import { Expected } from "@/services/requests.js";

export default class Onboarding {
   constructor(request) {
      this.moduleName = "Onboarding";
      this.prefix = "onboarding/";

      this.extends = {
         tour: "tour/"
      };

      this.request = request;

      this.routes = {
         onboarding: {
            finishTour: async register_tour => {
               return this.request.PUT(
                  {
                     url: this.prefix + this.extends.tour,
                     body: { register_tour }
                  },
                  Expected.add({
                     200: "Resposta recebida"
                  })
               );
            }
         }
      };
   }
}
