// Services
import Api from "@/services/api";

export default {
   state: {
      prospectsList: []
   },

   getters: {
      prospectsList: state => state.prospectsList
   },

   actions: {
      async getProspects({ commit }) {
         let prospectsList = await Api.getProspects();
         commit("updateProspectList", prospectsList.data);
      }
   },

   mutations: {
      updateProspectList(state, list) {
         state.prospectsList = list;
      }
   }
};
