export default class ManagingPartner {
   constructor(request) {
      this.moduleName = "managingPartner";
      this.prefix = "managing-partners/";

      this.request = request;

      this.routes = {
         bank: {
            create: this.createManagingPartnerData,
            edit: this.editManagingPartnerData,
            get: this.getManagingPartnerData
         }
      };
      this.bind(this.routes, this);
   }
   async createManagingPartnerData(payload) {
      const expectedStatus = {
         201: "Dados criados",
         default: "Erro inesperado"
      };

      return await this.request.POST(
         {
            url: this.prefix,
            body: payload
         },
         expectedStatus
      );
   }
   async editManagingPartnerData(payload) {
      const expectedStatus = {
         200: "Dados editados",
         default: "Erro inesperado"
      };

      return await this.request.PUT(
         {
            url: this.prefix + `${payload.id}/`,
            body: payload
         },
         expectedStatus
      );
   }
   async getManagingPartnerData() {
      const expectedStatus = {
         200: "Dados recebidos",
         default: "Erro inesperado"
      };

      return await this.request.GET(
         {
            url: this.prefix
         },
         expectedStatus
      );
   }
}
