<template>
   <div class="navigationWrap">
      <Menu v-if="isAuthenticated && !hideFeatures" />
   </div>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/shared/components/sidebar/Menu.vue";
import "@/shared/scss/_menu.scss";

export default {
   name: "Navigation",
   components: {
      Menu
   },
   computed: {
      ...mapGetters(["userAuthenticated"]),

      isAuthenticated() {
         return !!this.userAuthenticated;
      },
      hideFeatures() {
         return !!this.$route.meta.hideMenuFeatures;
      }
   }
};
</script>
