import { Requests } from "@/services/requests";

const ENDPOINTS = {
   EDIT: "bill/edit/"
};

const MSG = {
   EDIT_SUCCESS: "EDITADO com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class Billing {
   constructor(request) {
      this.request = request;
      this.moduleName = "Billing";
      this.routes = {
         billing: {
            edit: this.editBilling.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async editBilling(payload) {
      const { id, ...bodyWithoutId } = payload;
      return this.makeRequest({
         url: ENDPOINTS.EDIT + id + "/",
         method: "PUT",
         body: bodyWithoutId,
         expectedStatus: {
            200: MSG.EDIT_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}
