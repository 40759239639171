import { Expected } from "@/services/requests.js";

export default class Budget {
   constructor(request) {
      this.moduleName = "budget";
      this.prefix = "budget-item/";
      this.alternativePrefix = "budget/";
      this.idealPrefix = "budget-ideal/";
      this.futureFlow = "future-flow/";
      this.idealItem = "ideal-item/";
      this.request = request;

      this.routes = {
         manage: {
            add: budgetConfig => {
               const { ideal } = budgetConfig;
               let url = this.prefix;
               if (ideal) {
                  url += "?budget_ideal=True";
               }
               const body = { ...budgetConfig };
               delete body.ideal;
               const data = {
                  url,
                  body
               };
               return this.request.POST(
                  data,
                  Expected.add({
                     200: "Resposta recebida",
                     201: "Plano criado"
                  })
               );
            },
            ideal: () => {
               return this.request.POST(
                  {
                     url: `${this.futureFlow}${this.idealPrefix}`
                  },
                  Expected.add({
                     200: "Resposta recebida",
                     201: "Plano criado"
                  })
               );
            },

            getIdeal: transferer => {
               return this.request.get(
                  {
                     url: `${this.idealPrefix}?asset=${transferer === true ? "True" : "False"}`
                  },
                  Expected.add({
                     200: "Resposta recebida"
                  })
               );
            },

            delete: budget => {
               return this.request.DELETE(
                  {
                     url: !budget.ideal
                        ? `${this.prefix}${budget.id}/`
                        : `${this.idealPrefix}${this.idealItem}${budget.id}`
                  },
                  Expected.add({
                     200: "Resposta recebida",
                     204: "Plano deletado"
                  })
               );
            },

            deleteMultiple: planPayload => {
               return this.request.PUT(
                  {
                     url: `${this.alternativePrefix +
                        planPayload.taskId}/delete-items/?budget_ideal=${
                        planPayload.ideal ? "True" : "False"
                     }`,
                     body: [...planPayload.items]
                  },
                  Expected.add({
                     200: "Resposta recebida",
                     204: "Plano deletado"
                  })
               );
            }
         }
      };
   }
}
