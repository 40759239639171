import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class Tasks {
   constructor(request) {
      this.moduleName = "tasks";
      this.prefix = "tasks/";
      this.extends = {
         task: "budget-planning/",
         tasks: "task/"
      };

      this.request = request;

      this.routes = {
         clientTasks: {
            get: this.getTasks,
            budget: this.getBudgetTasks
         },
         plannerTasks: {
            budget: this.getBudgetPlan,
            getTaskList: this.getTaskList
         }
      };
      this.bind(this.routes, this);
   }

   getTasks(payload) {
      const expectedStatus = {
         200: "Tarefas carregadas",
         default: "Tarefas não encontradas"
      };

      let clientQuery = `?client=${payload.client}`;
      clientQuery += payload.status ? `&status=${payload.status}` : "";

      return this.request.GET(
         {
            url: this.prefix + this.extends.task + clientQuery
         },
         expectedStatus
      );
   }

   getBudgetTasks(payload) {
      const query = Query.build(payload);
      const endpoint = "budget-planning/";

      return this.request.GET(
         {
            url: this.prefix + endpoint + query
         },
         Expected.add({
            200: "Dados recebidos"
         })
      );
   }

   getBudgetPlan(payload) {
      const expectedStatus = {
         200: "Tarefas carregadas",
         default: "Tarefas não encontradas"
      };

      const clientQuery = `${payload.taskId}/?client=${payload.client}`;

      return this.request.GET(
         {
            url: this.prefix + this.extends.task + clientQuery
         },
         expectedStatus
      );
   }

   getTaskList(payload) {
      const expectedStatus = {
         200: "Tarefas carregadas",
         default: "Tarefas não encontradas"
      };

      let clientQuery = `?client=${payload.client}`;
      clientQuery += payload.status ? `&status=${payload.status}` : "";

      return this.request.GET(
         {
            url: this.prefix + this.extends.tasks + clientQuery
         },
         expectedStatus
      );
   }
}
