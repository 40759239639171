import { Mod } from "@/services/api";
import { Utils } from "@/utils/Utils";
import Mock from "@/__mocks__/future-flow-mock.js";

const INVALID_CHART_NAME = "No such chart name found.";
const TESTING = false;

export default {
   state: {
      cashflow: null,
      cashflowValue: null,
      descriptionMetrics: null,
      categoryMetrics: null,
      typeMetrics: null,
      futureFlowPrediction: null
   },
   getters: {},
   actions: {
      async getChartData({ commit }, config) {
         let req;

         try {
            switch (config.name) {
               case "cash-flow": {
                  req = await Mod.find("charts", "cashflow").get(config.filter);

                  commit("updateChartData", {
                     type: "cashflow",
                     chart: req.data.data
                  });
                  return req.data.data;
               }

               case "description-metrics": {
                  req = await Mod.find("charts", "description").get(config.filter);

                  commit("updateChartData", {
                     type: "descriptionMetrics",
                     chart: req.data.data
                  });
                  return req.data.data;
               }

               case "category-metrics": {
                  req = await Mod.find("charts", "category").get(config.filter);

                  commit("updateChartData", {
                     type: "categoryMetrics",
                     chart: req.data.data
                  });
                  return req.data.data;
               }

               case "types-metrics": {
                  req = await Mod.find("charts", "types").get(config.filter);

                  commit("updateChartData", {
                     type: "typeMetrics",
                     chart: req.data.data
                  });
                  return req.data.data;
               }

               case "cash-flow-value": {
                  req = await Mod.find("charts", "cashflowValue").get(config.filter);

                  commit("updateChartData", {
                     type: "cashflowValue",
                     chart: req.data.data
                  });
                  return req.data.data;
               }

               case "future-flow-prediction": {
                  if (TESTING) {
                     req = await Mock.futureFlowPrediction();
                  } else {
                     req = await Mod.find("futureFlow", "metrics").prediction(config.query);
                  }

                  commit("updateChartData", {
                     type: "cashflowValue",
                     chart: req.data.data
                  });

                  return req.data.data;
               }

               default:
                  throw INVALID_CHART_NAME;
            }
         } catch (err) {
            throw Error(err);
         }
      }
   },
   mutations: {
      updateChartData(state, data) {
         if (Utils.isArray(data?.chart) && data?.chart[0]?.amount) {
            data.chart = Utils.order.byValue({
               type: "object",
               sort: "descending",
               list: data.chart,
               keyName: "amount"
            });
         }

         state[data.type] = data.chart;
      }
   }
};
