// App
import App from "./App.vue";
// libraries
import Vue from "vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueAnalytics from "vue-analytics";
import { VueMaskDirective } from "v-mask";
import VueEllipseProgress from "vue-ellipse-progress";
import Croppa from "vue-croppa";
//Interceptors
import "./services/interceptors";

// Sentry
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import "./registerServiceWorker";

const IS_PRODUCTION = process.env.NODE_ENV == "production";
const IS_LOCAL = process.env.VUE_APP_IS_LOCAL == "true";
const IS_STAGING = process.env.VUE_APP_IS_STAGING == "true";

// Vue Mask
Vue.config.productionTip = false;
Vue.directive("mask", VueMaskDirective);

// Analytics
Vue.use(VueAnalytics, {
   id: process.env.VUE_APP_ANALYTICS_KEY,
   router
});

// Vue Ellipse Progress
Vue.use(VueEllipseProgress);

//Vue Croppa
Vue.use(Croppa);

// Sentry Config
if (IS_PRODUCTION) {
   Sentry.init({
      dsn: `https://b9026eedb68d4c0ba95983481799c0e5@o293581.ingest.sentry.io/5286809`,
      integrations: [new VueIntegration({ Vue, attachProps: true })],
      debug: IS_STAGING ? true : false,
      beforeSend: (event, hint) => {
         if (hint) {
            const ERROR = hint.originalException;
            let errorMessage;

            if (typeof ERROR === "string") {
               errorMessage = ERROR;
            } else if (ERROR instanceof Error) {
               errorMessage = ERROR.message;
            }

            switch (errorMessage) {
               case "timeout":
               case "Invalid to header":
               case "Invalid To header":
               case "Mailbox not enabled":
               case "Auth window was closed by user":
               case "The request timed out.":
               case "Network request failed":
               case "Mail service not enabled":
               case "apiClient is null - call configure first":
               case "Backend Error":
                  return null;
               default:
                  break;
            }
         }

         return event;
      }
   });
}

// DevTools Config
if (IS_LOCAL || IS_STAGING) {
   Vue.config.devtools = true;
} else {
   Vue.config.devtools = false;
}

new Vue({
   router,
   store,
   vuetify,
   render: h => h(App)
}).$mount("#app");
