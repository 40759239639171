import Router from "@/router";

const BAD_NAMES = ["null", "undefined", "nao-encontrado"];

export default function storedOrganizationName() {
   const ORGANIZATION = localStorage.organizationConfig
      ? JSON.parse(localStorage.organizationConfig)
      : {};

   let isOrgValid = ORGANIZATION ? ORGANIZATION.valid : false;

   if (isOrgValid) {
      if (BAD_NAMES.includes(ORGANIZATION.name)) {
         return false;
      } else {
         return ORGANIZATION.name;
      }
   } else {
      const ORG_NAME = Router.currentRoute.params.organization;

      if (BAD_NAMES.includes(ORG_NAME) || !ORG_NAME) {
         return false;
      } else {
         return ORG_NAME;
      }
   }
}
