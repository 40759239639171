<script>
//Vuex
import { mapActions, mapGetters } from "vuex";

export default {
   computed: {
      ...mapGetters(["userAuthenticated"]),
      getSlug() {
         return this.$route.meta.slug;
      }
   },
   methods: {
      ...mapActions(["helpDeskAutoPlay"]),
      async showTutorial(slug = null) {
         await this.helpDeskAutoPlay(slug == null ? this.getSlug : slug);
      }
   },
   async mounted() {
      if (this.userAuthenticated) {
         this.showTutorial();
      }
   }
};
</script>
