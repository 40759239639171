<template>
   <div>
      <template>
         <v-tooltip v-if="!isMobile" color="primary">
            <template v-slot:activator="{ onTooltip, attrsTooltip }">
               <v-btn
                  class="chatButton"
                  :class="{ chatButtonSmall: zoomLevel >= 1.5 }"
                  fab
                  @click.stop="openChat"
                  color="primary"
                  :small="$vuetify.breakpoint.mdAndDown || zoomLevel >= 1.5"
                  :medium="$vuetify.breakpoint.lgAndUp"
                  v-show="!chatMenu"
                  @click:outside="closeChat"
               >
                  <span class="desktop-label chat-label" v-show="zoomLevel < 1.5">Chat</span>
                  <v-icon color="secondary" :small="zoomLevel >= 1.5">mdi-chat</v-icon>
               </v-btn>
            </template>
            <span v-if="!chatMenu">Chat</span>
         </v-tooltip>
         <v-btn
            v-if="!chatMenu && isMobile"
            class="chatButton chatButtonMobile"
            fab
            text
            @click.stop="openChat"
            small
            v-show="!chatMenu"
            @click:outside="closeChat"
         >
            <v-icon color="primary">mdi-chat-outline</v-icon>
         </v-btn>
      </template>
      <v-menu
         offset-y
         :close-on-content-click="false"
         :right="$vuetify.breakpoint.mdAndUp"
         v-model="chatMenu"
         content-class="chat-menu"
         ref="chatMenuRef"
      >
         <v-card>
            <v-card-title class="chat-header">
               <img :src="plannerData.photo" width="40" height="40" alt="Imagem do planejador." />
               <h4>
                  {{ plannerData.name }}
               </h4>

               <v-spacer></v-spacer>
               <v-btn icon small @click="closeChat">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-card-title>
            <v-card-text>
               <div class="messages-container">
                  <transition name="fade" mode="out-in">
                     <div class="planner-message" v-show="showMessages[0]">
                        <div>
                           <p v-if="showMessages[0]" ref="message1">
                              Olá, {{ nome }}
                              <v-icon small>mdi-happy</v-icon>
                           </p>
                        </div>
                     </div>
                  </transition>
               </div>

               <div class="messages-container">
                  <transition name="fade" mode="out-in">
                     <div class="planner-message" v-show="showMessages[1]">
                        <div>
                           <p v-if="showMessages[1]" ref="message2">
                              Me chamo {{ plannerData.name }}, sou seu planejador financeiro, prazer
                              em te conhecer!
                              <v-icon small>mdi-happy</v-icon>
                           </p>
                        </div>
                     </div>
                  </transition>
               </div>

               <div class="messages-container">
                  <transition name="fade" mode="out-in">
                     <div class="planner-message" v-show="showMessages[2]">
                        <div>
                           <p v-if="showMessages[2]" ref="message3">
                              Como posso te ajudar?
                              <v-icon small>mdi-happy</v-icon>
                           </p>
                        </div>
                     </div>
                  </transition>
               </div>
               <div class="input-container">
                  <v-textarea
                     ref="userInput"
                     rows="2"
                     v-model="userMessage"
                     label="Digite sua mensagem"
                     class="user-input input-label-margin"
                     :max-rows="4"
                  >
                     <template #append>
                        <v-btn icon color="primary" @click="redirectToWhatsApp">
                           <v-icon>mdi-send</v-icon>
                        </v-btn>
                     </template>
                  </v-textarea>
               </div>
            </v-card-text>
         </v-card>
      </v-menu>
   </div>
</template>
<script>
import { mapState } from "vuex";
import "./scss/_chatMenu.scss";
export default {
   name: "ChatButton",

   methods: {
      redirectToWhatsApp() {
         if (this.userMessage) {
            const message = encodeURIComponent(this.userMessage);
            const phoneNumber = this.plannerData.phone.replace(/\D/g, "");
            const url = `https://wa.me/${phoneNumber}?text=${message}`;
            window.open(url, "_blank");
            this.userMessage = "";
         }
      },
      updatePlannerData() {
         if (this.persona && this.persona.planner && this.persona.planner.user) {
            this.plannerData.name = `${this.persona.planner.user.first_name} ${this.persona.planner.user.last_name}`;
            this.plannerData.email = this.persona.planner.user.email;
            if (
               this.persona.planner.hasOwnProperty("profile_picture") &&
               this.persona.planner.profile_picture
            ) {
               this.plannerData.photo = this.persona.planner.profile_picture;
            }
         }
         if (this.persona && this.persona.planner && this.persona.planner.telephone) {
            this.plannerData.phone = this.formatPhoneNumber(this.persona.planner.telephone);
         }

         if (this.persona && this.persona.first_name && this.persona.last_name) {
            this.nome = `${this.persona.first_name} ${this.persona.last_name}`;
         }
      },
      handleMobileChatOpen() {
         if (this.$vuetify.breakpoint.smAndDown) {
            this.$nextTick(() => {
               this.$refs.chatMenuRef.updateDimensions();
            });
         }
      },
      openChat() {
         this.chatMenu = true;
         this.handleMobileChatOpen();
         this.showMessagesWithDelay();
      },
      showMessageWithDelay(messageIndex, delay) {
         if (this.showMessages[messageIndex]) return;
         setTimeout(() => {
            this.$set(this.showMessages, messageIndex, true);
         }, delay);
      },
      showMessagesWithDelay() {
         if (!this.chatMenu) return;

         this.showMessageWithDelay(0, 1000);
         this.showMessageWithDelay(1, 2000);
         this.showMessageWithDelay(2, 4000);
      },
      closeChat() {
         this.chatMenu = false;
         this.userMessage = "";
      },
      formatPhoneNumber(phoneNumber) {
         const cleanedNumber = phoneNumber.replace(/\D/g, "");

         if (cleanedNumber.startsWith("55")) {
            return cleanedNumber;
         }

         return "55" + cleanedNumber;
      },
      updateZoomLevel() {
         this.zoomLevel = window.devicePixelRatio;
      }
   },
   data: () => ({
      chatDialog: false,
      userMessage: "",
      chatMenu: false,
      plannerData: {
         photo: "https://via.placeholder.com/80",
         name: "Nome do Planejador",
         email: "email@example.com",
         phone: "+55 11 99999-9999"
      },
      nome: "cliente",
      messageTimeouts: [null, null, null],
      showMessages: [false, false, false],
      zoomLevel: window.devicePixelRatio
   }),
   computed: {
      ...mapState({
         persona: store => store.user.persona
      }),
      isMobile() {
         return window.innerWidth <= 1360;
      }
   },
   watch: {
      persona() {
         this.updatePlannerData();
      }
   },
   mounted() {
      this.$nextTick(() => {
         this.updatePlannerData();
      });
      window.addEventListener("resize", this.updateZoomLevel);
   },
   beforeDestroy() {
      window.removeEventListener("resize", this.updateZoomLevel);
   }
};
</script>
