<template>
   <card-body-v2 modal ref="BankIntegrationAlert" title="Alerta de integração">
      <template v-slot:actions>
         <v-btn icon fab @click="() => close()"><v-icon>mdi-close</v-icon></v-btn>
      </template>
      <template v-slot:content>
         <p>
            Para concluir a integração de sua conta <strong>{{ bank.name }}</strong
            >, é necessário finalizar uma etapa de liberação em seu app do banco.
         </p>
         <v-stepper v-model="e1" elevation="0">
            <v-stepper-header>
               <template v-for="index in steps">
                  <v-stepper-step
                     :key="`${index}-step`"
                     :complete="e1 > index"
                     :step="index"
                     editable
                  >
                     {{ bank.steps[index - 1] }}
                  </v-stepper-step>

                  <v-divider v-if="index !== steps" :key="index"></v-divider>
               </template>
            </v-stepper-header>

            <v-stepper-items>
               <v-stepper-content v-for="index in steps" :key="`${index}-content`" :step="index">
                  <v-card class="mb-12" elevation="0">
                     <v-img height="500px" contain :src="bank.assets[index - 1]" />
                  </v-card>

                  <v-btn color="primary" @click="nextStep(index)" class="mx-4">
                     {{ index == steps ? "Finalizar" : "Próximo" }}
                  </v-btn>

                  <v-btn text @click="close">
                     fechar
                  </v-btn>
               </v-stepper-content>
            </v-stepper-items>
         </v-stepper>
      </template>
   </card-body-v2>
</template>

<script>
// Core
import { mapGetters } from "vuex";
// Utils
import bankList from "@/utils/bankIntegrationList";
export default {
   name: "BankIntegrationAlert",
   components: {
      CardBodyV2: () => import("@/shared/components/atomicDesign/molecules/CardBodyV2.vue")
   },
   data: () => ({
      bank_id: "",
      e1: 1
   }),
   computed: {
      ...mapGetters(["BankCode"]),
      bank() {
         return bankList[this.bank_id];
      },
      steps() {
         return this.bank?.steps.length;
      }
   },
   methods: {
      open(bank = null) {
         if (bank) {
            this.bank_id = bank;
            this.$refs.BankIntegrationAlert.openModal();
         }
      },
      close() {
         this.$refs.BankIntegrationAlert.closeModal();
      },
      nextStep(n) {
         if (n === this.steps) {
            this.e1 = 1;
            this.close();
         } else {
            this.e1 = n + 1;
         }
      }
   },
   watch: {
      steps(val) {
         if (this.e1 > val) {
            this.e1 = val;
         }
      },
      BankCode(val) {
         if (val) {
            this.open(val);
         }
      }
   }
};
</script>

<style lang="scss" scoped></style>
