const links = [
   {
      id: 301,
      name: "dashboard",
      text: "Dashboard",
      icon: "mdi-apps",
      permission: true
   },
   {
      id: 302,
      name: "prospect",
      text: "Prospect",
      icon: "mdi-account-multiple-outline",
      permission: "add_prospect"
   }
];

export default links;
