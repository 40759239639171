<template>
   <v-snackbar top rounded v-model="Alert.status" :vertical="vertical" :color="Alert.color">
      <span class="text-subtitle-2" v-text="Alert.text"></span>
   </v-snackbar>
</template>

<script>
//Core
import { mapGetters, mapMutations } from "vuex";
export default {
   data: () => ({
      vertical: true
   }),
   computed: {
      ...mapGetters(["Alert"])
   },
   methods: {
      ...mapMutations(["resetAlert"])
   }
};
</script>
