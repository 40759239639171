import CustomEmailService from "@/services/moduleFactory/modules/customEmail";
// Inicialize o serviço
const customEmailService = new CustomEmailService();

export default {
   state: {
      organizationEmailConfig: null
   },
   getters: {
      allCustomEmails: state => state.customEmails,
      organizationEmailConfig: state => state.organizationEmailConfig
   },
   actions: {
      async createCustomEmail({ commit }, payload) {
         try {
            const response = await customEmailService.createCustomEmail(payload);
            commit("ADD_CUSTOM_EMAIL", response.data);
         } catch (e) {
            throw e;
         }
      },
      async editCustomEmail({ commit }, { id, payload }) {
         try {
            const response = await customEmailService.editCustomEmail(id, payload);
            commit("SET_CUSTOM_EMAILS", response.data[0]);
         } catch (e) {
            throw e;
         }
      },
      async listCustomEmails({ commit }) {
         try {
            const response = await customEmailService.listCustomEmails();
            commit("SET_CUSTOM_EMAILS", response.data[0]);
            return response;
         } catch (e) {
            throw e;
         }
      },
      async deleteCustomEmail({ commit }, id) {
         try {
            await customEmailService.deleteCustomEmail(id);
            commit("REMOVE_CUSTOM_EMAIL", id);
         } catch (e) {
            throw e;
         }
      }
   },
   mutations: {
      ADD_CUSTOM_EMAIL(state, customEmail) {
         state.customEmails.push(customEmail);
      },
      UPDATE_CUSTOM_EMAIL(state, { id, updatedData }) {
         const index = state.customEmails.findIndex(email => email.id === id);
         if (index !== -1) {
            state.customEmails[index] = updatedData;
         }
      },
      SET_CUSTOM_EMAILS(state, customEmails) {
         state.customEmails = customEmails;
      },
      REMOVE_CUSTOM_EMAIL(state, id) {
         state.customEmails = state.customEmails.filter(email => email.id !== id);
      }
   }
};
