/**
 * This module is for general register actions and mutation control
 * eg: register related
 *
 * This module should make use only of the new API structure
 * Modules as Mod
 */

// Requests
import { Mod } from "@/services/api";

export default {
   state: {
      plans: {
         selected: null
      }
   },
   getters: {
      selectedPlan: state => state.plans.selected
   },
   actions: {
      async finishOrganizationRegister(store, company) {
         return await Mod.find("organization", "register").done(company);
      },
      async createOrganizationPlan(store, planData) {
         return await Mod.find("payment", "plans").create(planData);
      },
      async findPlanSelected({ commit }, clientData) {
         let plan = await Mod.find("payment", "plans").find(clientData);

         commit("registerPlan", plan.data.data);
         return plan.data;
      }
   },
   mutations: {
      registerPlan(state, plan) {
         state.plans.selected = plan;
      }
   }
};
