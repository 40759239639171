import Transform from "@/utils/dateManager";

export default function({ list, descriptionList }) {
   this.description = descriptionList ?? null;
   this.list = list;

   if (!this.description) {
      throw Error("labels is required");
   } else if (!this.description?.length) {
      return [];
   }

   this.getIds = function(descriptionData) {
      let descriptions = {};

      for (let descItem in this.description) {
         let self = this.description[descItem];
         descriptions[self.id] = self.name;
      }
      return descriptionData ? descriptions[descriptionData] : descriptions;
   };

   this.fixDescription = function() {
      this.list = this.list.map(item => {
         let { description, ...keys } = item;

         if (typeof description == "number") {
            return {
               description: {
                  value: description,
                  text: this.getIds(description)
               },
               ...keys
            };
         } else {
            return { description, ...keys };
         }
      });
   };

   if (Array.isArray(this.list)) {
      if (!this.list.length) {
         return this.list;
      }

      this.fixDescription();

      return this.list.map(item => {
         item.cash_date = Transform.toLocale(item.cash_date);
         item.date = Transform.toLocale(item.date);
         item.isExpense = item.amount < 0;

         return item;
      });
   } else {
      throw Error("list param must be an Array");
   }
}
