import { Mod } from "@/services/api";

export default {
   actions: {
      async createOrigin(_commit, payload) {
         return await Mod.find("manual-account", "manualAccount").add(payload);
      },
      async ignored(_commit, payload) {
         return await Mod.find("manual-account", "origin").ignored(payload);
      },
      async deleted(_commit, payload) {
         return await Mod.find("manual-account", "origin").deleted(payload);
      },
      async originsByMonth({ commit }, payload) {
         const dates = {
            dateStart: payload.start,
            dateEnd: payload.end
         };
         const origins = await Mod.find("manual-account", "origin").byMonth(
            dates,
            payload.clientId
         );
         commit("defineNewOrigin", origins.data.data);
         return origins;
      }
   }
};
