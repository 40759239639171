// Helpers
import { Transform } from "@/utils/dateManager";
import { Mod } from "@/services/api";

export default {
   state: {
      currentClient: {},
      client: {},
      filteredDate: {
         dateTo: "",
         dateFrom: ""
      }
   },

   getters: {
      getCurrentClientFullName: state => {
         const NAME = state.currentClient?.first_name;
         const LAST_NAME = state.currentClient?.last_name;

         if (NAME) {
            return `${NAME} ${LAST_NAME}`;
         } else {
            return state.currentClient.email;
         }
      },
      clientLastBundle: state => {
         if (state?.client?.last_import_bundle)
            return Transform.toLocale(
               new Date(state.client.last_import_bundle.created_at).toISOString().substr(0, 10)
            );
         else return "";
      }
   },

   actions: {
      async getCurrentClientInfo({ commit }, options) {
         let clientList = await Mod.find("profiles", "clients").get();

         clientList = clientList?.data?.data;
         if (clientList && clientList?.length) {
            let currentClient = null;

            currentClient = clientList.filter(c => c.user.id === options.client)[0];

            commit("setPlannersClientInfo", currentClient?.user);
            commit("setClient", currentClient);

            return currentClient;
         }
         return clientList;
      }
   },

   mutations: {
      setPlannersClientInfo(state, clientInfo) {
         state.currentClient = clientInfo;
      },
      setClient(state, info) {
         state.client = info;
      }
   }
};
