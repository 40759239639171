import { Expected } from "@/services/requests.js";

export default class Notices {
   constructor(request) {
      this.moduleName = "notices";
      this.prefix = "notification_settings/";

      this.request = request;

      this.routes = {
         notices: {
            get: async () => {
               return this.request.GET(
                  {
                     url: this.prefix
                  },
                  Expected.add({
                     200: "Notices"
                  })
               );
            },
            patch: body => {
               const expectedStatus = {
                  200: "Informações alteradas",
                  default: "Sistema indisponível. Tente novamente mais tarde."
               };
               return this.request.PATCH(
                  {
                     url: this.prefix,
                     body
                  },
                  expectedStatus
               );
            }
         }
      };
   }
}
