const links = [
   {
      id: 100,
      name: "cashRegister",
      text: "Meu Caixa",
      icon: "mdi-currency-usd",
      permission: true
   },
   {
      id: 101,
      name: "my-assets",
      text: "Meu Patrimônio",
      icon: "mdi-home-city-outline",
      permission: "my_assets_client"
   },
   {
      id: 102,
      name: "my-performance",
      text: "Desempenho",
      icon: "mdi-chart-pie",
      permission: "my_performance_client"
   },
   {
      id: 103,
      name: "meu-orcamento",
      text: "Meu Orçamento",
      icon: "mdi-cash-usd-outline",
      permission: "!future_flow"
   },
   {
      id: 105,
      name: "fluxo-futuro",
      text: "Fluxo futuro",
      icon: "mdi-poll",
      permission: "future_flow"
   },
   {
      id: 104,
      name: "tarefas",
      text: "Avisos & Importações",
      icon: "mdi-calendar-check-outline",
      permission: true
   }
];

export default links;
