import { Utils } from "./Utils";
import moment from "moment";

const Download = {
   _DOM: {
      tag: null
   },
   _blob: null,

   /* eslint-disable */
   xls({ file, clientData = null } = {}) {
      this._createFileBlob(file);
      let fileName;
      if (clientData == null) {
         moment.locale("pt-br");
         let today = moment().format("L");
         fileName = `Transacoes-${today}.xlsx`;
      } else {
         fileName = this._concatFileName(clientData, "xlsx");
      }
      return this._createClickableTag(fileName);
   },

   _createClickableTag(fileName) {
      this._DOM.tag = document.createElement("a");
      this._DOM.tag.href = window.URL.createObjectURL(this._blob);
      this._DOM.tag.setAttribute("download", fileName);
      this._DOM.tag.click();

      window.URL.revokeObjectURL(this._DOM.tag.href);

      return this._DOM.tag;
   },

   _concatFileName(clientData, extension = "xlsx") {
      if (Utils.isObject(clientData, true)) {
         let clientFullName = `${clientData.user.first_name.toLowerCase()}-${clientData.user.last_name.toLowerCase()}-resumo`;

         let fileName = clientFullName + "." + extension;

         return fileName;
      }
      return null;
   },

   _createFileBlob(file) {
      this._blob = new Blob([file], { type: "application/octet-stream" });
   }
};

export default Download;
