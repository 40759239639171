import pagarmeInstance from "@/services/pagarme";

export default class Payment {
   constructor(request) {
      this.moduleName = "payment";
      this.prefix = "payment/";
      this.extends = {
         card: "card/",
         plans: "plans/",
         bank: "bank-accounts/",
         subscription: "subscriptions/",
         reactive: "subscription/reactivate/"
      };

      this.request = request;

      this.routes = {
         card: {
            create: this.updateCreditCard,
            info: this.getCreditCardInformation,
            send: this.createClient
         },
         plans: {
            get: this.getOrganizationPlans,
            suggested: this.getSuggestedPlan,
            edit: this.editOrganizationPlan,
            create: this.createOrganizationPlan,
            find: this.findPlanWithHashedId
         },
         bank: {
            get: this.getBankAccountData,
            edit: this.editBankAccount,
            create: this.createBankAccount
         },
         subscription: {
            change: this.changeSubscriptionPlan,
            get: this.getPlannedSubscription,
            accept: this.acceptSubscriptionChange,
            reactive: this.reactivateSubscription
         }
      };
      this.bind(this.routes, this);
   }
   // Create Bank Account Data
   createBankAccount(payload) {
      const expectedStatus = {
         201: "Dados criados",
         default: "Não foi possível criar dados"
      };

      return this.request.POST(
         {
            url: this.prefix + this.extends.bank,
            body: payload
         },
         expectedStatus
      );
   }
   // Change Subscription Plan
   changeSubscriptionPlan(payload) {
      const expectedStatus = {
         201: "Inscrição alterada",
         default: "Erro inesperado"
      };

      return this.request.POST(
         {
            url: this.prefix + this.extends.subscription,
            body: payload
         },
         expectedStatus
      );
   }
   // Accept Subscription Change
   async acceptSubscriptionChange(payload) {
      const expectedStatus = {
         202: "Subscrição aceita",
         200: "Subscrição rejeitada",
         default: "Erro inesperado"
      };
      const declare = payload.accepts ? "accept/" : "reject/";

      return this.request.POST(
         {
            url: this.prefix + this.extends.subscription + `${payload.id}/${declare}`,
            body: {
               ...(payload.accepts && {
                  address: payload.address,
                  card_token: payload.creditCard,
                  cpf: payload.cpf
               })
            }
         },
         expectedStatus
      );
   }
   // Get Planned Subscription
   getPlannedSubscription() {
      const expectedStatus = {
         200: "Subscrição recebida",
         default: "Erro inesperado"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.subscription
         },
         expectedStatus
      );
   }
   // Edit Bank Account Data
   editBankAccount(payload) {
      const expectedStatus = {
         200: "Dados criados",
         default: "Não foi possível criar dados"
      };

      return this.request.PUT(
         {
            url: this.prefix + this.extends.bank + `${payload.id}/`,
            body: payload
         },
         expectedStatus
      );
   }
   // Edit Organization
   editOrganizationPlan(payload) {
      const expectedStatus = {
         200: "Cartão atualizado",
         default: "Não foi possível alterar plano"
      };

      const { id, ...body } = payload;

      return this.request.PUT(
         {
            url: this.prefix + this.extends.plans + `${id}/`,
            body: { ...body }
         },
         expectedStatus
      );
   }
   // Find plan using hash ID
   findPlanWithHashedId(payload) {
      const expectedStatus = {
         200: "Dados de plano coletados",
         default: "Sistema indisponível. Tente novamente mais tarde., plano não encontrado"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.plans + `find?hashedId=${payload.planHash}`,
            headers: { ...payload.token }
         },
         expectedStatus
      );
   }

   // TODO: usando hard coded o token da preciso -> by Renan 13/11/2023

   createClient(payload) {
      const org = localStorage.getItem("organization");
      const url = `?appId=${
         org === process.env.VUE_APP_PRECISO
            ? process.env.VUE_APP_PAGARME_PUBLIC_KEY_PRECISO
            : process.env.VUE_APP_PAGARME_PUBLIC_KEY
      }`;
      return pagarmeInstance.post(url, payload);
   }
   updateCreditCard(payload) {
      const expectedStatus = {
         201: "Cartão atualizado",
         200: "Cartão atualizado",
         default: "Erro nos dados do cartão"
      };

      return this.request.POST(
         {
            url: this.prefix + this.extends.card,
            body: { ...payload }
         },
         expectedStatus
      );
   }
   // Gets bank account info
   getBankAccountData() {
      const expectedStatus = {
         200: "Dados coletados",
         default: "Dados bancários não resgatados"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.bank
         },
         expectedStatus
      );
   }
   // Gets credit card info
   getCreditCardInformation() {
      const expectedStatus = {
         200: "Dados coletados",
         default: "Erro na leitura de dados do usuário"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.card
         },
         expectedStatus
      );
   }
   // Gets organization plans
   getOrganizationPlans() {
      const expectedStatus = {
         200: "Dados de planos atualizados",
         default: "Erro na leitura de dados do usuário"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.plans
         },
         expectedStatus
      );
   }
   // Gets incoming plan
   getSuggestedPlan(payload) {
      const expectedStatus = {
         200: "Dados de planos atualizados",
         default: "Erro inesperado"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.plans + `${payload}/`
         },
         expectedStatus
      );
   }
   // Creates organization plan
   createOrganizationPlan(planData) {
      const expectedStatus = {
         201: "Plano criado",
         202: "Plano criado",
         default: "Erro ao criar plano"
      };

      let multipartForm = planData.serviceProvision === "custom";

      return this.request.POST(
         {
            url: this.prefix + this.extends.plans,
            hasFile: !!multipartForm,
            body: planData
         },
         expectedStatus
      );
   }
   // Reactive subscription
   reactivateSubscription(payload) {
      const expectedStatus = {
         200: "Subscrição reativada",
         default: "Erro ao reativar subscrição"
      };

      return this.request.POST(
         {
            url: this.extends.reactive,
            body: payload
         },
         expectedStatus
      );
   }
}
