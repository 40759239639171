export default class Timestamp {
   constructor() {
      this.time = 0;

      this.endDate = 0;
      this.sessionTime = null;
   }
   _register() {
      const STAMP_DATA = {
         endDate: this.endDate,
         sessionTime: this.sessionTime
      };

      localStorage.timestamp = JSON.stringify(STAMP_DATA);
   }
   _read() {
      if (localStorage.timestamp) {
         let localData = JSON.parse(localStorage.timestamp);

         this.endDate = localData.endDate;
         this.sessionTime = localData.sessionTime;
      } else {
         this.start();
      }
   }
   _refresh() {
      this.time = Date.now();
   }
   _hasLocalTimestamp() {
      const STAMP = "timestamp";

      return !!localStorage.getItem(STAMP);
   }
   renew() {
      let timesHour = h => h * 60 * 60 * 1000;
      const CSS = `background: #7e46d8; color: #fff; padding: 2px 5px; line-height: 1em; border-radius: 3px;`;

      this.endDate = Date.now() + timesHour(this.sessionTime);
      this._register();

      let inHour = h => `${new Date(h).getHours()}h${new Date(h).getMinutes()}min`;
      console.log("%cSessão expira: " + inHour(this.endDate), CSS); // eslint-disable-line no-console
   }
   alert(msg) {
      const CSS = `background: #7e46d8; color: #fff; padding: 2px 5px; line-height: 1em; border-radius: 3px;`;

      console.log(`%c${msg}`, CSS); // eslint-disable-line no-console
   }
   start() {
      if (this._hasLocalTimestamp()) return;

      this.time = Date.now();

      this.setSessionTime();
      this._register();

      return this;
   }
   setEndDate() {
      if (!this.endDate) {
         let timesHour = h => h * 60 * 60 * 1000;

         this.endDate = Date.now() + timesHour(this.sessionTime);

         return this.endDate;
      } else {
         return this.endDate;
      }
   }
   setSessionTime(time) {
      time = time || 2;
      this.sessionTime = time;

      this.setEndDate();
   }
   verify() {
      this._refresh();
      this._read();

      return this.time < this.endDate;
   }
}
