class ReturnErrorUpload {
   constructor(response) {
      this.status = response && response.status ? response.status : "400";
      this.text = response && response.data && response.data.detail ? response.data.detail : "";
      this.errors = response && response.data && response.data.errors ? response.data.errors : [];
      this.init();
   }

   init() {
      return {
         status: this.status,
         text: this.text,
         errors: this.errors
      };
   }
}

export { ReturnErrorUpload };
