import Filter from "@/services/utils/filter.js";
import { Query } from "@/services/utils/validators.js";

/**
 * class responsible for cash
 *
 * @class CashFlow
 * @constructor
 * @param {Object} - request
 */
export default class CashFlow {
   constructor(request) {
      this.moduleName = "cashFlow";
      this.prefix = "cash-flow/";

      this.extends = {
         summary: "summary/",
         export: "export-xls/",
         transfer: "transfer-asset/"
      };

      this.request = request;

      this.routes = {
         reviewTransactions: {
            delete: this.deleteTransaction,
            edit: this.editTransaction
         },
         display: {
            filter: this.filterByDate,
            all: this.allTransactions
         },
         editTransaction: {
            update: this.saveCategorization
         },
         summary: {
            filter: this.filterSummary,
            transfer: this.getCashFlowTransfer
         },
         transaction: {
            download: this.downloadTransactions
         }
      };
      this.bind(this.routes, this);
   }
   /**
    * Filters cash flow's summary transfer asseets
    * @method getCashFlowTransfer
    * @param {Object} payload
    * @return {Object} response summary transfer
    */
   async getCashFlowTransfer(payload) {
      const expectedStatus = {
         200: "Retornado com sucesso",
         default: "Não foi possível filtrar"
      };

      const { start, end, ...filter } = Filter.config(payload);

      let query = Query.build(
         {
            cashDateStart: start,
            cashDateEnd: end,
            ...filter
         },
         { acceptNull: payload.filterType === "category" }
      );

      return await this.request.GET(
         {
            url: this.prefix + this.extends.transfer + query
         },
         expectedStatus
      );
   }

   async deleteTransaction(payload) {
      const expectedStatus = {
         204: "Transação deletada",
         default: "Não foi possível deletar transação"
      };

      return await this.request.DELETE(
         {
            url: this.prefix + `${payload.id}/?clientId=${payload.clientID}`
         },
         expectedStatus
      );
   }
   async editTransaction(payload) {
      const expectedStatus = {
         200: "Transação editada!",
         default: "Não foi possível editar transação"
      };

      let query = `${payload.transaction.id}/`;
      query += payload.clientID ? `?clientId=${payload.clientID}` : "";

      return await this.request.PUT(
         {
            url: this.prefix + query,
            body: payload.transaction
         },
         expectedStatus
      );
   }
   async allTransactions(payload) {
      const expectedStatus = {
         200: "Atualizado",
         default: "Não foi possível filtrar transação"
      };

      const query = `?&origins=[${payload.origins}]`;

      return await this.request.GET(
         {
            url: this.prefix + query
         },
         expectedStatus
      );
   }
   async saveCategorization(payload) {
      const expectedStatus = {
         200: "Atualizado",
         default: "Não foi possível filtrar transação"
      };

      return await this.request.PUT(
         {
            url: `${this.prefix + payload.id}/`,
            body: payload
         },
         expectedStatus
      );
   }

   /**
    * Filters cash flow's summary
    * @method filterSummary
    * @param {Object} payload
    * @return {Object} response summary transfer
    */
   async filterSummary(payload) {
      const { start, end, ...filter } = Filter.config(payload, [
         "description",
         "category",
         "isExpense"
      ]);

      const query = Query.build(
         {
            cashDateStart: start,
            cashDateEnd: end,
            ...filter
         },
         {
            acceptBoolean: true
         }
      );

      return await this.request.GET(
         {
            url: this.prefix + this.extends.summary + query
         },
         {
            200: "Summary atualizado",
            default: "Sistema indisponível. Tente novamente mais tarde."
         }
      );
   }

   /**
    * Filter cash flow using all valid filter config
    * @param {Object} payload
    */
   async filterByDate(payload) {
      const expectedStatus = {
         200: "Atualizado",
         default: "Não foi possível filtrar transação"
      };

      const { start, end, ...filter } = Filter.config(payload);

      let query = Query.build(
         {
            cashDateStart: start,
            cashDateEnd: end,
            ...filter
         },
         { acceptNull: payload.filterType === "category" }
      );

      return await this.request.GET(
         {
            url: this.prefix + query
         },
         expectedStatus
      );
   }

   async downloadTransactions(payload) {
      const expectedStatus = {
         200: "Atualizado",
         default: "Não foi possível filtrar transação"
      };

      const { start, end, ...filter } = Filter.config(payload);

      const query = Query.build(
         {
            cashDateStart: start,
            cashDateEnd: end,
            ...filter
         },
         {
            acceptBoolean: true,
            acceptValid: true
         }
      );

      return await this.request.GET(
         {
            url: this.prefix + this.extends.export + query,
            responseType: "arraybuffer"
         },
         expectedStatus
      );
   }
}
