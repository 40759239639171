const accountDetails = {
   data: {
      data: [
         {
            name: "Cartões de Crédito",
            data: [
               {
                  type: "credit-card",
                  id: "1",
                  nestedName: "Nubank",
                  valueLastBill: "R$ -800,00",
                  cardNumber: "**** **** **** 1234",
                  plugins: [
                     {
                        primaryColor: "8c00c3",
                        secondaryColor: "b530e9"
                     }
                  ]
               },
               {
                  type: "credit-card",
                  id: "2",
                  nestedName: "Bradesco",
                  valueLastBill: "R$ -900,00",
                  cardNumber: "**** **** **** 1235",
                  plugins: [
                     {
                        primaryColor: "ff1f3a",
                        secondaryColor: "ff475d"
                     }
                  ]
               },
               {
                  type: "credit-card",
                  id: "3",
                  nestedName: "Itaú",
                  valueLastBill: "R$ -3000,00",
                  cardNumber: "**** **** **** 7985",
                  plugins: [
                     {
                        primaryColor: "dc4500",
                        secondaryColor: "fda100"
                     }
                  ]
               }
            ]
         },
         {
            name: "Investimentos",
            data: [
               {
                  type: "investment",
                  id: 1,
                  nestedName: "CDB",
                  date: "25/05/2021",
                  summary: [
                     {
                        label: "Saldo",
                        value: "R$ 12.000,99"
                     }
                  ]
               },
               {
                  type: "investment",
                  id: 2,
                  nestedName: "Fundo DI",
                  date: "25/06/2021",
                  summary: [
                     {
                        label: "Saldo",
                        value: "R$ 11.000,99"
                     }
                  ]
               }
            ]
         }
      ]
   }
};

const accountBadge = {
   data: {
      data: [
         {
            bank: "Bradesco",
            origin: "XXXX-Y",
            balance: 15062,
            type: "Conta corrente",
            importType: "Automática",
            lastUpdated: "2021-04-29",
            primaryColor: "ff1f3a",
            secondaryColor: "ff475d"
         },
         {
            bank: "Nubank",
            origin: "ZZZZ",
            balance: -215070,
            type: "Conta corrente",
            importType: "Automática",
            lastUpdated: "2021-04-29",
            primaryColor: "8c00c3",
            secondaryColor: "b530e9"
         },
         {
            bank: "Itaú",
            origin: "ZZZZ",
            balance: 850070,
            type: "Conta poupança",
            importType: "Automática",
            lastUpdated: "2021-04-29",
            primaryColor: "dc4500",
            secondaryColor: "fda100"
         }
      ]
   }
};

const Mock = {
   _async(response) {
      return new Promise(resolve => {
         resolve(response);
      });
   },
   badge() {
      return this._async(accountBadge);
   },
   cards() {
      return this._async(accountDetails);
   }
};

export default Mock;
