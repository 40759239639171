import { Mod } from "@/services/api";
import Mock from "@/__mocks__/future-flow-mock.js";

const TESTING = false;

export default {
   state: {
      planned: []
   },
   getters: {},
   actions: {
      async getFutureFlow({ dispatch, commit }, props) {
         let req;

         if (TESTING) {
            req = await Mock.futureFlowMonths();
         } else {
            req = await dispatch("getFutureFlowSearch", props);
         }
         commit("updatePlans", req.data.data);

         return req.data.data;
      },

      async getFutureFlowSearch(_commit, props) {
         return await Mod.find("futureFlow", "metrics").planned(props);
      },

      async getPlanned({ commit }, props) {
         let req;
         req = await Mod.find("futureFlow", "metrics").getPlanned({
            dates: props.dates,
            clientId: props.clientId
         });
         if (props.setPlanning) {
            commit("updatePlans", req.data.data);
            return req.data;
         }
         if (!props?.value) return req.data;
         if (props.value === "past") {
            commit("updatePlansPast", req.data.data);
         } else {
            commit("updatePlansFuture", req.data.data);
         }
         return req.data;
      },

      createFutureFlowPlan(_commit, config) {
         return Mod.find("futureFlow", "metrics").create(config);
      }
   },
   mutations: {
      updatePlans(state, plans) {
         state.planned = [...plans];
      },
      updatePlansPast(state, plans) {
         state.planned.unshift(...plans);
      },
      updatePlansFuture(state, plans) {
         state.planned.push(...plans);
      }
   }
};
