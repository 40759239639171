//Moment
import moment from "moment";

export default class Events {
   constructor() {
      this.events = [];
      this.yearly = [];
      this.monthly = [];
   }

   static setEvents(events) {
      this.events = [...events];
      this.filterEvents();
   }

   static filterEvents() {
      this.yearly = this.events.filter(v => v.periodicity == "YEARLY");
      this.monthly = this.events.filter(v => v.periodicity == "MONTHLY");
   }

   static createPeriodicity() {
      const cpEvents = [];
      this.monthly.forEach(event => {
         for (let n = 0; n < 36; n++) {
            let cpEvent = { ...event };
            cpEvent.reminder_date = moment(event.reminder_date)
               .add(n, "months")
               .format("YYYY-MM-DD");
            cpEvents.push(cpEvent);
         }
      });
      this.yearly.forEach(event => {
         for (let n = 0; n < 3; n++) {
            let cpEvent = { ...event };
            cpEvent.reminder_date = moment(event.reminder_date)
               .add(n, "months")
               .format("YYYY-MM-DD");
            cpEvents.push(cpEvent);
         }
      });
      return cpEvents;
   }
}
