export default new (function() {
   this.rgbToHex = (r, g, b) => {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
   };
   this.hexToRgb = hex => {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      return result
         ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
           }
         : null;
   };
   this.hexToRgba = hex => {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      return result
         ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
              a: 1
           }
         : null;
   };
   this.hexToHsla = H => {
      // Convert hex to RGB first
      let r = 0,
         g = 0,
         b = 0;
      if (H.length == 4) {
         r = "0x" + H[1] + H[1];
         g = "0x" + H[2] + H[2];
         b = "0x" + H[3] + H[3];
      } else if (H.length == 7) {
         r = "0x" + H[1] + H[2];
         g = "0x" + H[3] + H[4];
         b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
         cmax = Math.max(r, g, b),
         delta = cmax - cmin,
         h = 0,
         s = 0,
         l = 0;

      if (delta == 0) h = 0;
      else if (cmax == r) h = ((g - b) / delta) % 6;
      else if (cmax == g) h = (b - r) / delta + 2;
      else h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0) h += 360;

      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);

      return {
         h,
         s: s / 100,
         l: l / 100,
         a: 1
      };
   };
   this.hexToHsva = hex => {
      let { r, g, b } = this.hexToRgba(hex);

      r /= 255;
      g /= 255;
      b /= 255;

      var max = Math.max(r, g, b),
         min = Math.min(r, g, b);
      var h,
         s,
         v = max;

      var d = max - min;
      s = max == 0 ? 0 : d / max;

      if (max == min) {
         h = 0; // achromatic
      } else {
         switch (max) {
            case r:
               h = (g - b) / d + (g < b ? 6 : 0);
               break;
            case g:
               h = (b - r) / d + 2;
               break;
            case b:
               h = (r - g) / d + 4;
               break;
         }

         h /= 6;
      }

      return {
         h,
         s,
         v,
         a: 1
      };
   };
   this.factory = hex => {
      return {
         alpha: 1,
         hex: "#" + hex,
         hexa: "#" + hex + "FF",
         rgba: this.hexToRgba(hex),
         hsla: this.hexToHsla(hex),
         hsva: this.hexToHsva(hex),
         hue: this.hexToHsla(hex).h
      };
   };
})();
