import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";

const DEFAULT = {
   primary: "#fff",
   secondary: "#fff"
};

Vue.use(Vuetify);

Vue.component("my-component", {
   methods: {
      changeLocale() {
         this.$vuetify.lang.current = "pt";
      }
   }
});

Vue.component("v-text-field", VTextField);

Vue.use(VCurrencyField, {
   locale: "pt-BR",
   currency: "BRL",
   decimalLength: 2,
   autoDecimalMode: true,
   min: null,
   max: null,
   defaultValue: 0
});

export default new Vuetify({
   icons: {
      iconfont: "mdi"
   },
   lang: {
      locales: { pt },
      current: "pt"
   },
   breakpoint: {
      mobileBreakpoint: "sm",
      thresholds: {
         xs: 320,
         sm: 540,
         md: 800,
         lg: 1280
      },
      scrollBarWidth: 24
   },
   theme: {
      themes: {
         light: {
            primary: DEFAULT.primary,
            secondary: DEFAULT.secondary,
            anchor: DEFAULT.secondary,
            background: "#f8f8f8"
         }
      }
   }
});
