import { Query } from "@/services/utils/validators.js";
export default class Transactions {
   constructor(request) {
      this.moduleName = "transactions";
      this.prefix = "transactions/";
      this.extended = {
         description: "description/",
         category: "category/",
         origin: "origin/"
      };

      this.request = request;

      this.routes = {
         labels: {
            description: this.getDescription,
            category: this.getCategory,
            origin: this.getOrigin
         },
         create: {
            create: this.createManualTransaction
         },
         update: {
            name: this.updateTransactionName
         }
      };
      this.bind(this.routes, this);
   }

   async getDescription() {
      const expectedStatus = {
         200: "Descrições atualizadas",
         default: "Não encontramos descrições"
      };

      const descritpion = await this.request.GET(
         {
            url: this.extended.description
         },
         expectedStatus
      );
      return descritpion.data.data.sort((a, b) => a.name.localeCompare(b.name));
   }
   async getCategory() {
      const expectedStatus = {
         200: "Categorias atualizadas",
         default: "Não encontramos categorias"
      };

      return await this.request.GET(
         {
            url: this.extended.category
         },
         expectedStatus
      );
   }
   async getOrigin(payload) {
      const expectedStatus = {
         200: "Origem atualizadas",
         default: "Não encontramos origens"
      };

      const query = Query.build(payload);

      return await this.request.GET(
         {
            url: this.extended.origin + query
         },
         expectedStatus
      );
   }

   async createManualTransaction() {
      const expectedStatus = {
         200: "Task atualizadas",
         default: "Não encontramos origens"
      };
      return await this.request.GET(
         {
            url: this.prefix + "create/"
         },
         expectedStatus
      );
   }

   async updateTransactionName({ id, name }) {
      const expectedStatus = {
         200: "Lançamento atualizado!",
         default: "Sistema indisponível. Tente novamente mais tarde.."
      };
      return await this.request.PUT(
         {
            url: this.prefix + `name/${id}/`,
            body: {
               name
            }
         },
         expectedStatus
      );
   }
}
