import Store from "@/store";
import Router from "@/router";

const ERROR_MSG = `Request permission error, at src/services/utils/plugins.js.`;

export default {
   validate(config) {
      let { strictTokenAccess, softTokenAccess } = config;

      if (strictTokenAccess) {
         return this.beforeRequest();
      }
      if (softTokenAccess) {
         return this.beforeRequestSoft();
      }

      if (process.env.NODE_ENV == "dev") {
         throw Error(`${ERROR_MSG} ${JSON.stringify(config)}`);
      } else {
         return true;
      }
   },
   beforeRequest() {
      const TOKEN = localStorage.token;

      if (TOKEN) {
         return true;
      } else {
         Store.dispatch("kickUser");
         Router.push({ name: "login" }).catch(() => {});

         return false;
      }
   },
   beforeRequestSoft() {
      const TOKEN = localStorage.token;

      return Boolean(TOKEN);
   }
};
