import Timestamp from "@/user/timestamp";

export default {
   actions: {
      /**
       * Sets a timestamp, when the timestamp is expired, kicks the user.
       * The timestamp is renewed every organization validation (every time the user navigates the platform).
       *
       * @param {object} Store
       */
      startSessionTimer({ dispatch }) {
         const SESSION = new Timestamp();

         SESSION.start();

         if (SESSION.verify()) {
            SESSION.renew();
         } else {
            SESSION.alert("Sessão encerrada");
            dispatch("kickUser");
         }
      }
   }
};
