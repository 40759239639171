<template>
   <span v-if="subscription" v-html="planMessage"></span>
</template>
<script>
import currency from "@/utils/currency";

export default {
   name: "UserPlanMessage",
   props: {
      subscription: {
         type: Object,
         required: true
      },
      classe: {
         type: String,
         required: false,
         default: ""
      }
   },
   computed: {
      planMessage() {
         if (!this.subscription) {
            return "";
         }

         const {
            service_amount,
            platform_amount,
            recurrent_amount,
            service_months
         } = this.subscription;
         const recurrentAmount = currency.toReal((recurrent_amount || 0) + (platform_amount || 0));

         if (service_amount && service_amount > 0) {
            const serviceAmount = currency.toReal(service_amount + (platform_amount || 0));
            return `Plano de <strong class="${this.classe}" >R$ ${serviceAmount}</strong>
            por ${service_months} mês(es) e, após esse período, assinatura mensal de
            <strong class="${this.classe}" >R$ ${recurrentAmount}</strong >.`;
         } else {
            return `Assinatura mensal de <strong class="${this.classe}" >R$ ${recurrentAmount}.</strong >`;
         }
      }
   }
};
</script>
