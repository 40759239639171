import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class Module {
   constructor(request) {
      this.moduleName = "myAssets";
      this.prefix = "my-assets/";

      this.request = request;

      this.routes = {
         details: {
            cards: async filters => {
               const query = Query.build(filters);
               const endpoint = "account-details/";

               return this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            },
            badge: async clientId => {
               const query = Query.build(clientId);
               const endpoint = "account/";

               return this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Cash flow chart data received"
                  })
               );
            },
            creditCard: async config => {
               const query = Query.build(config.clientId);
               const endpoint = `credit-card-details/${config.idCard}/`;

               return this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Credit card details data received"
                  })
               );
            },
            cardStatment: async config => {
               const query = Query.build(config.clientId);
               const endpoint = `card-statment-details/${config.idStatment}/`;

               return this.request.GET(
                  {
                     url: this.prefix + endpoint + query
                  },
                  Expected.add({
                     200: "Card statment details data received"
                  })
               );
            }
         },
         update: {
            updateAccount: async config => {
               const endpoint = `account/${config.id}/`;
               delete config.id;

               return this.request.PUT(
                  {
                     url: this.prefix + endpoint,
                     body: { nickname: config.nickname }
                  },
                  Expected.add({
                     200: "Card statment details update"
                  })
               );
            }
         }
      };
   }
}
