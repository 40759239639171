import Api, { Mod } from "@/services/api";
import Loader from "@/utils/loader";
import { Utils } from "@/utils/Utils.js";
import Transform from "@/utils/dateManager";

export default {
   state: {
      autoAssignElements: false,
      budget: {
         income: [],
         expense: []
      },
      cashflow: [],
      categoryList: {
         expense: [],
         income: [],
         product: [],
         cashflow: []
      },
      clientID: null,
      comparePlannedTransactions: false,
      dataIsUpdated: false,
      displayedItems: [],
      filter: {
         origins: [],
         data: {}
      },
      inScopeItemID: "0",
      jumpPermitted: false,
      labels: {
         descriptionList: {},
         categoryList: [],
         origins: []
      },
      loading: new Loader(),
      myBudgetAnalyticView: false,
      subscription: null,
      suggestedPlan: null,
      tableType: "ft",
      tasks: {
         planningList: false
      }
   },

   getters: {
      canJump: state => state.jumpPermitted,
      cashflow: state => state.cashflow,
      categoryList: state => state.labels.categoryList,
      clientNewSubscription: state => state.subscription,
      comboBoxScopeID: state => state.inScopeItemID,
      compare: state => state.comparePlannedTransactions,
      descriptionList: state => state.labels.descriptionList,
      expense: state => state.categoryList.expense,
      filteredCategoryList: state => state.labels.categoryList,
      income: state => state.categoryList.income,
      myBudget: state => state.budget,
      suggestedPlan: state => state.suggestedPlan,
      userPlanningList: state => {
         if (state.tasks.planningList.length) {
            return [...state.tasks.planningList, { text: "Nenhum", value: 0 }];
         } else return [];
      }
   },

   actions: {
      doSaveCashflow: async function({ commit }, cashflow) {
         let { row, client } = cashflow;

         const updateCashflow = {
            id: row.id,
            client,
            data: row
         };

         let updatedCashflow = await Api.doSaveCashflow(updateCashflow);

         commit("updateCashflow", updatedCashflow.data);

         return updatedCashflow.data;
      },

      async acceptSubscription(store, data) {
         return await Mod.find("payment", "subscription").accept(data);
      },
      /**
       * Update transaction, with client permissions
       *
       * @param {Store} store
       * @param {Object} props The payload
       * @returns {Promise} The request response
       */
      async categorize(store, props) {
         const { id, category, description, is_fixed, ignored } = Utils.transaction(props);

         return await Mod.find("cashFlow", "editTransaction").update({
            id,
            ...(category && { category }),
            ...(description && { description }),
            ...(Utils.isBoolean(is_fixed) && { is_fixed }),
            ...(Utils.isBoolean(ignored) && { ignored })
         });
      },

      async fetchBudget({ commit }, budgetData) {
         let req;

         commit("cleanBudget");

         if (budgetData.targetMonth) {
            req = await Api.fetchBudgetComparison(budgetData.targetMonth);
         } else {
            req = await Api.fetchBudget(budgetData);
         }

         commit("fillBudget", req.data);
      },

      async fetchBudgetPlans({ commit }, customTargetMonth) {
         let req = await Api.fetchBudgetPlans();

         commit("setBudgetPlans", {
            newPlanningList: req.data,
            targetMonthSelected: customTargetMonth || null
         });
      },

      async getSubscriptionPlanned({ commit }) {
         const subscription = await Mod.find("payment", "subscription").get();

         commit("updateClientSubscription", subscription.data.data);
         return subscription.data.data;
      },

      async getSuggestedPlan({ commit }, planId) {
         let plan = await Mod.find("payment", "plans").suggested(planId);

         commit("updateSuggestedPlan", plan.data.data);
         return plan.data;
      },

      getClientCashflow: async function({ commit, dispatch, state }) {
         if (state.filter.data.origins) {
            await dispatch("getLabels");
            if (state.filter.data.origins && !state.filter.data.origins.length) {
               state.filter.data.origins = [];
               state.labels.originList.map(originItem =>
                  state.filter.data.origins.push(originItem.id.toString())
               );
            }
            const cashflow = await Api.filterCashflow(state.filter.data);
            commit("setCashflowToState", cashflow);
         }
      },

      async getDescriptionOptions(store, queryData) {
         return await Api.getDescriptionTag(queryData);
      },

      async getLabels({ commit }) {
         const labels = await Promise.all([
            Api.getDescription(),
            Api.getCategories(),
            Api.getOrigins()
         ]);
         commit("setLabels", labels);
      },
      async getRecommendedCategory(store, descriptionID) {
         return await Api.getMandatoryCategoryList(descriptionID);
      },

      async reactiveSubscription(_commit, payload) {
         const CARD_HASH = await Mod.find("payment", "card").hash(payload.card);

         delete payload.card;
         payload.card_hash = CARD_HASH;

         const req = await Mod.find("payment", "subscription").reactive(payload);
         return req.data.data;
      },
      async createManualTransactionCartao(store, payload) {
         const errorMessages = {
            "The number field is not a valid card number": "Não é um número de cartão válido",
            "Card expired.": "Cartão expirado."
         };

         function translateErrorMessage(errorResponse) {
            if (!errorResponse?.errors) return [];
            return Object.entries(errorResponse.errors).flatMap(([, messages]) =>
               messages.map(message => errorMessages[message] || message)
            );
         }

         function saveLogError(error, payload) {
            Mod.find("logging", "logging").create({
               type: "error",
               error: error,
               request: payload
            });
         }

         try {
            return await Mod.find("payment", "card").send(payload);
         } catch (err) {
            console.error(err); // eslint-disable-line no-console
            saveLogError(err, payload);
            const translatedErrors = translateErrorMessage(err?.response?.data);
            if (translatedErrors.length > 0) {
               throw new Error(translatedErrors.join(", "));
            } else {
               throw new Error(
                  "Erro desconhecido ao processar o pagamento. Verifique seus dados e tente novamente."
               );
            }
         }
      },

      async createManualTransaction() {
         const res = await Mod.find("transactions", "create").create();
         return res.data.data;
      },

      async updateTransactionName(_, payload) {
         const res = await Mod.find("transactions", "update").name(payload);
         return res.data.data;
      }
   },

   mutations: {
      cleanPeriods(state) {
         state.tasks.planningList = false;
      },
      fillBudget(state, budget) {
         state.budget = budget;
      },

      jumpToNextInput(state, setter) {
         setter = setter || false;
         state.jumpPermitted = setter;
      },
      setBudgetPlans(state, planningData) {
         let { newPlanningList, targetMonthSelected } = planningData;
         if (!targetMonthSelected) return;

         const newArray = newPlanningList.map(plan => {
            let targetMonth = Transform.roundDate(targetMonthSelected.end).getTime();
            let planTargetMonth = Transform.roundDate(plan.target_month + "-02").getTime();

            if (targetMonth === planTargetMonth) {
               return {
                  text: "Planejado",
                  value: plan.budget
               };
            }
         });
         while (newArray.includes(undefined)) {
            newArray.splice(newArray.indexOf(undefined), 1);
         }
         if (newArray[0]) {
            state.tasks.planningList = Array.from(new Set(newArray));
         }
      },

      setCashflowToState: function(state, requestedData) {
         state.cashflow = requestedData.data;
      },

      setLabels(state, labels) {
         labels[0].data.map(description => {
            state.labels.descriptionList[description.id] = description.name;
         });
         state.labels.categoryList = labels[1].data;
         state.labels.originList = labels[2].data;
      },
      updateCashflow(state, cashflowItem) {
         state.cashflow.map((item, index) => {
            if (cashflowItem.id === item.id) {
               item[index] = cashflowItem;
            }
         });
      },
      updateClientSubscription(state, newSubscription) {
         state.subscription = newSubscription;
      },

      updateItemFocus(state, item) {
         state.inScopeItemID = item.id;
      },

      updateSuggestedPlan(state, planId) {
         state.suggestedPlan = planId;
      }
   }
};
