import Vue from "vue";
import VueRouter from "vue-router";
import * as Store from "../store/index";

Vue.use(VueRouter);

const routes = [
   {
      path: "/:organization",
      name: "NotAuthenticated",
      component: () =>
         import(
            /* webpackChunkName: "NotAuthenticated" */ "../views/AuthenticationController/NotAuthenticated"
         ),
      children: [
         {
            path: "login",
            name: "login",
            component: () => import(/* webpackChunkName: "Login" */ "../views/login/Login")
         },
         // {
         //    path: "cadastro/novo-cliente/:hash/:plan?/:mail?",
         //    name: "RegisterClient",
         //    component: () =>
         //       import(
         //          /* webpackChunkName: "RegisterClient" */ "@/views/registerUser/shared/components/AD/molecules/NewClient.vue"
         //       ),
         //    meta: {
         //       isRegisterPage: true
         //    }
         // },
         {
            path: "cadastro/:role/:hash/:plan?",
            name: "registerUser",
            component: () =>
               import(
                  /* webpackChunkName: "RegisterUser" */ "@/views/registerUser/RegisterUser.vue"
               ),
            meta: {
               isRegisterPage: true
            }
         },
         {
            path: "redefinir-senha/:uid?/:token?",
            name: "reset-password",
            component: () =>
               import(/* webpackChunkName: "RedefinePassword" */ "../views/RedefinePassword")
         }
      ]
   },
   {
      path: "*",
      name: "not-found",
      component: () => import(/* webpackChunkName: "Page404" */ "../views/Page404"),
      beforeEnter: (to, from, next) => {
         if (to.fullPath === "/") {
            next(`/${localStorage.organization || "fintask"}/login`);
         } else {
            Store.default.commit("setAlert", {
               text: "Endereço não existe.",
               color: "error"
            });
            next(from);
         }
      }
   },
   {
      path: "/cadastro/organizacao",
      name: "registerOrganization",
      component: () =>
         import(
            /* webpackChunkName: "RegisterOrganization" */ "@/views/registerOrganization/RegisterOrganization.vue"
         )
   },
   {
      path: "/reset/config",
      name: "resetLocalConfig",
      component: () =>
         import(
            /* webpackChunkName: "ResetLocalConfig" */ "@/views/resetLocalConfig/ResetLocalConfig.vue"
         )
   },
   {
      path: "/callback-opb",
      name: "callbackOpenBanking",
      component: () =>
         import(
            /* webpackChunkName: "MyTransactions" */ "../views/myTransactions/MyTransactions.vue"
         ),
      meta: {
         slug: "open-banking",
         breadcrumb: [{ name: "Meu caixa", link: "open-banking" }],
         userPermissions: ["client", "planner"]
      }
   },

   {
      path: "/app/:organization",
      name: "Authenticated",
      component: () =>
         import(
            /* webpackChunkName: "Authenticated" */ "../views/AuthenticationController/Authenticated"
         ),
      meta: {
         requiresAuth: true
      },
      children: [
         {
            path: "dashboard",
            name: "dashboard",
            component: () =>
               import(/* webpackChunkName: "Dashboard" */ "../views/dashboard/Dashboard.vue"),
            meta: {
               slug: "dashboard",
               breadcrumb: [{ name: "Dashboard", link: "" }]
            }
         },
         {
            path: "callback-opb",
            name: "callbackOpenBanking",
            component: () =>
               import(
                  /* webpackChunkName: "MyTransactions" */ "../views/myTransactions/MyTransactions.vue"
               ),
            meta: {
               slug: "open-banking",
               breadcrumb: [{ name: "Meu caixa", link: "open-banking" }],
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "integration-opb",
            name: "integrationOpb",
            component: () =>
               import(
                  /* webpackChunkName: "MyTransactions" */ "../views/myTransactions/MyTransactions.vue"
               ),
            props: true,
            meta: {
               slug: "integration-opb",
               breadcrumb: [{ name: "Meu caixa", link: "open-banking" }],
               userPermissions: ["client", "planner"]
            }
         },

         {
            path: "cliente/:clientId/:view?/:taskStatus?",
            name: "client",
            component: () =>
               import(
                  /* webpackChunkName: "PlannerAsClient" */ "@/views/plannerAsClient/PlannerAsClient.vue"
               ),
            meta: {
               slug: "cliente",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Transações", link: "" }
               ],
               userPermissions: ["planner"]
            }
         },
         {
            path: "tarefa-de-categorizacao",
            name: "categorizingTask",
            component: () =>
               import(
                  /* webpackChunkName: "CategorizingTask" */ "@/views/categorizingTask/CategorizingTask.vue"
               ),
            meta: {
               slug: "tarefa-de-categorizacao",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Tarefa de categorização", link: "" }
               ],
               userPermissions: ["client"]
            }
         },
         {
            path: "arquivo/:clientId/:bundleId/:taskId",
            name: "file",
            component: () => import(/* webpackChunkName: "Import" */ "../views/Import"),
            meta: {
               slug: "arquivo",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Importação", link: "" }
               ],
               userPermissions: ["planner", "client"]
            }
         },
         {
            path: "prospect",
            name: "prospect",
            component: () => import(/* webpackChunkName: "Prospect" */ "../views/Prospect"),
            meta: {
               slug: "prospect",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Prospect", link: "" }
               ],
               userPermissions: ["planner"]
            }
         },
         {
            path: "arquivo",
            name: "arquivo",
            component: () =>
               import(/* webpackChunkName: "FileReview" */ "@/views/fileReview/FileReview.vue"),
            meta: {
               slug: "arquivo",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Importação", link: "importacao" },
                  { name: "Revisão", link: "" }
               ],
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "fluxo-futuro",
            name: "fluxo-futuro",
            component: () =>
               import(
                  /* webpackChunkName: "FutureFlowView" */ "@/views/futureFlow/FutureFlowView.vue"
               ),
            meta: {
               slug: "fluxo-futuro",
               breadcrumb: [{ name: "Fluxo futuro", link: "fluxo-futuro" }],
               toggleFeature: "future_flow",
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "configuracao/meu-perfil/:view?",
            name: "perfil",
            component: () =>
               import(/* webpackChunkName: "Profile" */ "@/views/profile/Profile.vue"),
            meta: {
               slug: "configuracao-meu-perfil",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Configurações", link: "" }
               ],
               userPermissions: ["planner", "client", "manager"]
            }
         },
         {
            path: "tarefas-e-avisos",
            name: "tarefas",
            component: () => import(/* webpackChunkName: "MyTasks" */ "../views/MyTasks"),
            meta: {
               slug: "minhas-tarefas",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Avisos & Importações", link: "" }
               ],
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "planejamento",
            name: "planejamento",
            component: () => import(/* webpackChunkName: "Planning" */ "../views/Planning"),
            meta: {
               slug: "planejamento",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Tarefas", link: "" },
                  { name: "Planejamento", link: "" }
               ],
               userPermissions: ["planner", "client"]
            }
         },
         {
            path: "meu-orcamento",
            name: "meu-orcamento",
            component: () =>
               import(/* webpackChunkName: "MyBudget" */ "@/views/myBudget/MyBudgetRoot.vue"),
            meta: {
               slug: "meu-orcamento",
               breadcrumb: [
                  { name: "Dashboard", link: "dashboard" },
                  { name: "Meu Orçamento", link: "" }
               ],
               userPermissions: ["client"]
            }
         },
         {
            path: "planejadores",
            name: "planejadores",
            component: () =>
               import(/* webpackChunkName: "Planners" */ "@/views/planners/Planners.vue"),
            meta: {
               slug: "planejadores",
               breadcrumb: [{ name: "Planejadores", link: "planejadores" }],
               userPermissions: ["manager"]
            }
         },
         {
            path: "configuracao/financeiro",
            name: "configuracao-financeiro",
            component: () =>
               import(
                  /* webpackChunkName: "OrganizationConfig" */ "@/views/organizationConfig/OrganizationConfig.vue"
               ),
            meta: {
               slug: "configuracao-financeiro",
               breadcrumb: [{ name: "Configuração", link: "planos" }],
               userPermissions: ["manager"]
            }
         },
         {
            path: "configuracao/organizacao",
            name: "configuracao-organizacao",
            component: () =>
               import(
                  /* webpackChunkName: "OrganizationConfig" */ "@/shared/components/atomicDesign/molecules/ConfigOrganization.vue"
               ),
            meta: {
               slug: "configuracao-organizacao",
               breadcrumb: [{ name: "Configuração", link: "configuraçãoo" }],
               userPermissions: ["manager"]
            }
         },
         {
            path: "clientes",
            name: "clients",
            component: () =>
               import(
                  /* webpackChunkName: "ManagerClients" */ "../views/managerClients/ManagerClients"
               ),
            meta: {
               slug: "clientes",
               breadcrumb: [{ name: "Clientes", link: "clients" }],
               userPermissions: ["manager"]
            }
         },
         {
            path: "meu-caixa/:task?",
            name: "cashRegister",
            component: () =>
               import(
                  /* webpackChunkName: "MyTransactions" */ "../views/myTransactions/MyTransactions"
               ),
            meta: {
               slug: "meu-caixa",
               breadcrumb: [{ name: "Meu caixa", link: "meu-caixa" }],
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "bs2-callback",
            name: "bs2Callback",
            component: () =>
               import(/* webpackChunkName: "Bs2Callback" */ "@/views/bs2Callback/bs2Callback.vue"),
            meta: {
               slug: "bs2-callback",
               breadcrumb: [{ name: "", link: "bs2Callback" }],
               userPermissions: ["client"]
            }
         },
         {
            path: "meu-desempenho",
            name: "my-performance",
            component: () =>
               import(
                  /* webpackChunkName: "MyPerformance" */ "@/views/myPerformance/MyPerformance.vue"
               ),
            meta: {
               slug: "meu-desempenho",
               breadcrumb: [{ name: "Meu desempenho", link: "cash-flow" }],
               toggleFeature: "my_performance_client",
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "meu-patrimonio",
            name: "my-assets",
            component: () =>
               import(/* webpackChunkName: "MyAssets" */ "@/views/myAssets/MyAssets.vue"),
            meta: {
               slug: "meu-patrimonio",
               breadcrumb: [{ name: "Meu Patrimônio", link: "meu-patrimonio" }],
               toggleFeature: "my_assets_client",
               userPermissions: ["client", "planner"]
            }
         },
         {
            path: "orcamento-do-mes/:date/:taskId?",
            name: "month-budget",
            component: () =>
               import(
                  /* webpackChunkName: "MonthBudget" */ "@/views/monthBudget/MonthBudgetView.vue"
               ),
            meta: {
               slug: "month-budget",
               breadcrumb: [{ name: "Orçamento do mês", link: "month-budget" }],
               toggleFeature: "future_flow",
               userPermissions: ["client", "planner"]
            }
         }
      ]
   },
   {
      path: "/:organization/confirmar/:token",
      name: "confirmar",
      component: () => import(/* webpackChunkName: "Confirm" */ "../views/Confirm"),
      meta: {
         breadcrumb: [{ name: "Confirmar e-mail", link: "confirmar" }],
         hideMenuFeatures: true,
         userPermissions: ["client", "planner", "manager"]
      }
   },
   {
      path: "/nao-encontrado",
      name: "no-organization",
      component: () => import(/* webpackChunkName: "NoOrganization" */ "../views/NoOrganization"),
      redirect: {
         path: "/fintask/login"
      }
   },
   {
      path: "/access/:token",
      name: "fastAccess",
      component: () =>
         import(/* webpackChunkName: "FastLogin" */ "@/views/loginViaUrl/LoginViaUrl.vue")
   }
];

const router = new VueRouter({
   mode: "history",
   routes
});

router.beforeEach(async (to, from, next) => {
   if (to.query.token) {
      const token = to.query.token;
      localStorage.setItem("token", token);
   }
   if (to.fullPath.startsWith("/#/")) {
      next(to.fullPath.slice(2));
   } else {
      next();
   }
   if (from.params.organization !== to.params.organization && from.fullPath !== "/") {
      if (from.name !== "fastAccess") {
         localStorage.removeItem("token");
      }
      localStorage.removeItem("timestamp");
      localStorage.removeItem("tokenConfig");
      // localStorage.removeItem("org");
      Store.default.commit("resetTokenConfig");
      Store.default.commit("resetOrganizationConfig");
      if (to.params.organization !== localStorage.getItem("organization")) {
         Store.default.commit("overRightOrgConfig", to.params.organization);
      }
   }

   if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.token) {
         next();
      } else {
         let { organization } = to.params;
         next({ path: `/${organization ? organization : "fintask"}/login` });
      }
   } else {
      next();
   }
});

export default router;
