<script>
//Vuex
import { mapActions, mapGetters } from "vuex";
//Pusher
import Pusher from "pusher-js";
//Mixin
import HelpDeskAutoPlay from "./HelpDeskAutoPlay.vue";

export default {
   data() {
      return {
         notification: false,
         timeout: 5000,
         mensage: null,
         hash: null,
         pusher: new Pusher(process.env.VUE_APP_WEBSOCKET_APP_KEY, {
            cluster: process.env.VUE_APP_WEBSOCKET_APP_CLUSTER
         })
      };
   },
   computed: {
      ...mapGetters(["persona"])
   },
   methods: {
      ...mapActions(["applyMiscFilterConfig", "getOrigins"]),
      channel(route) {
         if (route?.name !== "login") {
            Pusher.logToConsole = false;

            this.hash = btoa(this.persona.email);

            if (this.pusher) {
               const channel = this.pusher.subscribe(this.hash);
               channel.bind("updateQuanto", data => {
                  this.existMensage(data);
                  this.showTutorial(data?.message);
                  this.updateInfos();
               });
            }
         } else {
            if (this.pusher) {
               this.pusher.unsubscribe(this.hash);
               this.hash = null;
            }
         }
      },
      existMensage(data) {
         if (data?.message) {
            this.notification = true;
            setTimeout(() => {
               this.notification = false;
            }, this.timeout);
            this.mensage = "Integração realizada com sucesso!";
         }
      },
      async updateInfos() {
         await this.getOrigins();
         await this.applyMiscFilterConfig();
      }
   },
   mixins: [HelpDeskAutoPlay],
   watch: {
      persona: {
         deep: true,
         handler(person) {
            if (person != null) {
               this.channel(this.$route);
            }
         }
      },
      $route(route) {
         if (this.persona != null) {
            this.channel(route);
         }
      }
   }
};
</script>
