// Services
import { ReturnError } from "@/utils/error";
import { Mod } from "@/services/api";
import api from "@/services/api";
import Stats from "@/utils/stats";

export default {
   state: {
      clients: [],
      loading: 0,
      task: {
         budget: null
      }
   },

   getters: {
      getPlannerClients: function(state) {
         let clientList = state.clients;
         let clients = [];

         clientList.map(client => {
            let organizedItem = { ...client };

            organizedItem.fullName = `${client.user.first_name} ${client.user.last_name}`;
            organizedItem.id = client.user.id || "";
            organizedItem.last_import_bundle = client.last_import_bundle || false;

            if (organizedItem.last_import_bundle) {
               organizedItem.created_at = client.last_import_bundle.created_at || "";
            } else {
               organizedItem.created_at = null;
            }
            clients.push(organizedItem);
         });
         return clients;
      },
      getPlannerClientsRaw: state => state.clients,
      taskBudget: state => state.task.budget
   },

   actions: {
      async doUpdateUserData({ state, dispatch }, options) {
         dispatch("getClientAction", options);
         return state.clients;
      },

      async getClientAction({ commit }, options = {}) {
         if (typeof options?.active !== "boolean") {
            options.active = null;
         }

         const clients = await api.getClientList(options);

         commit("setClientsData", clients.data);

         if (options.client) {
            let fileList, currentBundle;

            fileList = clients.data.filter(client => client.user.id === options.client);

            if (!fileList[0].last_import_bundle) {
               commit("cleanFileList");
               throw new ReturnError(Stats.bundleNotFound);
            }

            currentBundle = fileList[0].last_import_bundle.id;
            commit("defineCurrentClientBundle", currentBundle);
         }
         return clients.data;
      },

      async changeSubscription(store, data) {
         return await Mod.find("payment", "subscription").change(data);
      },

      async getActiveClients(store, profile) {
         let clientList = await Mod.find("profiles", "clients").get(profile);
         return clientList.data.data;
      },

      async getBudgetId({ commit }, taskData) {
         const TASK_INFO = await Mod.find("tasks", "plannerTasks").budget(taskData);

         commit("fillBudgetData", TASK_INFO.data.data);
         return TASK_INFO.data.data;
      },

      async getTaskIdByBundle(store, bundleId) {
         let task = await Mod.find("tasks", "plannerTasks").getTaskId(bundleId);

         return task.data.data;
      },

      async getClientTasks(store, clientId) {
         let taskPending = await Mod.find("tasks", "plannerTasks").getTaskList({
            client: clientId,
            status: "pending"
         });
         let taskDone = await Mod.find("tasks", "plannerTasks").getTaskList({
            client: clientId,
            status: "done"
         });

         taskPending = taskPending.data.data;
         taskDone = taskDone.data.data;

         return [...taskPending, ...taskDone];
      },

      async editTransactionData(store, transactionData) {
         return await Mod.find("cashFlow", "reviewTransactions").edit(transactionData);
      }
   },

   mutations: {
      fillBudgetData(state, taskData) {
         state.task.budget = taskData;
      },
      setClientsData(state, clientData) {
         state.clients = clientData;
      },
      setLoadingStatus(state, loadingStatus) {
         state.loading = loadingStatus;
      }
   }
};
