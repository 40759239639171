// Core
import { Mod } from "@/services/api";

export default {
   state: {
      display: false,
      helpList: null,
      helpDeskStack: [],
      helpDeskFilteredList: null,
      helpDeskVideoSource: null,
      helpDeskActiveVideo: null,
      helpDeskVideoData: null,

      //AutoPlay
      helpDeskAutoPlay: {
         infos: null
      }
   },
   getters: {
      showHelpDesk: state => state.display,
      helpDeskList: state => state.helpList,

      helpDeskSlug: state => state.helpDeskStack.reduce((str, slug) => (str += slug), ""),

      //AutoPlay
      helpDeskAutoPlayInfos: state => state.helpDeskAutoPlay.infos
   },
   actions: {
      // async helpDeskSearchSlugQuery({ commit, getters }) {
      //    try {
      //       // const req = await Mod.find("helpDesk", "slugSearch").get({
      //       //    slug: getters.helpDeskSlug
      //       // });
      //
      //       // commit("newHelpList", req.data.data);
      //    } catch (err) {
      //       const SLUG_NOT_IMPLEMENTED = "Not Found Page with this slug";
      //
      //       if (err === SLUG_NOT_IMPLEMENTED) {
      //          commit("resetHelpDeskList");
      //       } else {
      //          throw Error(err);
      //       }
      //    }
      // },

      /**
       * AutoPlay Section
       */
      async helpDeskAutoPlay({ commit }, slug) {
         try {
            const req = await Mod.find("helpDesk", "slugSearch").autoPlay({
               slug: slug
            });
            commit("newHelpInfos", req.data.data.articles[0]);
            return req.data.data.articles[0];
         } catch (err) {
            const SLUG_NOT_IMPLEMENTED = "Not Found Page with this slug";

            if (err === SLUG_NOT_IMPLEMENTED) {
               commit("resetHelpInfo");
            } else {
               throw Error(err);
            }
         }
      },

      async updateUserPreference(_commit, articleId) {
         const req = await Mod.find("helpDesk", "slugSearch").updatePreference(articleId);
         return req.data.data;
      }
   },
   mutations: {
      updateSourceVideo(state, props = null) {
         if (props) {
            const { source, active, data } = props;

            state.helpDeskVideoSource = source;
            state.helpDeskActiveVideo = active;
            state.helpDeskVideoData = data;
         } else {
            state.helpDeskVideoSource = null;
            state.helpDeskActiveVideo = null;
            state.helpDeskVideoData = null;
         }
      },

      filterHelpDesk(state, newList = []) {
         state.helpDeskFilteredList = newList;
      },

      resetHelpDeskList(state) {
         state.helpList = null;
         state.helpDeskFilteredList = null;
      },

      help(state, setter = "show") {
         switch (setter) {
            case "show":
               state.display = true;
               break;
            case "hide":
               state.display = false;
               state.helpDeskVideoSource = null;
               break;
         }

         return;
      },

      // helpDeskPushStack(state, item) {
      //    // Blocks duplicated stacks
      //    if (!state.helpDeskStack.includes(item)) {
      //       // Adds new stack
      //       state.helpDeskStack.push(item);
      //    }
      // },

      helpDeskResetStack(state) {
         state.helpDeskStack = [];
      },

      helpDeskPopStack(state) {
         state.helpDeskStack.pop();
      },

      helpDeskRemoveSlugStack(state, slug) {
         const index = state.helpDeskStack.indexOf(slug);

         if (index !== -1) {
            return state.helpDeskStack.splice(index, 1);
         }
         return null;
      },

      newHelpList(state, newList) {
         state.helpList = { ...newList };
      },

      //AutoPlay Section
      newHelpInfos(state, info) {
         state.helpDeskAutoPlay.infos = { ...info };
      },

      resetHelpInfo(state) {
         state.helpDeskAutoPlay.infos = null;
      }
   }
};
