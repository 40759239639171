import { Requests } from "@/services/requests";

const ENDPOINTS = {
   CHAT: "planner-chat/"
};

const MSG = {
   CREATE_SUCCESS: "Criado com sucesso",
   GET_SUCCESS: "Retornados com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class PlannerChat {
   constructor(request) {
      this.request = request;
      this.moduleName = "PlannerChat";
      this.routes = {
         chat: {
            create: this.createChat.bind(this),
            get: this.getChat.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async createChat(payload) {
      return this.makeRequest({
         url: ENDPOINTS.CHAT,
         method: "post",
         body: { ...payload },
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async getChat() {
      return this.makeRequest({
         url: ENDPOINTS.CHAT,
         method: "GET",
         expectedStatus: {
            200: MSG.GET_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}
