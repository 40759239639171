export default {
   state: {
      hasModal: false,
      optionsDefault: [
         {
            name: "help",
            color: "primary",
            icon: "mdi-help",
            tooltipText: "Ajuda"
         }
      ],
      options: []
   },
   getters: {
      allOptions: (state, getters, rootState, rootGetters) => {
         const chatUserEnable = rootGetters["user/chatUserEnable"];
         return chatUserEnable ? [...state.optionsDefault, ...state.options] : [...state.options];
      },
      getHasModal: state => state.hasModal
   },
   actions: {},
   mutations: {
      resetOptions(state) {
         state.options = [];
      },
      setOptions(state, payload) {
         state.options = [...payload];
      },
      setHasModal(state, payload) {
         state.hasModal = payload;
      }
   }
};
