/**
 * The handler is responsible for managing bad requests
 *
 * When the backend throws a message, the handler is responsible for filtering the message back to the function
 * And when it's not possible, send a custom response like: "Sistema indisponível. Tente novamente mais tarde.".
 *
 * The handler is useful when the frontend needs to display the error message to the client.
 * So it doesn't need to expect every single case with status response.
 *
 * @example
 * request => Error { clientId: ["client id is expected"] }
 * handler => "client id is expected"
 */

import { Utils } from "@/utils/Utils";

const ERROR_TYPE_NOT_DEFINED = "Invalid error, cannot troubleshoot";
class Handler {
   constructor() {
      this.errorType = null;
      this.error = null;
      this.defaultMessage = null;
   }

   /**
    * Responsible for identifying the error response message
    *
    * @param {Error} error The error with the message
    * @param {string} defaultMessage A custom message if the message is not found in the error
    * @returns {string} The identified message
    */
   troubleshoot(
      error = null,
      defaultMessage = "Sistema indisponível. Tente novamente mais tarde."
   ) {
      if (!error) {
         throw Error(ERROR_TYPE_NOT_DEFINED);
      }

      const response = error.response;
      const self = this;

      if (error?.defaultError) {
         return error.defaultError;
      }

      if (error.data) {
         return Object.values(error.data)[0];
      }

      if (response) {
         this._resetConfig();

         this.error = error.response.data;
         this.defaultMessage = defaultMessage || this.defaultMessage;

         if (Utils.isArray(response.data)) {
            this.errorType = "array";
         } else {
            this.errorType = typeof response.data;
         }
      }

      if (self.errorType) {
         return this._findError(error);
      }

      return defaultMessage;
   }

   /**
    * Find the message inside the error
    *
    * @param {object | array} error The error holding the error message
    * @returns {string} With the resolved error message or a custom error message
    */
   _findError(error) {
      if (!error?.response) {
         return self.defaultMessage;
      }

      const errorData = error.response.data;

      switch (this.errorType) {
         case "array":
            return this._getArrayError(errorData);

         case "object":
            return this._getObjectError(errorData);

         default:
            return self.defaultMessage;
      }
   }

   /**
    * Identifies the error inside an array
    *
    * @param {array} errorData Array with the error message
    * @returns {string} The message
    */
   _getArrayError(errorData) {
      return errorData.map(e => e)[0];
   }

   /**
    * Identifies the error inside an object
    *
    * @param {object} errorData Object with the error message
    * @returns {string} The message
    */
   _getObjectError(errorData) {
      if (errorData.detail) {
         return errorData.detail;
      }

      const key = Object.keys(errorData)[0];

      if (key) {
         const innerObject = errorData[key];

         if (Utils.isArray(innerObject)) {
            return innerObject[0];
         } else if (Utils.isString(innerObject)) {
            return innerObject;
         } else if (Utils.isObject(innerObject)) {
            if (Object.values(innerObject)[0][0]) {
               return Object.values(innerObject)[0][0];
            }
         }
      }
      return Object.values(errorData)[0][0];
   }

   /**
    * Resets the settings to the default settings
    */
   _resetConfig() {
      this.errorType = null;
      this.error = null;
      this.defaultMessage = "Sistema indisponível. Tente novamente mais tarde.";
   }
}

const handler = new Handler();

export default handler;
