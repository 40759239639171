import { Mod } from "@/services/api";
import profiles from "@/user/roles";
import Vue from "vue";

export default {
   state: {
      user: {
         card: null,
         hash: null
      },
      persona: null,
      profiles: null,
      view: null,
      showAlertUpdateCreditCard: false
   },

   getters: {
      userPaymentInformation: state => state.user.card,
      userHasPendingCreditCard: state => {
         if (state.persona?.hasOwnProperty("has_pending_credit_card")) {
            return state.persona.has_pending_credit_card;
         }
         return false;
      },
      persona: state => state.persona,
      view: state => state.view,
      organizationFeatures(state) {
         const FEATURES = state?.persona?.organization?.features;

         if (FEATURES?.length) {
            return FEATURES.map(f => f.code);
         }
         return [];
      },

      tryoutUser(state, getters) {
         const FEATURES = getters.organizationFeatures;

         if (FEATURES?.length) {
            return FEATURES.includes("tryout");
         }
         return false;
      },

      chatUserEnable(state) {
         const FEATURES = state?.persona?.organization?.features;

         if (FEATURES?.length) {
            return FEATURES.some(f => f.code === "chat");
         }
         return false;
      },

      chatPlannerEnable(state) {
         const FEATURES = state?.persona?.organization?.features;

         if (FEATURES?.length) {
            return FEATURES.some(f => f.code === "chat_planner");
         }
         return false;
      },
      role(state) {
         if (!state.view) return {};

         const user = state.view.level;
         const roles = state.profiles.roles;

         const _getRole = role => user === roles[role].accessLevel;

         return {
            client: _getRole("client"),
            planner: _getRole("planner"),
            manager: _getRole("manager")
         };
      },
      userCanSeeToggleFeature(state, getters) {
         return featureName => {
            const FEATURES = getters.organizationFeatures;

            if (FEATURES) {
               return FEATURES.includes(featureName);
            }
            return false;
         };
      }
   },

   actions: {
      async getCreditCardInformation({ commit }) {
         const creditCardInfo = await Mod.find("payment", "card").info();
         commit("updateCreditCardInformation", creditCardInfo.data);
      },
      async me({ commit }, profileRoles) {
         profileRoles = profileRoles || profiles;

         const me = await Mod.find("me", "info", {
            softTokenAccess: true
         }).get();

         const value = me.data;

         if (value.data.is_external !== undefined) {
            localStorage.setItem("clienteExterno", value.data.is_external);
         }

         commit("setInfo", value);
         commit("setProfileList", profileRoles);
         commit("setProfileView", value.data);
         return value;
      },
      async tokenCheck({ commit }, headers) {
         const me = await Mod.find("me", "info").get(headers);

         commit("setInfo", me.data);
         return me.data.data;
      },
      async newCreditCard({ dispatch, commit }, payload) {
         let parts = payload.card_expiration_date.split("/");
         const exp_month = parts[0];
         const exp_year = parts[1];

         const result = {
            type: "card",
            card: {
               number: payload.card_number.replace(/\D/g, ""),
               holder_name: payload.card_holder_name,
               exp_month,
               exp_year,
               cvv: payload.card_cvv
            }
         };
         const card = await Mod.find("payment", "card").send(result);

         await Mod.find("payment", "card").create({
            card_token: card.data.id,
            address: payload.address,
            cpf: payload.cpf
         });

         commit("updateCreditCard", false);
         return dispatch("me");
      },
      async validateUserPassword(store, credential) {
         let passwordValidation = await Mod.find("profiles", "password").check({ ...credential });
         return passwordValidation.data.data;
      },
      async cancelSubscription() {
         return Mod.find("profiles", "subscription").cancel();
      },
      editPassword(store, credentials) {
         return Mod.find("auth", "user", {
            strictTokenAccess: true
         }).editPassword({
            new_password1: credentials.newPassword,
            new_password2: credentials.confirmNewPassword,
            old_password: credentials.currentPassword
         });
      },
      register(store, userData) {
         return Mod.find("auth", "user").register(userData);
      },
      forgotPassword(store, user) {
         return Mod.find("restAuth", "password").requestNew({
            email: user.email
         });
      },
      redefineNewPassword(store, user) {
         return Mod.find("restAuth", "password").change({
            uid: user.uid,
            token: user.token,
            new_password1: user.password,
            new_password2: user.confirmPassword
         });
      },
      validateEmail(store, token) {
         return Mod.find("profiles", "email").confirm({
            key: token
         });
      },
      verifyOrganization(store, organizationName) {
         return Mod.find("organization", "company").verify(organizationName);
      }
   },

   mutations: {
      changeProfileView(state, view) {
         state.view = view;
      },
      updateCreditCardInformation(state, cardInfo) {
         state.user.card = { ...cardInfo.data };
      },
      setInfo(state, info) {
         Vue.set(state, "persona", info.data);
      },
      setProfileList(state, profile) {
         state.profiles = profile;
      },
      setProfileView(state, data) {
         state.persona.view = state.profiles.primary(data);
         state.view = state.profiles.primary(data);
      },
      updateCreditCard(state, status = true) {
         state.showAlertUpdateCreditCard = status;
         return status;
      }
   }
};
