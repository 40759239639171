<template>
   <div class="menuHeader">
      <figure id="logoWrapper">
         <a v-if="link" :href="link" rel="noopener noreferrer">
            <img v-if="logo" :src="logo" :alt="name" :title="name" />
         </a>
         <img v-if="logo && !link" :src="logo" :alt="name" :title="name" />
      </figure>
   </div>
</template>

<script>
export default {
   name: "OrganizationLogo",
   props: {
      logo: {
         type: [String, Boolean],
         default: () => Boolean(false)
      },
      name: {
         type: String,
         default: () => String()
      },
      link: {
         type: String,
         default: null
      }
   }
};
</script>
