/**
 * This module is for general user actions and mutation control
 * eg: filter related
 *
 * This module should make use only of the new API structure
 * Modules as Mod
 */

// Requests
import { Mod } from "@/services/api";
import Download from "@/utils/Download";
import ComputeTransactionList from "@/views/plannerAsClient/shared/utils/computeTransactionList.js";
import Vue from "vue";

export default {
   state: {
      cashflow: [],
      category: [],
      config: {
         limit: "25",
         offset: "0",
         start: null,
         end: null,
         count: 0,
         origins: null,
         next: true,
         previous: false,
         clientId: null,
         showPlanned: "true",
         description: null,
         category: null,
         filterType: "transactions",
         isReactive: null,
         isExpense: null,
         isFinancialProduct: null,
         showDescriptions: null,
         identificationNumber: null
      },
      description: [],
      filtersFetchingTransactions: false,
      budgetPlan: {
         comparing: false
      },
      summary: {},
      transferer: {},
      taskList: null,
      origins: []
   },
   getters: {
      origins: state => state.origins,
      cashFlow: state => state.cashflow,
      descriptions: state => state.description,
      categories: state => state.category,
      transactions: state => {
         if (state.description?.length) {
            return new ComputeTransactionList({
               list: [...state.cashflow],
               descriptionList: [...state.description]
            });
         }
         return [];
      }
   },
   actions: {
      async downloadXLS({ state, dispatch }, props) {
         const requestClients = await dispatch("getClientAction");
         const req = await Mod.find("cashFlow", "transaction").download({
            ...state.config,
            ...props.type
         });

         const clientData =
            typeof props.clientId === "number"
               ? requestClients.filter(client => client.user.id === Number(props.clientId))[0]
               : props.clientId;

         Download.xls({
            file: req.data.data,
            clientData
         });

         return req.data.data;
      },

      /**
       * Gets all origins before filtering
       * @param {Object}
       */
      async getOrigins({ state, commit }, payload) {
         let clientId = state.config.clientId || null;
         if (payload) clientId = payload;

         const origins = await Mod.find("transactions", "labels", {
            strictTokenAccess: true
         }).origin({ ...(clientId && { clientId }) });

         commit("defineNewOrigin", origins.data.data);
         return origins.data;
      },
      /**
       * Gets category list before rendering category component
       * This is for displaying the category name instead of category value
       * @param {Object}
       */
      async getCategory({ commit, state }) {
         if (state?.category?.length > 0) {
            return state.category;
         }
         const categories = await Mod.find("transactions", "labels").category();

         commit("defineNewCategory", categories.data.data);
         return categories.data;
      },
      /**
       * Gets description list before rendering category component
       * This is for displaying the description name instead of description value
       * @param {Object}
       */
      async getDescription({ commit }) {
         const descriptions = await Mod.find("transactions", "labels").description();

         commit("defineNewDescription", descriptions);
         return descriptions;
      },
      /**
       * Replacement for getDescription and getCategory
       * @param {Object}
       * @returns {Object} description and categories
       */
      async getTransactionLabels({ commit }) {
         const descriptions = await Mod.find("transactions", "labels").description();
         const categories = await Mod.find("transactions", "labels").category();

         commit("defineNewDescription", descriptions);
         commit("defineNewCategory", categories.data.data);

         return {
            descriptions,
            categories
         };
      },
      /**
       * Uses the default {state.config} to filter
       * Called on init of the SlimFilter component
       * @param {Object}
       * @param isAttTransfererPatrimonial
       */
      async filterByConfig(
         { state, commit, getters, dispatch },
         isAttTransfererPatrimonial = true
      ) {
         commit("filtersLoading", true);

         try {
            const cashflow = await Mod.find("cashFlow", "display").filter(state.config);

            if (getters.role.planner) {
               await dispatch("filterSummary");
            }

            commit("defineCashflow", cashflow.data.data);
            if (isAttTransfererPatrimonial) {
               await dispatch("getCashFlowTransfer");
            }

            return cashflow.data;
         } catch (err) {
            throw Error(err);
         } finally {
            commit("filtersLoading", false);
         }
      },
      /**
       * Summary is called when ever SlimFilter changes configuration and when
       * A planner starts category or product table
       * @param {Object}
       */
      async filterSummary({ state, commit }) {
         try {
            commit("filtersLoading", true);
            const summary = await Mod.find("cashFlow", "summary").filter(state.config);

            commit("storeSummary", summary.data.data);
            return summary.data;
         } catch (err) {
            throw Error(err);
         } finally {
            commit("filtersLoading", false);
         }
      },
      async getCashFlowTransfer({ state, commit }) {
         try {
            const summary = await Mod.find("cashFlow", "summary").transfer(state.config);

            commit("storeTransferer", summary.data.data);
            return summary.data;
         } catch (err) {
            throw Error(err);
         }
      },
      /**
       * Config setter for summary tables, using descriptions and categories
       * @param {Object}
       * @param {Object} config -> { description, category }
       */
      async applyMiscFilterConfig({ commit, dispatch }, config) {
         try {
            commit("resetFilterMiscConfig");
            commit("setFilterConfig", config);
            commit("changeFilterType", "category");

            return await dispatch("filterByConfig", false);
         } catch (err) {
            throw Error(err);
         }
      }
   },
   mutations: {
      clearCashflow(state) {
         state.cashflow = [];
      },
      changeBudgetPlanningType(state, bool) {
         state.budgetPlan.comparing = bool;
      },
      defineCashflow(state, cashflow) {
         state.cashflow = cashflow?.results || [];

         state.config.next = Boolean(cashflow.next);
         state.config.previous = Boolean(cashflow.previous);
         state.config.count = cashflow.count;
      },
      defineNewOrigin(state, originList) {
         state.origins = originList;
      },

      defineNewCategory(state, categoryList) {
         state.category = categoryList;
      },
      defineNewDescription(state, descriptionList) {
         state.description = descriptionList;
      },
      filtersLoading(state, bool) {
         state.filtersFetchingTransactions = bool;
      },
      manageDescriptionExceptions(state, list) {
         state.config.showDescriptions = list ? [...list] : null;
      },
      resetFilterMiscConfig(state, hard = false) {
         state.config.description = null;
         state.config.category = null;
         state.config.isExpense = null;
         state.config.isFinancialProduct = null;
         state.config.filterType = "transactions";
         state.config.origins = null;
         state.config.identificationNumber = null;

         if (hard) {
            state.config.start = null;
            state.config.end = null;
         }

         state.config.offset = "0";
         state.config.limit = "25";

         state.config.showDescriptions = null;
      },
      setFilterConfig(state, props) {
         for (let key in props) {
            state.config[key] = props[key];
         }
      },
      storeSummary(state, summary) {
         state.summary = summary;
      },
      storeTransferer(state, transferer) {
         state.transferer = transferer;
      },
      changeFilterType(state, type) {
         state.filterType = type;
      },

      updateTransactionItem(state, transaction) {
         const modify = !transaction.is_asset_transfer ? state.summary : state.transferer;
         if (transaction.amount > 0) {
            if (modify && modify.income && modify.income.length > 0) {
               const index = modify.income.findIndex(
                  income => income.descriptionId === transaction.description
               );
               if (index !== -1) {
                  if (transaction.ignored) {
                     modify.income[index].descriptionTotal =
                        modify.income[index].descriptionTotal - transaction.amount;
                  } else {
                     modify.income[index].descriptionTotal =
                        modify.income[index].descriptionTotal + transaction.amount;
                  }
               }
            }
         } else if (modify && modify.expense && modify.expense.length > 0) {
            const index = modify.expense.findIndex(
               expense => expense.categoryId === transaction.category
            );

            if (index !== -1) {
               let indexDescription = -1;
               if (
                  modify.expense[index].descriptions &&
                  modify.expense[index].descriptions.length > 0
               ) {
                  indexDescription = modify.expense[index].descriptions.findIndex(
                     expense => expense.descriptionId === transaction.description
                  );
               }

               if (transaction.ignored) {
                  modify.expense[index].categoryTotal =
                     modify.expense[index].categoryTotal - transaction.amount;
                  if (indexDescription !== -1) {
                     modify.expense[index].descriptions[indexDescription].descriptionTotal =
                        modify.expense[index].descriptions[indexDescription].descriptionTotal -
                        transaction.amount;
                  }
               } else {
                  modify.expense[index].categoryTotal =
                     modify.expense[index].categoryTotal + transaction.amount;
                  if (indexDescription !== -1) {
                     modify.expense[index].descriptions[indexDescription].descriptionTotal =
                        modify.expense[index].descriptions[indexDescription].descriptionTotal +
                        transaction.amount;
                  }
               }
            }
         }
         const index = state.cashflow.findIndex(item => item.id === transaction.id);
         Vue.set(state.cashflow, index, { ...transaction });
         if (modify && modify.total_savings !== undefined && modify.total_savings !== null) {
            if (transaction.ignored) {
               modify.total_savings = modify.total_savings - transaction.amount;
            } else {
               modify.total_savings = modify.total_savings + transaction.amount;
            }
         }
         if (!transaction.is_asset_transfer) {
            state.summary = modify;
         } else {
            state.transferer = modify;
         }
      }
   }
};
