<script>
function _getPosition(element) {
   var xPosition = 0;
   var yPosition = 0;

   while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
   }

   return { x: xPosition, y: yPosition };
}

export default {
   name: "CardBodyStickyFunction",
   data() {
      return {
         stickyComponent: null,
         stickHeader: false,
         windowContainer: null,
         headerContainer: null,
         headerContainerSize: 0,
         headerContainerHeight: 0
      };
   },
   computed: {
      shouldStick() {
         if (this.stickyHeader) {
            return this.stickHeader;
         } else {
            return false;
         }
      },
      hasStickyStatement() {
         return this.stickyHeader || false;
      },
      headerWidth() {
         if (this.headerContainerSize > 0 && this.stickHeader) {
            return `width: ${this.headerContainerSize}px`;
         } else {
            return "";
         }
      },
      headerHeight() {
         if (this.headerContainerHeight > 0 && this.stickHeader) {
            return `padding-top: ${this.headerContainerHeight}px`;
         } else {
            return "";
         }
      }
   },
   methods: {
      init() {
         if (this.hasStickyStatement) {
            this.stickyComponent = this.$refs.CardComponent;

            this.setWindowContainer();
            this.addEvent();
         }
      },
      addEvent() {
         this.windowContainer.addEventListener("scroll", this.scrollCallback);
      },
      removeEvent() {
         this.windowContainer.removeEventListener("scroll", this.scrollCallback);
      },
      setContainerSize(header) {
         this.headerContainerSize = header.offsetWidth;
         this.headerContainerHeight = header.offsetHeight;
      },
      setWindowContainer() {
         let component = this.stickyComponent;

         this.windowContainer = document.querySelector("#mainContainer");
         this.headerContainer = component.querySelector(".componentHeader");
      },
      scrollCallback(e) {
         if (this.hasStickyStatement) {
            const SCROLL = e.target.scrollTop;
            const HEADER_HEIGHT = this.headerContainer.offsetHeight;

            let componentOffsetTop = _getPosition(this.stickyComponent).y;
            let distanceLogic = Math.round(HEADER_HEIGHT + componentOffsetTop);

            let componentTotalHeight = this.stickyComponent.offsetHeight;

            let maxScrollHeight =
               componentTotalHeight + componentOffsetTop - Math.round(HEADER_HEIGHT * 3);

            this.setContainerSize(this.headerContainer);

            if (SCROLL >= distanceLogic && SCROLL < maxScrollHeight) {
               this.stickHeader = true;
            } else {
               this.stickHeader = false;
            }
         }
      }
   },
   mounted() {
      this.init();
   },
   beforeDestroy() {
      if (this.hasStickyStatement) {
         this.removeEvent();
      }
   }
};
</script>
