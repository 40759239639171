// Services
import { Mod } from "@/services/api";

export default {
   state: {
      token: null
   },

   getters: {
      isAuthenticated(state) {
         if (!!state.token && state.token !== "null" && state.token !== "undefined") {
            return !!state.token;
         } else if (!state.token) {
            return !!localStorage.token;
         }
      },
      localToken(state) {
         if (this.getters.isAuthenticated) {
            return state.token;
         }
      }
   },

   actions: {
      async checkAuthentication({ commit }) {
         return new Promise((resolve, reject) => {
            const TOKEN = localStorage.token;
            const ORG = localStorage.organization;

            if (TOKEN) {
               commit("userToken", TOKEN);
               resolve(TOKEN);
            } else {
               localStorage.clear();
               localStorage.organization = ORG;

               reject(false);
            }
         });
      },
      async loginUser({ commit }, credential) {
         let req = await Mod.find("auth", "session").login(credential);
         if (req?.data?.status == 200) {
            commit("userToken", req.data.data.key);
         }
         return req;
      },
      async loginNoCredential({ commit, dispatch }, token) {
         commit("userToken", token);

         await dispatch("getSessionStatus");
         const user = await dispatch("me");
         if (user) {
            localStorage.setItem("organization", user.data.organization.slug);
            localStorage.setItem("org", user.data.organization);
         }

         commit("newOrganization", user.data.organization);
         commit("brandOrganization", user.data.organization.slug);

         return user.data;
      }
   },

   mutations: {
      userToken(state, newToken) {
         localStorage.token = newToken;
         state.token = newToken;
      }
   }
};
