const futureFlowMonthData = {
   data: {
      data: [
         {
            id: "10",
            date: "03/2021",
            taskStatus: "done",
            summary: {
               income: 7580.52,
               expense: 5200
            },
            planned: {
               income: 0,
               expense: 0
            }
         },
         {
            id: "2",
            date: "05/2021",
            taskStatus: "done",
            summary: {
               income: 8300,
               expense: 4700.8
            },
            planned: {
               income: 7500.0,
               expense: 5000
            }
         },
         {
            id: "3",
            date: "06/2021",
            taskStatus: "done",
            summary: {
               income: 4500,
               expense: 0
            },
            planned: {
               income: 0,
               expense: 4000
            }
         },
         {
            id: "4",
            date: "07/2021",
            taskStatus: "done",
            summary: {
               income: 0,
               expense: 3750
            },
            planned: {
               income: 4000,
               expense: 0
            }
         },
         {
            id: "5",
            date: "08/2021",
            taskStatus: "done",
            summary: {
               income: 7580.52,
               expense: 5200
            },
            planned: {
               income: 0,
               expense: 0
            }
         },
         {
            id: "6",
            date: "09/2021",
            taskStatus: "done",
            summary: {
               income: 8300,
               expense: 4700.8
            },
            planned: {
               income: 7500.0,
               expense: 5000
            }
         },
         {
            id: "7",
            date: "10/2021",
            taskStatus: "pending",
            summary: {
               income: 4500,
               expense: 0
            },
            planned: {
               income: 0,
               expense: 4000
            }
         },
         {
            id: "8",
            date: "11/2021",
            taskStatus: "pending",
            summary: {
               income: 0,
               expense: 3750
            },
            planned: {
               income: 4000,
               expense: 0
            }
         },
         {
            id: "9",
            date: "12/2021",
            taskStatus: "pending",
            summary: {
               income: 4500,
               expense: 0
            },
            planned: {
               income: 0,
               expense: 4000
            }
         },
         {
            id: "1",
            date: "01/2022",
            taskStatus: "pending",
            summary: {
               income: 0,
               expense: 3750
            },
            planned: {
               income: 4000,
               expense: 0
            }
         },
         {
            id: "11",
            date: "02/2022",
            taskStatus: "pending",
            summary: {
               income: 0,
               expense: 3750
            },
            planned: {
               income: 4000,
               expense: 0
            }
         }
      ]
   }
};

const futureFlowPrediction = {
   data: {
      data: [
         {
            name: "Saldo planejado",
            ticks: 12,
            data: [
               { date: "01/2021", amount: 8000 },
               { date: "02/2021", amount: 7000 },
               { date: "03/2021", amount: 6000 },
               { date: "04/2021", amount: 3000 },
               { date: "05/2021", amount: 2000 },
               { date: "06/2021", amount: 0 },
               { date: "07/2021", amount: -2000 },
               { date: "08/2021", amount: -2500 },
               { date: "09/2021", amount: -3000 },
               { date: "10/2021", amount: -5000 },
               { date: "11/2021", amount: -7000 },
               { date: "12/2021", amount: -8500 }
            ]
         },
         {
            name: "Saldo projetado",
            ticks: 2,
            data: [
               { date: "01/2021", amount: 5000 },
               { date: "02/2021", amount: 6500 },
               { date: "03/2021", amount: 5300 },
               { date: "04/2021", amount: 4200 },
               { date: "05/2021", amount: 1000 },
               { date: "06/2021", amount: 0 },
               { date: "07/2021", amount: -100 },
               { date: "08/2021", amount: -500 },
               { date: "09/2021", amount: -1000 },
               { date: "10/2021", amount: -2000 },
               { date: "11/2021", amount: -3800 },
               { date: "12/2021", amount: -5300 }
            ]
         }
      ]
   }
};

const Mock = {
   futureFlowMonths() {
      return this._async(futureFlowMonthData);
   },
   futureFlowPrediction() {
      return this._async(futureFlowPrediction);
   },
   _async(response) {
      return new Promise(resolve => {
         setTimeout(() => {
            resolve(response);
         }, 1000);
      });
   }
};

export default Mock;
