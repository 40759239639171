<template>
   <user-menu />
</template>

<script>
import UserMenu from "@/shared/components/userMenu/UserMenu.vue";
import "@/style/menu.scss";

export default {
   name: "Menu",
   components: {
      UserMenu
   }
};
</script>
