const events = [
   {
      id: 38,
      title: "Lembrete de Gasto Fixo",
      content: "MERCADO EXEMPLO 3",
      reminder_date: "2022-01-25",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38634
   },
   {
      id: 39,
      title: "Lembrete de Gasto Fixo",
      content: "TRANS 1",
      reminder_date: "2021-12-10",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38642
   },
   {
      id: 40,
      title: "Lembrete de Gasto Fixo",
      content: "MERCADO EXEMPLO 4",
      reminder_date: "2021-12-03",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38635
   },
   {
      id: 41,
      title: "Lembrete de Gasto Fixo",
      content: "MERCADO EXEMPLO 5",
      reminder_date: "2021-12-23",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38636
   },
   {
      id: 42,
      title: "Lembrete de Gasto Fixo",
      content: "TRANS 4",
      reminder_date: "2021-12-30",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38639
   },
   {
      id: 43,
      title: "Lembrete de Gasto Fixo",
      content: "MERCADO EXEMPLO 5",
      reminder_date: "2021-12-19",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38636
   },
   {
      id: 44,
      title: "Lembrete de Gasto Fixo",
      content: "TRANS 4",
      reminder_date: "2021-12-08",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38639
   },
   {
      id: 45,
      title: "Lembrete de Gasto Fixo",
      content: "MERCADO EXEMPLO 5",
      reminder_date: "2021-12-03",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38636
   },
   {
      id: 46,
      title: "Lembrete de Gasto Fixo",
      content: "TRANS 4",
      reminder_date: "2021-12-01",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38639
   },
   {
      id: 47,
      title: "Lembrete de Gasto Fixo",
      content: "MERCADO EXEMPLO 5",
      reminder_date: "2021-12-18",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38636
   },
   {
      id: 48,
      title: "Lembrete de Gasto Fixo",
      content: "TRANS 4",
      reminder_date: "2021-12-28",
      periodicity: "MONTHLY",
      type: "RECOMMENDER",
      user_reminder: 352,
      cashflow_reminder: 38639
   }
];

const Mock = {
   _async(response) {
      return new Promise(resolve => {
         resolve(response);
      });
   },
   events() {
      return this._async(events);
   },
   deleteEvent(id) {
      const index = events.map(event => event.id).indexOf(id);
      events.splice(index, 1);
   }
};

export default Mock;
