export default {
   state: {
      token: {
         valid: false
      },
      tokenReady: false
   },
   getters: {
      userAuthenticated: state => state.token.valid,
      tokenLoaded: state => state.tokenReady
   },
   actions: {
      /**
       * Validates token using /me endpoint.
       * If the token isn't valid, kicks the user.
       *
       * @param {object} Store
       * @returns {void}
       */
      async preventBadToken({ commit, dispatch }) {
         try {
            await dispatch("me");
            dispatch("authenticateToken");

            return;
         } catch (err) {
            let hasBadToken = localStorage.token;

            commit("tokenDone", true);

            if (hasBadToken) {
               dispatch("kickUser");
            }
            return;
         }
      },

      /**
       * Updates token to localStorage and is cached.
       * This step is only called when the token is valid.
       * Sets a valid flag to the token config.
       *
       * @param {object} Store
       */
      authenticateToken({ commit, dispatch }) {
         commit("updateTokenConfig", true);

         dispatch("registerTokenConfig");
      },

      /**
       * Validates the token
       *
       * @param {object} Store
       */
      validateToken({ dispatch }) {
         dispatch("readTokenConfig");
      },

      /**
       * Validates token flag, when it's valid, jumps to the timer validation.
       * When the token doesn't have a valid flag, checks bad names with preventBadToken.
       *
       * @param {object} Store
       * @returns {void}
       */
      readTokenConfig({ state, commit, dispatch }) {
         let localTokenConfig = localStorage.tokenConfig;

         if (localTokenConfig) {
            let tokenData = { ...JSON.parse(localTokenConfig) };

            commit("updateTokenConfig", tokenData.valid);

            if (state.token.valid) {
               commit("tokenDone", true);
               dispatch("startSessionTimer");
               return;
            }
         }
         dispatch("preventBadToken");
      },

      /**
       * Registers the token to the localStorage.
       * Starts a session.
       *
       * @param {object} Store
       */
      registerTokenConfig({ state, commit, dispatch }) {
         localStorage.tokenConfig = JSON.stringify(state.token);
         commit("tokenDone", true);

         dispatch("startSessionTimer");
      }
   },
   mutations: {
      resetTokenConfig(state) {
         state.token.valid = false;
      },

      tokenDone(state, status) {
         state.tokenReady = status;
      },

      updateTokenConfig(state, props) {
         state.token.valid = props;
      }
   }
};
