import { Mod } from "@/services/api";
import Mock from "@/__mocks__/account-badge-data.js";

const TESTING = false;

export default {
   state: {
      bankAccountData: [],
      bankAccountDetails: [],

      creditCardDetails: {},
      cardStatmentDetails: {}
   },
   getters: {},
   actions: {
      async getBankAccounts({ commit }, payload) {
         let reqAccount, reqDetails;

         if (TESTING) {
            reqAccount = await Mock.badge();
            reqDetails = await Mock.cards();
         } else {
            reqAccount = await Mod.find("myAssets", "details").badge(payload);
            reqDetails = await Mod.find("myAssets", "details").cards(payload);
         }

         commit("updateBankAccount", reqAccount.data.data);
         commit("updateBankDetails", reqDetails.data.data);
         return { reqAccount, reqDetails };
      },
      async getCreditCardDetails({ commit }, config) {
         let reqCardDetails;
         reqCardDetails = await Mod.find("myAssets", "details").creditCard(config);
         commit("updateCreditCardDetails", reqCardDetails.data.data);

         return reqCardDetails.data.data;
      },

      async updateAccount(_, config) {
         return Mod.find("myAssets", "update").updateAccount(config);
      },

      async getStatmentDetails({ commit }, config) {
         let reqCardStatment;
         reqCardStatment = await Mod.find("myAssets", "details").cardStatment(config);
         commit("updateCardStatmentDetails", reqCardStatment.data.data);

         return reqCardStatment.data.data;
      },
      async createDebt(_, payload) {
         await Mod.find("debts", "debts").create(payload);
      },
      async deleteDebt(_, payload) {
         await Mod.find("debts", "debts").delete(payload);
      },
      async updateDebt(_, payload) {
         await Mod.find("debts", "debts").update(payload);
      }
   },
   mutations: {
      updateBankAccount(state, bankData) {
         state.bankAccountData = [...bankData];
      },
      updateBankDetails(state, bankDetails) {
         state.bankAccountDetails = [...bankDetails];
      },
      updateCreditCardDetails(state, cardDetails) {
         state.creditCardDetails = cardDetails;
      },
      updateCardStatmentDetails(state, cardStatment) {
         state.cardStatmentDetails = cardStatment;
      }
   }
};
