import { ReturnError } from "@/utils/error";
import Api, { Mod } from "@/services/api";

export default {
   state: {
      cards: [],
      categorizingPeriod: [],
      clientID: "0",
      importBundleID: "0",
      myTasks: [],
      statusFilter: "pending",
      budgetTasks: [],
      task: {
         categorizing: {
            taskID: "0",
            start: new Date().toISOString().substr(0, 10),
            end: new Date().toISOString().substr(0, 10)
         }
      }
   },

   getters: {
      taskCards: state => state.cards,
      myTasks: state => state.myTasks.filter(task => task.status == "pending"),
      categorizingDates: state => state.task.categorizing,
      importBundle: state => state.importBundleID
   },

   actions: {
      async changeTaskStatus({ dispatch }, cardData) {
         await Api.changeCardStatus(cardData);
         dispatch("fetchTasks");
      },
      async createTask({ state, dispatch }, taskType) {
         let options;
         switch (taskType) {
            case "import":
            case "budget-planning":
               options = {
                  client: state.clientID,
                  type: taskType
               };
               break;
            case "categorizing":
               options = {
                  client: state.clientID,
                  type: taskType,
                  start: state.categorizingPeriod[0],
                  end: state.categorizingPeriod[1]
               };
               break;
            default:
               return;
         }

         let newTask = await Api.createTask(options);
         switch (newTask.status) {
            case 201:
               dispatch("fetchTasks");
               return newTask.data;
            default:
               throw new ReturnError({
                  text: "Unexpected error",
                  status: newTask.status
               });
         }
      },
      async fetchTasks({ state, commit }, taskStatus = state.statusFilter) {
         commit("updateCards", new Array());
         let cardContent = await Api.fetchTasksType({
            status: taskStatus,
            clientID: state.clientID
         });
         commit("pushCards", cardContent.data);
         return cardContent;
      },

      async fetchBudgetTasks({ commit }, taskStatus = "done") {
         const req = await Mod.find("tasks", "clientTasks").budget({ status: taskStatus });

         commit("myBudgetTaskUpdate", req.data.data);
         return req.data.data;
      },

      async getTaskImportBundle({ commit }, bundleID) {
         let bundle = await Api.getTaskImportBundle(bundleID);
         commit("redirectBundleID", bundle.data);
      },
      async getCategorizingDates({ commit }, taskId) {
         let task = await Api.getCategorizingDates(taskId);
         commit("updateCategorizingDates", task.data);
      },
      async getMyTasks({ state }) {
         let tasks = await Api.getMyTasks();
         state.myTasks = tasks.data;
      },
      async removeTask({ dispatch }, taskID) {
         await Api.removeTask(taskID);
         dispatch("fetchTasks");
      }
   },

   mutations: {
      defineClient(state, ID) {
         state.clientID = ID;
      },
      myBudgetTaskUpdate(state, budgetList) {
         state.budgetTasks = budgetList;
      },
      pushCards(state, cardList) {
         state.cards.push(...cardList);
      },
      redirectBundleID(state, bundleData) {
         state.importBundleID = bundleData.bundle;
      },
      setCategorizingPeriod(state, period) {
         state.categorizingPeriod = period;
      },
      updateCards(state, content) {
         state.cards = content;
      },
      updateCardStatus(state, cardData) {
         let relevantCard = state.cards.filter(card => card.id == cardData.taskID);
         relevantCard.map(card => (card.status = cardData.status));
      },
      updateCategorizingDates(state, taskData) {
         state.task.categorizing.taskID = taskData.id;
         state.task.categorizing.start = taskData.start;
         state.task.categorizing.end = taskData.end;
      },
      updateCategorizingTaskID(state, taskID) {
         state.task.categorizing.taskID = taskID;
      },
      updateSelectedItems(state, items) {
         state.statusFilter = items;
      }
   }
};
