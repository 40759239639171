import { Utils } from "./Utils";

const DEVELOPMENT = process.env.NODE_ENV === "development";

const error = {
   FUNCTION_NAME_REQUIRED: "Deprecated function name is expected and required."
};

/**
 * A function to help alert deprecated functions, only warning when using development environment
 *
 * @param {string} filePath
 * @param {string} fnName
 * @returns {void}
 */
const Deprecated = ({ filePath = "", fnName = "" }) => {
   if (!DEVELOPMENT) {
      return;
   }
   if (!Utils.isString(fnName)) {
      throw Error(error.FUNCTION_NAME_REQUIRED);
   }

   let warning = `Using deprecated function ${fnName}`;

   if (filePath) {
      console.warn(`${warning}, path: ${filePath}`); // eslint-disable-line no-console
   } else {
      console.warn(warning); // eslint-disable-line no-console
   }
};

export default Deprecated;
