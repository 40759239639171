/**
 * This module is for general user actions and mutation control
 * eg: filter related
 *
 * This module should make use only of the new API structure
 * Modules as Mod
 */

import { Mod } from "@/services/api";
import bankIntegrationAlertList from "@/utils/bankIntegrationList";
import { Quanto } from "@/utils/integration.js";

export default {
   state: {
      quanTo: null,
      bank_code: null
   },

   getters: {
      BankCode: state => state.bank_code
   },

   actions: {
      sessionConnectQuanto({ commit }, props) {
         const ENV = process.env.VUE_APP_QUANTO_ENV;

         let connect = new Quanto({
            session: props.key,
            env: ENV
         });

         connect.onSuccess(props.onSuccess);
         connect.onExit(props.onExit);

         commit("quantoSession", connect);
         return connect;
      },
      async startBS2IntegrationSession() {
         let request = await Mod.find("integration", "session").startBS2();

         return request.data;
      },
      async startQuantoIntegrationSession() {
         let request = await Mod.find("integration", "session").startQuanto();

         return request.data;
      },

      async startBelvoIntegrationSession(_, bankCode) {
         let request = await Mod.find("integration", "session").startBelvo(bankCode);

         return request.data.data;
      },

      async belvoCreateLink({ commit }, body) {
         let request = await Mod.find("integration", "belvo")
            .createLink(body)
            .then(() => {
               if (bankIntegrationAlertList[body.institution]) {
                  commit("bankCode", body.institution);
               }
            });

         return request.data;
      }
   },

   mutations: {
      quantoSession(state, props) {
         state.quanTo = props;
      },
      bankCode(state, props) {
         state.bank_code = props;
      }
   }
};
