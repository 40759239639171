/**
 * Organization Methods
 *
 * We first check the organization, if the local organization registered is valid then,
 * we can check if the organization is valid, if not then we kick the user to the 404 page (no organization).
 *
 * If the user doesn't have an organization the user should be kicked to the 404 page
 *
 */
import Router from "@/router";
import storedOrganizationName from "./shared/storedOrganizationName";
function validateRoute(name) {
   Router.replace({ params: { organization: name } }).catch(() => {});
}

export default {
   state: {
      organization: {
         name: null,
         valid: false
      },
      orgReady: false
   },
   getters: {
      organizationName: state => state.organization.name,
      organizationLoaded: state => state.orgReady
   },
   actions: {
      /**
       * Validates organization name, when valid, saves on localStorage
       *
       * @param {object} Store
       */
      async defineOrganizationColors({ commit, dispatch, getters }) {
         try {
            await dispatch("setOrganizationLayout", getters.organizationName);

            commit("organizationDone", true);
            dispatch("validateToken");
         } catch (err) {
            dispatch("hardKickUser");
         }
      },

      /**
       * Starts the token validation stage
       *
       * @param {object} Store
       */
      async jumpToTokenStage({ commit, dispatch }) {
         try {
            let meData = await dispatch("me");
            let organizationData = meData.data.organization;

            commit("overRightOrgConfig", organizationData.slug);

            dispatch("defineOrganizationColors");
            dispatch("validateToken", true);
         } catch (err) {
            commit("tokenDone", true);

            dispatch("kickUser");
            dispatch("readOrganizationConfig");
         }
      },

      /**
       * Validates organization name, if false, resets localStorage config
       *
       * @param {object} Store
       * @returns {void}
       */
      async validateOrganizationName({ state, dispatch, commit }) {
         const ORG_NAME = state.organization.name;
         try {
            let orgReq = await dispatch("verifyOrganization", ORG_NAME);

            if (orgReq.data.status === 200) {
               localStorage.setItem("org", JSON.stringify(orgReq.data.data));
               state.organization.name = orgReq.data.data.slug;
               localStorage.setItem(
                  "organizationConfig",
                  JSON.stringify({ name: orgReq.data.data.slug, valid: true })
               );

               commit("updateTheme", orgReq.data.data);
               commit("authenticateOrganization", orgReq.data.data);
               dispatch("registerOrganizationConfig");
            }
         } catch (err) {
            const organizationSlug = localStorage.getItem("organization");
            if (organizationSlug && organizationSlug !== ORG_NAME) {
               state.organization.name = organizationSlug;
               localStorage.setItem(
                  "organizationConfig",
                  JSON.stringify({ name: organizationSlug, valid: true })
               );
               dispatch("hardKickLogin");
            }
            dispatch("hardKickUser");
         }
      },
      /**
       * Read organization config from localStorage
       *
       * @param {object} Store
       * @returns {void}
       */
      readOrganizationConfig({ dispatch, commit, state }) {
         let localOrgConfig = localStorage.organizationConfig;

         let orgName = localOrgConfig
            ? JSON.parse(localOrgConfig).name
               ? JSON.parse(localOrgConfig).name
               : null
            : null;

         if (localOrgConfig) {
            commit("updateOrganizationConfig", orgName);

            if (state.organization.valid) {
               dispatch("defineOrganizationColors");
               return;
            } else {
               dispatch("validateOrganizationName");
            }
         }
         dispatch("preventBadOrgName");
      },

      /**
       * Registers the organization config on localStorage
       *
       * @param {object} Store
       */
      registerOrganizationConfig({ state, dispatch }) {
         localStorage.organizationConfig = JSON.stringify(state.organization);
         dispatch("defineOrganizationColors");
      },

      /**
       * Starts the organization validation
       * Jumps to token validation when localStorage org config has a valid flag
       *
       * @param {object} Store
       */
      async initOrganizationConfig({ state, commit, dispatch }) {
         let hasToken = localStorage.tokenConfig ? JSON.parse(localStorage.tokenConfig) : {};

         commit("organizationDone", false);
         commit("tokenDone", false);

         if (hasToken && hasToken.valid) {
            dispatch("jumpToTokenStage");
            commit("organizationDone", true);
         } else {
            const organizationSalva = localStorage.getItem("organization");
            const organizationUrl = Router.history.current.params.organization;
            if (organizationSalva && organizationUrl && organizationUrl !== organizationSalva) {
               try {
                  let orgReq = await dispatch("verifyOrganization", organizationUrl);

                  if (orgReq.data.status === 200) {
                     localStorage.setItem("org", JSON.stringify(orgReq.data.data));
                     state.organization.name = orgReq.data.data.slug;
                     localStorage.setItem(
                        "organizationConfig",
                        JSON.stringify({ name: orgReq.data.data.slug, valid: true })
                     );
                     localStorage.setItem("organization", orgReq.data.data.slug);
                     commit("updateTheme", orgReq.data.data);
                     commit("authenticateOrganization", orgReq.data.data);
                     dispatch("registerOrganizationConfig");
                     return;
                  }
               } catch (err) {
                  validateRoute(organizationSalva);
               }
            }
            dispatch("readOrganizationConfig");
         }
      },

      /**
       * Validates an organization name, using storedOrganizationName function to filter out bad names (null, undefined...).
       * After receiving the filtered data, it updates and validates the name, checking the organization endpoint.
       *
       * @param {object} Store
       * @returns {void}
       */
      preventBadOrgName({ commit, dispatch }) {
         let organizationName = storedOrganizationName();

         if (organizationName) {
            commit("updateOrganizationConfig", organizationName);
            dispatch("validateOrganizationName");

            validateRoute(organizationName);
            return;
         }
         commit("organizationDone", true);
         dispatch("hardKickUser");
      }
   },
   mutations: {
      authenticateOrganization(state, reqData) {
         state.organization.valid = !!reqData;
      },

      resetOrganizationConfig(state) {
         state.organization.name = null;
         state.organization.valid = false;
         state.orgReady = false;
      },

      updateOrganizationConfig(state, organizationName) {
         let storeOrgConfig = state.organization;

         if (storeOrgConfig.valid) {
            if (organizationName !== storeOrgConfig.name) {
               Router.replace({ params: { organization: storeOrgConfig.name } }).catch(() => {});

               return;
            }
         }
         state.organization.name = organizationName;
      },

      overRightOrgConfig(state, orgName) {
         state.organization.name = orgName;

         localStorage.organizationConfig = JSON.stringify({
            name: orgName,
            valid: true
         });
      },

      organizationDone(state, status) {
         state.orgReady = status;
      }
   }
};
