const links = [
   {
      id: 200,
      name: "planejadores",
      text: "Planejadores",
      icon: "mdi-account-group",
      permission: true
   },

   {
      id: 202,
      name: "clients",
      text: "Clientes",
      icon: "mdi-format-list-bulleted-square",
      permission: true
   },
   {
      id: 203,
      name: "configuracao-financeiro",
      text: "Financeiro",
      icon: "mdi-currency-usd",
      permission: true
   },
   {
      id: 204,
      name: "configuracao-organizacao",
      text: "Configuração",
      icon: "mdi-cog-outline",
      permission: true
   }
];

export default links;
