/* Assets */
/* Caixa */
import caixa_senhaseconfiguracoes from "@/assets/bankIntegration/caixa/senhaseconfiguracoes.jpg";
import caixa_gerenciardispositivos from "@/assets/bankIntegration/caixa/gerenciardispositivos.jpg";
import caixa_permitirdispositivo from "@/assets/bankIntegration/caixa/permitirdispositivo.png";
/*  */

export default {
   caixa_br_retail: {
      name: "Caixa",
      steps: ["Senhas e configurações", "Gerenciar dispositivos", "Dispositivos cadastrados"],
      assets: [caixa_senhaseconfiguracoes, caixa_gerenciardispositivos, caixa_permitirdispositivo]
   }
};
