import Api from "@/services/api";
import { ReturnError } from "@/utils/error";
import { ReturnErrorUpload } from "@/utils/errorUpload";

export default {
   state: {
      acceptedFileType: ["xlsx", "ofx", "xls"],
      appendToBundle: false,
      bundleID: "",
      files: [],
      rejectedFiles: [],
      requestData: {}
   },

   getters: {
      acceptedFiles: state => state.acceptedFileType,
      rejectedFiles: state => state.rejectedFiles,
      requestData: state => state.requestData
   },

   actions: {
      async sendFiles({ state, commit }, sendData) {
         let formData, dispatchList, sentStatus, fileList, $package;

         formData = new FormData();
         fileList = sendData.list;
         dispatchList = fileList.filter(file => state.acceptedFileType.includes(file.type));
         dispatchList.map(file => formData.append("files", file.data, file.data.name));

         $package = {};
         $package["files"] = fileList;
         $package["acceptedFiles"] = dispatchList;
         commit("examFiles", $package);
         if (state.rejectedFiles.length === fileList.length) throw new ReturnError(status.badFiles);
         commit("clearRejectedFiles");

         let filteredData = {};
         filteredData["progress"] = sendData.progress;
         filteredData["formData"] = formData;
         filteredData["clientId"] = sendData?.clientId;

         let endpoint = Api.upload;
         if (state.appendToBundle) {
            endpoint = Api.uploadToBundle;
            filteredData["bundleID"] = state.bundleID;
         }
         if (sendData.prospects) {
            endpoint = Api.uploadProspect;
            formData.append("email", sendData.email);
         }
         let sendRequest = await endpoint.call(this, filteredData);
         let status = sendRequest.status;
         switch (status) {
            case 201:
               sentStatus = true;
               break;
            case 400:
               throw new ReturnErrorUpload(sendRequest);
            case 403:
               sentStatus = false;
               throw sendRequest;
            case 500:
               throw {
                  ...sendRequest,
                  defaultError:
                     "Sistema indisponível. Tente novamente mais tarde.. Entre em contato com o suporte."
               };
            default:
               sentStatus = false;
         }
         setTimeout(() => {
            commit("setRequestData", sendRequest.data);
         }, 2500);
         return sentStatus;
      }
   },

   mutations: {
      clearRejectedFiles(state) {
         state.rejectedFiles = [];
      },
      examFiles(state, $package) {
         if (!$package.acceptedFiles.length) {
            state.rejectedFiles = $package.files;
            return;
         }
         state.rejectedFiles = $package.files.filter(file => {
            return !$package.acceptedFiles.includes(file);
         });
      },
      setDestination(state, response) {
         if (!response) return;
         state.appendToBundle = response.toBundle;
         state.bundleID = response.bundleID;
      },
      setRequestData(state, response) {
         state.requestData = response;
      }
   }
};
