export default {
   state: {
      active: [],
      editableTransaction: null
   },
   mutations: {
      setAccordion(state, list) {
         state.active = list;
      },
      resetConfig(state) {
         state.active = [];
      },
      editTransaction(state, transaction) {
         state.editableTransaction = transaction;
      },
      resetTransactionScope(state) {
         state.editableTransaction = null;
      }
   }
};
