import { Mod } from "@/services/api";

export default {
   state: {
      chat: [],
      page: 0,
      total: 0,
      isPreviousPage: 0,
      isNextPage: true
   },
   actions: {
      async sendChat(_commit, payload) {
         return Mod.find("PlannerChat", "chat").create(payload);
      },
      async getChat({ commit }, payload) {
         try {
            const data = await Mod.find("PlannerChat", "chat").get(payload);
            commit("setChat", data.data.data);
            commit("setPage", data.data.data);
            commit("setTotal", data.data.data);
            commit("setIsPreviousPage", data.data.data);
            commit("setIsNextPage", data.data.data);
         } catch (e) {
            console.error(e); // eslint-disable-line
         }
      }
   },
   mutations: {
      setChat(state, payload) {
         state.chat = payload.results.slice().reverse();
      },

      setPage(state, payload) {
         state.page = payload.results;
      },

      setTotal(state, payload) {
         state.total = payload.count;
      },

      setIsPreviousPage(state, payload) {
         state.isPreviousPage = payload.previous;
      },

      setIsNextPage(state, payload) {
         state.isNextPage = payload.next;
      }
   },
   getters: {
      getChatResult(state) {
         return state.chat;
      },
      getPage(state) {
         return state.page;
      },
      getTotal(state) {
         return state.total;
      },
      getIsPreviousPage(state) {
         return !!state.isPreviousPage;
      },
      getIsNextPage(state) {
         return !!state.isNextPage;
      }
   }
};
