export default class Auth {
   constructor(request) {
      this.moduleName = "auth";
      this.prefix = "rest-auth/";

      this.request = request;

      this.extends = {
         login: "login/",
         editPassword: "password/change/",
         registerUser: "registration/",
         resendActivate: "resend-activate/"
      };

      this.routes = {
         session: {
            login: this.requestToken
         },
         user: {
            editPassword: this.editUserPassword,
            register: this.registerUser,
            resendActivate: this.resendActivate
         }
      };
      this.bind(this.routes, this);
   }

   //  User access
   async requestToken(payload) {
      const expectedStatus = {
         200: "Token gerado",
         400: "Email ou senha incorretos.",
         403: "Organizacao incorreta",
         default: "Servidor indisponível no momento, tente novamente mais tarde."
      };

      let endpoint = this.extends.login;

      return await this.request.POST(
         {
            url: this.prefix + endpoint,
            body: payload
         },
         expectedStatus
      );
   }

   //  User change password
   async editUserPassword(payload) {
      const expectedStatus = {
         200: "Senha alterada",
         default: "Credenciais não aceitas"
      };

      let endpoint = this.extends.editPassword;

      return await this.request.POST(
         {
            url: this.prefix + endpoint,
            body: payload
         },
         expectedStatus
      );
   }

   //  Regiser user
   async registerUser(payload) {
      const expectedStatus = {
         201: "Conta criada",
         default: "Conta não criada"
      };

      let endpoint = this.extends.registerUser;

      return await this.request.POST(
         {
            url: this.prefix + endpoint,
            body: payload
         },
         expectedStatus
      );
   }

   async resendActivate() {
      const expectedStatus = {
         200: "Email reenviado",
         default: ""
      };

      let endpoint = this.extends.resendActivate;

      return await this.request.GET(
         {
            url: endpoint
         },
         expectedStatus
      );
   }
}
