import { ReturnError } from "@/utils/error";
import { maxFileName } from "@/utils/helpers";
import Api, { Mod } from "@/services/api";
import status from "@/utils/stats";

export default {
   state: {
      clientBundleID: 0,
      clientFileList: [],
      clientID: 0,
      currentFileID: "",
      fileTransaction: [],
      fileOriginList: {},
      fileOriginListForSelect: []
   },

   getters: {
      currentFileName: state => {
         if (state.clientFileList.length) {
            const currentFile = state.clientFileList.filter(
               file => state.currentFileID === file.id
            )[0];
            if (currentFile) {
               return currentFile.name;
            }
            return "Arquivo desconhecido";
         }
         return "Arquivo desconhecido";
      },
      fileList: state => state.clientFileList,
      fileOrigins: state => state.fileOriginList,
      transactionList: state => state.fileTransaction,
      originForSelect: state => state.fileOriginListForSelect,
      isManualCash: state => {
         if (state.clientFileList.length) {
            let currentFile = state.clientFileList.filter(
               file => state.currentFileID === file.id
            )[0];
            if (currentFile) {
               return currentFile.manual_cash;
            }
            return false;
         }
         return false;
      }
   },

   actions: {
      async addTransaction({ state, commit }, transactionData) {
         transactionData["client"] = state.clientID;
         let newTransaction = await Api.addTransaction(transactionData);
         commit("addToTransaction", newTransaction.data);
      },
      async bulkAddTransaction({ state }, transactionArray) {
         const modifiedTransactions = transactionArray.map(item => ({
            ...item,
            created_from: item.created_from || state.currentFileID,
            client: state.clientID
         }));

         const data = {
            transactions: modifiedTransactions
         };
         await Api.bulkSendTransactions(data);
      },
      async bulkDeleteTransactions(store, transactions) {
         let IDs = [];
         transactions.map(transaction => IDs.push({ id: transaction.id }));
         await Api.bulkRemoveTransactions(IDs);
      },
      async bulkEditSelectedTransactions({ state }, transactions) {
         let data = {};
         data["transactions"] = transactions;
         data["id"] = state.clientID;
         await Api.bulkEditTransaction(data);
      },
      async deleteFile({ dispatch, state }, fileID) {
         await Api.deleteFile(fileID);
         dispatch("getClientFileList", state.clientID);
      },

      async deleteTransaction({ state }, transactionID) {
         return await Mod.find("cashFlow", "reviewTransactions").delete({
            clientID: state.clientID,
            id: transactionID
         });
      },
      async editTransaction({ state }, transaction) {
         return await Mod.find("cashFlow", "reviewTransactions").edit({
            clientID: state.clientID || transaction.clientID,
            transaction: transaction.content || transaction
         });
      },
      async getClientFileList({ commit }, clientData) {
         commit("setClientID", clientData.clientID);
         commit("cleanFileList");
         let fileList = await Api.getClientFileList(clientData);
         fileList = fileList.data.files;
         if (fileList?.length) {
            for (let file of fileList) {
               file.read_status = file.read_status.replace("Leitura ", "");
            }
         }
         if (!fileList || !fileList.length) {
            commit("setFileList", false);
            throw new ReturnError(status.clientNotFound);
         }
         commit("setFileList", fileList);
         return fileList;
      },
      async readFile({ commit }, fileID) {
         const requestTransactions = await Api.getSelectedFile(fileID);
         commit("updateOriginList", requestTransactions.data.originsForSelect);
         commit("updateFileTransactions", requestTransactions.data.reviewList);
      }
   },

   mutations: {
      addToTransaction(state, singleTransaction) {
         state.fileTransaction.push(singleTransaction);
      },
      cleanFileList(state) {
         state.clientFileList = [];
      },
      cleanTransactionCache(state) {
         state.fileTransaction = [];
      },
      defineCurrentClientBundle(state, bundleID) {
         state.clientBundleID = bundleID;
      },
      setBundleID(state, ID) {
         state.clientBundleID = ID;
      },
      setClientID(state, ID) {
         state.clientID = ID;
      },
      setCurrentFileID(state, fileID) {
         state.currentFileID = fileID;
      },
      setFileID(state, ID) {
         state.currentFileID = ID;
      },
      setFileList(state, data) {
         if (!data) return;
         state.clientFileList = data;
         /**
          * Adds name to file list
          * Based on file link
          */
         state.clientFileList.map(fileItem => {
            let URI, occurances, fileName, slicedName;

            URI = fileItem.file;
            occurances = (URI.match(/\//g) || []).length;
            while (occurances !== 1) {
               URI = URI.replace("/", "_");
               occurances = (URI.match(/\//g) || []).length;
            }
            fileName = URI.substr(URI.indexOf("/") + 1);
            fileItem["name"] = fileName;

            slicedName = maxFileName(URI.substr(URI.indexOf("/") + 1), 35);
            if (fileName !== slicedName) {
               fileItem["slicedName"] = slicedName;
            }
         });
      },
      updateFileTransactions(state, transactions) {
         state.fileTransaction = transactions;
      },
      updateOriginList(state, origins) {
         state.fileOriginListForSelect = origins;
         origins.map(origin => (state.fileOriginList[origin.value] = origin.text));
      }
   }
};
