import axios from "axios";
import Interceptors from "@/utils/interceptors";

const interceptor = new Interceptors();

axios.interceptors.request.use(function(config) {
   if (interceptor.needClientId(config)) {
      config.params = { ...config.params, ...interceptor.getParam() };
   }
   config.url = interceptor.isClient(config);
   return config;
});
