// Core
import { Requests } from "@/services/requests";
import plugins from "@/services/utils/plugins.js";

class Modules extends Requests {
   constructor(moduleList) {
      super();
      if (!(moduleList instanceof Array)) throw "Module list must be an array";

      this.modules = moduleList;
      this.build();
   }
   build() {
      this.modules = this.modules.map(module => {
         module.prototype.bind = this.bind;
         return new module(Requests);
      });
   }
   read(config) {
      if (!config || !plugins) {
         return true;
      } else {
         return plugins.validate(config);
      }
   }
   find(moduleName, route, props) {
      let module = this.modules.filter(m => m.moduleName === moduleName)[0];
      if (!(module.routes[route] instanceof Object)) {
         throw "Route not found";
      }
      if (this.read(props)) {
         return module.routes[route];
      }
   }
   bind(routes, self) {
      for (let route in routes) {
         let endpoints = routes[route];

         for (let endpoint in endpoints) {
            endpoints[endpoint] = endpoints[endpoint].bind(self);
         }
      }
      return routes;
   }
}

export default Modules;
