import { Requests } from "@/services/requests";

const ENDPOINTS = {
   SESSION: "open-banking/v2/users/session/",
   PARTICIPANTS: "open-banking/v2/participants/",
   CONSENTS: "open-banking/v2/consents/",
   APPROVE: "open-banking/v2/approve/",
   CONNECT: "open-banking/v2/links-connect"
};

const MSG = {
   CREATE_SUCCESS: "Criado com sucesso",
   GET_ALL_SUCCESS: "Retornados com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class OpenBankingV2 {
   constructor(request) {
      this.request = request;
      this.moduleName = "OpenBankingV2";
      this.routes = {
         session: {
            create: this.createSession.bind(this)
         },
         consents: {
            create: this.createConsents.bind(this)
         },
         participants: {
            get: this.listParticipants.bind(this)
         },
         approve: {
            create: this.createApprove.bind(this)
         },
         connect: {
            get: this.listConnections.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async createSession(payload) {
      return this.makeRequest({
         url: ENDPOINTS.SESSION,
         method: "post",
         body: { ...payload },
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async createConsents(payload) {
      return this.makeRequest({
         url: ENDPOINTS.CONSENTS,
         method: "post",
         body: { ...payload },
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async createApprove(payload) {
      return this.makeRequest({
         url: ENDPOINTS.APPROVE,
         method: "post",
         body: { ...payload },
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async listConnections() {
      return this.makeRequest({
         url: ENDPOINTS.CONNECT,
         method: "GET",
         expectedStatus: {
            200: MSG.GET_ALL_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
   async listParticipants() {
      return this.makeRequest({
         url: ENDPOINTS.PARTICIPANTS,
         method: "GET",
         expectedStatus: {
            200: MSG.GET_ALL_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}
