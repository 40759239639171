<template>
   <div class="tryoutWarning mt-5 mb-2" v-if="tryoutUser">
      <v-alert prominent text type="warning" class="mb-0">
         <template v-slot:prepend>
            <v-icon color="warning" left>mdi-alert</v-icon>
         </template>
         Você está usando uma organização de teste.
      </v-alert>
   </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
   name: "tryoutWarning",
   computed: {
      ...mapGetters(["tryoutUser"])
   }
};
</script>
