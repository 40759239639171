import { Utils } from "@/utils/Utils";
/**
 * class Returns a pre-defined list of filters
 *
 * @class Filter
 */
const error = {
   INVALID_FILTER_CONFIG: "Invalid filter config, expected filter from { Store.Filters.config }.",
   BAD_EXCLUDING_LIST: "To exclude items from filter config, you need to use an Array of Strings."
};

const Filter = {
   /**
    * Returns a pre-defined list of filters
    *
    * @param {object} filterList The entire config list to be filtered (Store.Filter.config)
    * @param {Array<string>} exclude Keys that should be removed
    * @returns {object} The config list
    * @throws {Error} Throws when doesn't find {start} or {end} in {filterList} param from filter config
    * @throws {Error} Throws when {exclude} param isn't an array, null or undefined
    */
   config(filterList = {}, exclude = []) {
      if (!this._has(filterList, "start") || !this._has(filterList, "end")) {
         throw Error(error.INVALID_FILTER_CONFIG);
      }

      if (!Utils.isArray(exclude)) {
         throw Error(error.BAD_EXCLUDING_LIST);
      } else if (Utils.isArray(exclude) && exclude.length) {
         if (!Utils.isString(exclude[0])) {
            throw Error(error.BAD_EXCLUDING_LIST);
         }
      }

      const {
         start,
         end,
         origins,
         clientId,
         limit,
         offset,
         showPlanned,
         description,
         category,
         isExpense,
         isFinancialProduct,
         showDescriptions,
         identificationNumber
      } = filterList;

      return this._iterateItems(
         {
            start,
            end,
            origins,
            clientId,
            limit,
            offset,
            showPlanned,
            description,
            category,
            isExpense,
            isFinancialProduct,
            showDescriptions,
            identificationNumber
         },
         exclude
      );
   },

   _iterateItems(list, excluded = []) {
      const filterConfig = {};

      for (const key in list) {
         if (!excluded.includes(key)) {
            filterConfig[key] = list[key];
         }
      }
      return filterConfig;
   },

   _has(object, property) {
      return Object.hasOwn(object, property);
   }
};

export default Filter;
export { error };
