<template>
   <div>
      <h3 class="inherit mb-2">Cores do Tema</h3>
      <ul class="pallete">
         <li>
            <v-menu
               v-model="menu.primary"
               :close-on-content-click="false"
               :nudge-left="375"
               offset-x
            >
               <template v-slot:activator="{ on, attrs }">
                  <span class="item" v-on="on" v-bind="attrs">
                     <div class="picker" :style="pallete.primary"></div>
                     <label><small>Primário</small></label>
                  </span>
               </template>
               <v-color-picker
                  v-model="color.primary"
                  @input="() => changeViewColors('primary')"
                  mode="hexa"
                  ref="PrimaryPicker"
                  dot-size="8"
                  swatches-max-height="200"
               />
            </v-menu>
         </li>
      </ul>
   </div>
</template>

<script>
// Core
import { mapActions } from "vuex";
// Helper
import ColorTransform from "@/utils/colorTransform.js";

export default {
   name: "ColorPick",
   data() {
      return {
         color: {
            primary: null,
            secondary: "#000000"
         },
         menu: {
            primary: false
         }
      };
   },
   computed: {
      pallete() {
         const PRIMARY = this.colorConfig?.primary;
         const SECONDARY = this.colorConfig?.secondary;

         if (PRIMARY && SECONDARY) {
            return {
               primary: `background-color: #${this.colorConfig.primary};`,
               secondary: `background-color: #${this.colorConfig.secondary};`
            };
         }
         return null;
      }
   },
   methods: {
      ...mapActions(["editTheme"]),

      changeViewColors(key) {
         let colorConfig = {
            primary: this.colorConfig.primary,
            secondary: this.colorConfig.secondary
         };

         colorConfig[key] = this.color[key].hex.slice(1);

         this.editTheme(colorConfig);
         this.updateColorScheme(colorConfig);
      },
      syncColors() {
         const CONFIG = this.colorConfig;

         this.color.primary = ColorTransform.factory(CONFIG.primary);
         this.color.secondary = ColorTransform.factory(CONFIG.secondary);
      }
   },
   props: {
      "update-color-scheme": {
         type: Function,
         default: () => {}
      },
      "color-config": {
         type: [Boolean, Object],
         default: () => Object
      }
   },
   mounted() {
      this.syncColors();
   }
};
</script>
