import { Expected } from "@/services/requests.js";

export default class Reminder {
   constructor(request) {
      this.moduleName = "reminder";
      this.prefix = "bill-reminder/";
      this.request = request;

      this.extended = {
         recommend: "bill-reminder-recommender/"
      };

      this.routes = {
         reminder: {
            create: event => {
               return this.request.POST(
                  {
                     url: this.prefix,
                     body: event
                  },
                  Expected.add({
                     201: "Evento criado"
                  })
               );
            },
            get: () => {
               return this.request.GET(
                  {
                     url: this.prefix
                  },
                  Expected.add({
                     200: "Resposta recebida"
                  })
               );
            },
            update: ({ id, title, content, reminder_date, periodicity }) => {
               return this.request.PUT(
                  {
                     url: this.prefix + id + "/",
                     body: {
                        title,
                        content,
                        reminder_date,
                        periodicity
                     }
                  },
                  Expected.add({
                     200: "Evento atualizado"
                  })
               );
            },
            delete: id => {
               return this.request.DELETE(
                  {
                     url: this.prefix + id + "/"
                  },
                  Expected.add({
                     204: "Evento deletado"
                  })
               );
            },
            recommend: () => {
               return this.request.POST(
                  {
                     url: this.extended.recommend,
                     body: {}
                  },
                  Expected.add({
                     201: "Lembretes Criados"
                  })
               );
            }
         }
      };
   }
}
