import Vue from "vue";
import { Mod } from "@/services/api";

export default {
   state: {
      notifications: []
   },
   getters: {
      notifications: state => state.notifications
   },
   actions: {
      async getNotifications({ commit }) {
         const req = await Mod.find("notification", "notification").notifications();
         commit("getNotifications", req.data.data);
      },
      async visualized({ commit }, id_notification) {
         const req = await Mod.find("notification", "notification").visualized(id_notification);
         commit("updateNotification", req.data.data);
      }
   },
   mutations: {
      getNotifications(state, notifications) {
         state.notifications = notifications;
      },
      updateNotification(state, notification) {
         for (let key in state.notifications) {
            let item = state.notifications[key];

            if (item.id == notification.id) {
               Vue.set(state.notifications, state.notifications.indexOf(item), { ...notification });
            }
         }
      }
   }
};
