const error = {
   REQUIRED_PARAM: "Expected param, got null or undefined",
   REQUIRED_LIST: "Expected list param, got null or undefined"
};

const ASC = "ascending",
   DESC = "descending";

const Order = {
   default: {
      sort: ASC
   },

   sortConfig: {
      sort: null
   },

   /**
    * Expects Array containing data
    *
    * @param {String} type The order type, defaults to array
    * @param {String} keyName When using object, identify the order key {Required if Object}
    * @param {Array} list The list that should be ordered {Required}
    * @throws {Error} When doesn't have required options
    */
   byValue({ type = "default", keyName = null, list = [], sort = ASC }) {
      if (!list || !list.length) {
         throw Error(error.REQUIRED_LIST);
      }

      this.sortConfig.sort = sort;

      switch (type) {
         case "object": {
            if (!keyName) {
               throw Error(error.REQUIRED_PARAM);
            }

            return this._orderObject(list, keyName);
         }
         case "array":
         case "default":
         default:
            return this._orderArray(list);
      }
   },

   _orderObject(list, keyName) {
      let config = this.sortConfig.sort;

      if (config === ASC) {
         if (this._isString(list[0][keyName])) {
            return list.sort((a, b) => a[keyName].toLowerCase().localeCompare(b[keyName]));
         }

         return list.sort((a, b) => a[keyName] - b[keyName]);
      } else if (config === DESC) {
         if (this._isString(list[0][keyName])) {
            return list
               .sort((a, b) => a[keyName].toLowerCase().localeCompare(b[keyName]))
               .reverse();
         }

         return list.sort((a, b) => b[keyName] - a[keyName]);
      }
   },

   _orderArray(list) {
      let config = this.sortConfig.sort;

      if (config === ASC) {
         if (this._isString(list[0])) {
            return list.sort();
         }

         return list.sort((a, b) => a - b);
      } else if (config === DESC) {
         if (this._isString(list[0])) {
            return list.sort().reverse();
         }

         return list.sort((a, b) => b - a);
      }
   },

   _isString(value) {
      return typeof value === "string";
   }
};

export default Order;
export { error };
