import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class FutureFlow {
   constructor(request) {
      this.moduleName = "futureFlow";
      this.prefix = "future-flow/";

      this.request = request;

      this.routes = {
         metrics: {
            planned: filter => {
               let query = Query.build(filter);

               if (filter && filter.hasOwnProperty("ideal") && filter.ideal === true) {
                  query += "ideal=True";
               }

               return this.request.GET(
                  {
                     url: this.prefix + query
                  },
                  Expected.add({
                     200: "Dados recebidos"
                  })
               );
            },

            create: payload => {
               let requestOptions = {
                  url: this.prefix + "create/"
               };

               if (payload) {
                  requestOptions.body = payload;
               }

               return this.request.POST(
                  requestOptions,
                  Expected.add({
                     201: "Dados recebidos"
                  })
               );
            },
            edit: ({ clientId, option }) => {
               const query = Query.build(clientId);
               let requestOptions = {
                  url: this.prefix + "edit/" + query
               };
               if (option) {
                  requestOptions.body = option;
               }

               return this.request.PUT(
                  requestOptions,
                  Expected.add({
                     200: "Dados recebidos"
                  })
               );
            },

            prediction: filter => {
               const query = Query.build(filter);
               return this.request.GET(
                  {
                     url: this.prefix + "chart" + query
                  },
                  Expected.add({
                     200: "Dados recebidos"
                  })
               );
            },

            getPlanned: config => {
               const query = Query.build(config.clientId);
               return this.request.GET(
                  {
                     url:
                        this.prefix +
                        query +
                        `startDate=${config.dates.startDate}&endDate=${config.dates.endDate}`
                  },
                  Expected.add({
                     200: "Dados recebidos"
                  })
               );
            }
         }
      };
   }
}
