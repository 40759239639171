import currency from "@/utils/currency";

export default new (class Rules {
   constructor() {
      this.email = [
         v => !!v || "Preencha seu e-mail",
         v => /.+@.+/.test(v) || "Esse campo precisa ser um e-mail válido"
      ];
      this.emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.name = [
         v => {
            v = v ? String(v) : false;

            if (v && v.length >= 3) {
               if (v.length < 150) {
                  return true;
               } else {
                  return "Limite de 150 excedido";
               }
            }
            return "Esse campo é obrigatório";
         }
      ];
      this.lastName = [
         v => {
            v = v ? String(v) : false;

            if (v && v.length >= 3) {
               if (v.length < 150) {
                  return true;
               } else {
                  return "Limite de 150 excedido";
               }
            }
            return "Esse campo é obrigatório";
         }
      ];
      this.password = [
         v => !!v || "Esse campo é obrigatório.",
         v => (v && v === this.attach) || "Senhas precisam ser iguais"
      ];
      this.passwordLength = [v => v.length >= 8 || "Mínimo 8 caracteres"];
      this.attach = "";
      this.phone = [
         v => (v && (v.length === 14 || v.length === 15)) || "Número de telefone inválido",
         v =>
            (v && v.replace(/\D+/g, "").length >= 10 && v.replace(/\D+/g, "").length <= 11) ||
            "Use apenas números"
      ];
      this.housePhone = [v => (v && v.replace(/\D+/g, "").length <= 11) || "Use apenas números"];
      this.cpf = [v => (!!v && v.replace(/\D+/g, "").length === 11) || "Este campo é obrigatório"];
      this.cnpj = [v => (!!v && v.replace(/\D+/g, "").length === 14) || "Este campo é obrigatório"];
      this.dv = [v => (typeof v == "string" && String(v).split("").length >= 1) || ""];
      this.object = [v => typeof v == "object" || "Digite o nome do banco"];
      this.cep = [v => (!!v && v.split("").length >= 8) || "Este campo é obrigatório"];

      this.street = [v => (!!v && v.length >= 1) || "Este campo é obrigatório"];
      this.street_number = [v => (!!v && v.length >= 1) || "Este campo é obrigatório"];
      this.neighborhood = [v => (!!v && v.length >= 1) || "Este campo é obrigatório"];
      this.state = [
         value => !!value || "Este campo é obrigatório", // Verifica se o campo não está vazio
         value => value.length === 2 || "O estado deve ter exatamente 2 caracteres"
      ];
      this.city = [v => (!!v && v.length >= 1) || "Este campo é obrigatório"];

      this.validDate = [
         v => {
            if (v) {
               const [day, month, year] = v.split("/");

               if (day && month && year) {
                  let date = `${year}-${month}-${day}`;
                  if (year.toString().length < 4) return "Data inválida";
                  date = new Date(date);

                  return (date instanceof Date && !isNaN(date)) || "Data inválida";
               } else {
                  return "Data inválida";
               }
            } else {
               return "Data inválida";
            }
         }
      ];
      this.validateCpf = [
         cpf => !!cpf || "Número de CPF é obrigatório",
         v => (!!v && v.replace(/\D+/g, "").length === 11) || "Este campo é obrigatório",
         value => {
            const cpf = value.replace(/\D+/g, "");
            let resto,
               soma = 0;

            if (
               cpf === "00000000000" ||
               cpf === "11111111111" ||
               cpf === "22222222222" ||
               cpf === "33333333333" ||
               cpf === "44444444444" ||
               cpf === "55555555555" ||
               cpf === "66666666666" ||
               cpf === "77777777777" ||
               cpf === "88888888888" ||
               cpf === "99999999999"
            ) {
               return "Cpf inválido";
            }

            for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
            resto = (soma * 10) % 11;

            if (resto === 10 || resto === 11) resto = 0;
            if (resto !== parseInt(cpf.substring(9, 10))) return "Cpf inválido";

            soma = 0;
            for (let i = 1; i <= 10; i++)
               soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);

            resto = (soma * 10) % 11;

            if (resto === 10 || resto === 11) resto = 0;
            if (resto !== parseInt(cpf.substring(10, 11))) return "Cpf inválido";

            return true;
         }
      ];
      this.required = [v => !!v || "Este campo é obrigatório"];
      this.validateCnpj = [
         v => {
            const cnpj = v.replace(/[^\d]+/g, "");

            if (cnpj === "") return "CNPJ é obrigatório.";

            if (cnpj.length !== 14) return "CNPJ inválido.";

            // Valida DVs
            let tamanho = cnpj.length - 2;
            let numeros = cnpj.substring(0, tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
               soma += numeros.charAt(tamanho - i) * pos--;
               if (pos < 2) pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
            if (resultado !== digitos.charAt(0)) return "CNPJ inválido.";

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
               soma += numeros.charAt(tamanho - i) * pos--;
               if (pos < 2) pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
            if (resultado !== digitos.charAt(1)) return "CNPJ inválido.";

            return true;
         }
      ];
      this.validDateOrEmpty = [
         v => {
            if (!v) {
               return true;
            }

            const [day, month, year] = v.split("/");

            if (day && month && year) {
               let date = `${year}-${month}-${day}`;

               if (year.toString().length < 4) {
                  return "Data inválida";
               }
               date = new Date(date);

               return (date instanceof Date && !isNaN(date)) || "Data inválida";
            } else {
               return "Data inválida";
            }
         }
      ];
      this.isRequired = [v => !!v || "Campo obrigatório"];
      this.house = [v => (!!v && v.length >= 1) || "Número da casa inválido"];
      this.service = {
         name: [
            v =>
               (v && v.split("").length >= 2 && v && v.split("").length <= 25) ||
               "Esse campo é obrigatório"
         ],
         period: [
            v =>
               (!!v && !!parseInt(v) && v.length > 0 && v.length >= 1 && v.length <= 2) ||
               "Número inválido"
         ],
         description: [
            v =>
               (typeof v == "string" &&
                  String(v).split("").length >= 10 &&
                  String(v).split("").length <= 600) ||
               "Esse campo é obrigatório"
         ],
         fee: [v => (!!v && currency.toNumber(v) >= 0) || "Valor inválido"]
      };
      this.select = [v => !!v || "Error"];
      this.organization = {
         name: [
            v => v.split("").length >= 5 || "A Razão social precisa de pelo menos 5 caracteres."
         ]
      };
      this.validateCNPJ = cnpj => {
         cnpj = cnpj.replace(/[^\d]+/g, "");

         if (cnpj === "") return false;

         if (cnpj.length !== 14) return false;

         let size = cnpj.length - 2;
         let numbers = cnpj.substring(0, size);
         const digitVerifiers = cnpj.substring(size);
         let sum = 0;
         let pos = size - 7;

         for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
         }

         let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
         if (result !== Number(digitVerifiers.charAt(0))) return false;

         size += 1;
         numbers = cnpj.substring(0, size);
         sum = 0;
         pos = size - 7;

         for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
         }

         result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
         return result === Number(digitVerifiers.charAt(1));
      };
   }

   attachPassword(password) {
      this.attach = password;
   }

   validateFormInputs(formInputs) {
      const { confirmPassword } = formInputs;
      let valid = {};

      if (confirmPassword !== this.attach || confirmPassword.length < 3) {
         valid["password"] = false;
      }

      return valid;
   }
})();
