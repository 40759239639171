import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class ManualAccount {
   constructor(request) {
      this.moduleName = "manual-account";
      this.prefix = "manual-account/";

      this.extends = {
         origin: "ignore-origin/",
         perMonth: "origins-by-month/"
      };

      this.request = request;

      this.routes = {
         manualAccount: {
            add: ({ body, clientId }) => {
               const query = Query.build(clientId);

               return this.request.POST(
                  {
                     url: this.prefix + query,
                     body: { ...body }
                  },
                  Expected.add({
                     200: "Resposta recebida",
                     201: "Origen criado"
                  })
               );
            }
         },
         origin: {
            ignored: async ({ originId, ignored, clientId }) => {
               let query;
               if (clientId) {
                  query = Query.build(clientId);
               }
               const endpoint = clientId ? `${originId}/` + query : `${originId}/`;
               return this.request.PUT(
                  {
                     url: this.extends.origin + endpoint,
                     body: { ignored }
                  },
                  Expected.add({
                     200: "Resposta recebida"
                  })
               );
            },
            byMonth: async (dates, clientId) => {
               const query = Query.build({ ...dates, clientId });

               return await this.request.GET(
                  {
                     url: this.extends.perMonth + query
                  },
                  Expected.add({
                     200: "Origens filtradas recebidas"
                  })
               );
            },
            deleted: async ({ originId, clientId }) => {
               const query = Query.build(clientId);

               return this.request.DELETE(
                  {
                     url: this.prefix + `${originId}/` + query
                  },
                  Expected.add({
                     204: "Origem Deletada"
                  })
               );
            }
         }
      };
   }
}
