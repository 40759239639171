<template>
   <div id="configOrganizationSidebar">
      <div class="section header">
         <div class="alignment d-flex align-center justify-space-between">
            <h3>Customização do Tema</h3>
            <v-snackbar top v-model="activeSnackbar" :color="sucessSnackbar ? 'success' : 'error'">
               {{ messageSnackbar }}
            </v-snackbar>
         </div>
      </div>
      <div class="section customizable">
         <div class="colorPick">
            <LogoPicker @updated="newLogo = $event" />
            <v-divider class="my-3" />
            <ColorPick :update-color-scheme="updateColorScheme" :color-config="config.color" />
         </div>
         <v-divider class="my-3" />
         <h3 class="inherit mb-2">Favicon</h3>
         <h6 class="mb-2">Tamanho 16 X 16 ou 32 X 32 - Formato PNG | ICO</h6>
         <div class="fileUpload mb-2">
            <input
               id="headerFile"
               accept="image/*"
               type="file"
               @change="onFileChange('favicon', $event)"
            />
            <div v-if="faviconThumbnail" class="mt-1">
               <img :src="faviconThumbnail" alt="Thumbnail do favicon" height="50" />
            </div>
         </div>
         <v-divider class="my-3" />
         <v-text-field
            label="Link de Redirecionamento"
            v-model="link_redirect"
            placeholder="Insira o link de redirecionamento aqui"
         ></v-text-field>

         <v-divider class="my-3" />
         <h3 class="inherit mb-2">Customização do Email</h3>
         <h6 class="mb-2">Tamanho 1200 X 200 - Formato PNG | JPG</h6>
         <div class="fileUpload mb-2">
            <label for="headerFile">Upload do Header:</label>
            <input
               id="headerFile"
               type="file"
               accept="image/*"
               @change="onFileChange('header', $event)"
            />
            <div v-if="headerThumbnail" class="mt-1">
               <img :src="headerThumbnail" alt="Thumbnail do Header" height="50" />
            </div>
         </div>
         <div class="fileUpload">
            <label for="footerFile">Upload do Footer:</label>
            <input
               id="footerFile"
               type="file"
               accept="image/*"
               @change="onFileChange('footer', $event)"
            />
            <div v-if="footerThumbnail" class="mt-1">
               <img :src="footerThumbnail" alt="Thumbnail do Footer" height="50" />
            </div>
         </div>
         <v-divider class="my-3" />
      </div>

      <div class="section footer">
         <div class="d-flex justify-end align-center">
            <v-btn color="grey darken-4" dark :loading="colorLoading" @click="updateAll"
               >Salvar Alterações</v-btn
            >
         </div>
      </div>
   </div>
</template>

<script>
import "@/shared/scss/_configOrganization.scss";
import ColorPick from "@/shared/components/atomicDesign/atoms/ColorPick.vue";
import LogoPicker from "@/shared/components/atomicDesign/atoms/LogoPicker.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
   name: "ConfigOrganization",
   data() {
      return {
         config: {
            color: null
         },
         colorLoading: false,
         newLogo: null,
         headerFile: null,
         footerFile: null,
         isEditing: false,
         headerThumbnail: null,
         footerThumbnail: null,
         activeSnackbar: false,
         messageSnackbar: "",
         sucessSnackbar: false,
         idCustoEmail: null,
         favicon: null,
         faviconThumbnail: null,
         link_redirect: null
      };
   },
   components: {
      ColorPick,
      LogoPicker
   },
   computed: {
      ...mapGetters(["colorTheme"]),
      ...mapState({
         organization: store => store.user.persona.organization
      })
   },
   methods: {
      ...mapActions(["changeThemeColor", "changeLogo"]),

      updateColorScheme(config) {
         this.config.color = config;
      },
      updateColor() {
         this.colorLoading = true;
         this.changeThemeColor(this.colorTheme)
            .finally(() => (this.colorLoading = false))
            .catch(e => {
               throw Error(e);
            });
      },
      async updateAll() {
         const promises = [];

         promises.push(this.updateColor());

         if (
            this.newLogo ||
            (this.favicon && !/^https:\/\/s3\.amazonaws\.com\//.test(this.favicon)) ||
            this.link_redirect
         ) {
            const request = this.mountedObject();
            promises.push(this.changeLogo(request));
         }

         if (
            (this.headerFile && !/^https:\/\/s3\.amazonaws\.com\//.test(this.headerThumbnail)) ||
            (this.footerFile && !/^https:\/\/s3\.amazonaws\.com\//.test(this.headerThumbnail))
         ) {
            promises.push(this.uploadFiles());
         }
         try {
            await Promise.all(promises);
            this.sucessSnackbar = true;
            this.activeSnackbar = true;
            this.messageSnackbar = "Configurações alteradas com sucesso";
         } catch (e) {
            console.error(e); // eslint-disable-line no-console
            if (e.response && e.response.data) {
               const [firstKey, firstValue] = Object.entries(e.response.data)[0] || [];
               const errorMessage = Array.isArray(firstValue) ? firstValue[0] : firstValue;

               this.messageSnackbar = errorMessage
                  ? `${firstKey}: ${errorMessage}`
                  : "Erro desconhecido";
            } else {
               this.messageSnackbar = "Erro desconhecido";
            }
            this.sucessSnackbar = false;
            this.activeSnackbar = true;
         }
      },
      mountedObject() {
         try {
            const formData = new FormData();
            if (this.newLogo) {
               formData.append("file", this.newLogo);
            }
            if (this.link_redirect) {
               this.validateLinkRedirect();
               formData.append("link_redirect", this.link_redirect);
            }
            if (this.favicon && !/^https:\/\/s3\.amazonaws\.com\//.test(this.favicon)) {
               const blob = this.dataURLToBlob(this.faviconThumbnail);
               formData.append("favicon", blob);
            }
            return formData;
         } catch (e) {
            console.error(e); // eslint-disable-line no-console
         }
      },
      validateLinkRedirect() {
         if (!this.link_redirect.startsWith("https://")) {
            this.link_redirect = "https://" + this.link_redirect;
         }
      },
      onFileChange(type, event) {
         const file = event.target.files[0];
         if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
               if (type === "header") {
                  this.headerFile = file;
                  this.headerThumbnail = reader.result;
               } else if (type === "footer") {
                  this.footerFile = file;
                  this.footerThumbnail = reader.result;
               } else if (type === "favicon") {
                  this.favicon = file;
                  this.faviconThumbnail = reader.result;
               }
            };
         }
      },

      dataURLToBlob(dataURL) {
         const splitData = dataURL.split(",");
         if (splitData.length !== 2) {
            throw new Error("Invalid Data URL");
         }
         const byteString = atob(splitData[1]);
         const mimeString = dataURL
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
         const ab = new ArrayBuffer(byteString.length);
         const ia = new Uint8Array(ab);

         for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
         }

         return new Blob([ab], { type: mimeString });
      },

      async uploadFiles() {
         const formData = new FormData();
         if (
            this.headerFile &&
            this.headerThumbnail &&
            !/^https:\/\/s3\.amazonaws\.com\//.test(this.headerThumbnail)
         ) {
            const blob = this.dataURLToBlob(this.headerThumbnail);
            formData.append("header_image", blob, "header_filename.png");
         }

         if (
            this.footerFile &&
            this.footerThumbnail &&
            !/^https:\/\/s3\.amazonaws\.com\//.test(this.footerThumbnail)
         ) {
            const blob = this.dataURLToBlob(this.footerThumbnail);
            formData.append("footer_image", blob, "footer_filename.png");
         }

         if (this.isEditing && this.idCustoEmail !== null) {
            formData.append("id", this.idCustoEmail);
         }

         try {
            await this.$store.dispatch(
               this.isEditing ? "editCustomEmail" : "createCustomEmail",
               this.isEditing ? { payload: formData, id: this.idCustoEmail } : formData
            );
         } catch (e) {
            if (e.response && e.response.data) {
               const [firstKey, firstValue] = Object.entries(e.response.data)[0] || [];
               const errorMessage = Array.isArray(firstValue) ? firstValue[0] : firstValue;

               this.messageSnackbar = errorMessage
                  ? `${firstKey}: ${errorMessage}`
                  : "Erro desconhecido";
            } else {
               this.messageSnackbar = "Erro desconhecido";
            }
            this.sucessSnackbar = false;
            this.activeSnackbar = true;
         }
      }
   },
   async created() {
      this.updateColorScheme(this.colorTheme);
      if (this.organization && this.organization.favicon) {
         this.favicon = this.organization.favicon;
         this.faviconThumbnail = this.organization.favicon;
      }

      if (this.organization && this.organization.link_redirect) {
         this.link_redirect = this.organization.link_redirect;
      }

      try {
         const emailConfig = await this.$store.dispatch("listCustomEmails");
         if (emailConfig && emailConfig.data && emailConfig.data.length > 0) {
            const { header_image, footer_image, id } = emailConfig.data[0];
            this.isEditing = true;
            this.idCustoEmail = id;
            this.headerFile = header_image;
            this.footerFile = footer_image;

            this.headerThumbnail = header_image;
            this.footerThumbnail = footer_image;
         }
      } catch (e) {
         if (e.response && e.response.data) {
            // Obtém a primeira chave e valor de e.response.data
            const [firstKey, firstValue] = Object.entries(e.response.data)[0] || [];

            // Se firstValue for um array, pega o primeiro elemento dele. Caso contrário, mantém o valor.
            const errorMessage = Array.isArray(firstValue) ? firstValue[0] : firstValue;

            this.messageSnackbar = errorMessage
               ? `${firstKey}: ${errorMessage}`
               : "Erro desconhecido";
         } else {
            this.messageSnackbar = "Erro desconhecido";
         }
         this.sucessSnackbar = false;
         this.activeSnackbar = true;
      }
   }
};
</script>
