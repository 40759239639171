import Order from "./Order";

const NUMBER_TYPE_INVALID = "Invalid param type, expected Number",
   OBJECT_TYPE_INVALID = "Invalid param type, expected Object",
   ARRAY_TYPE_INVALID = "Invalid param type, expected Array",
   STRING_TYPE_INVALID = "Invalid param type, expected String",
   FUNCTION_TYPE_INVALID = "Invalid param type, expected Function",
   PARAM_IS_INVALID = "Invalid param, expected Valid param",
   BOOLEAN_TYPE_INVALID = "Invalid param type, expected Boolean",
   NULL_TYPE_INVALID = "Invalid param type, expected Null";

export const ThrowCase = {
   number: NUMBER_TYPE_INVALID,
   object: OBJECT_TYPE_INVALID,
   array: ARRAY_TYPE_INVALID,
   string: STRING_TYPE_INVALID,
   function: FUNCTION_TYPE_INVALID,
   boolean: BOOLEAN_TYPE_INVALID,
   null: NULL_TYPE_INVALID
};

export const Utils = {
   /**
    * @getter
    * @param {*} value
    * @param {Boolean} strict
    * @returns {Boolean}
    */
   isNumber(value, strict = false) {
      const isNumber = !isNaN(Number(value)) && typeof value === "number";

      if (strict) {
         if (!isNumber) {
            throw TypeError(NUMBER_TYPE_INVALID);
         }
      }
      return isNumber;
   },

   /**
    * @getter
    * @param {*} value
    * @param {Boolean} strict
    * @returns {Boolean}
    */
   isBoolean(value, strict = false) {
      const isBoolean = typeof value == "boolean";

      if (!isBoolean) {
         if (strict) {
            throw TypeError(BOOLEAN_TYPE_INVALID);
         }
      }
      return isBoolean;
   },

   /**
    * @getter
    * @param {*} value
    * @param {Boolean} strict
    * @returns {Boolean}
    */
   isValid(value, strict = false) {
      const isValid = Boolean(value);

      if (!isValid) {
         if (strict) {
            throw TypeError(PARAM_IS_INVALID);
         }
      }
      return isValid;
   },

   /**
    * @getter
    * @param {*} value
    * @param {Boolean} strict
    * @returns {Boolean}
    */
   isObject(value, strict = false) {
      const isObject = typeof value == "object" && String(value) == "[object Object]";

      if (!isObject) {
         if (strict) {
            throw TypeError(OBJECT_TYPE_INVALID);
         }
      }
      return isObject;
   },

   /**
    * @getter
    * @param {*} value
    * @param {Boolean} strict
    * @param {Boolean>required} strict
    */
   isFunction(value, strict = true) {
      const isFunction = typeof value == "function";

      if (!isFunction) {
         if (strict) {
            throw TypeError(FUNCTION_TYPE_INVALID);
         }
      }
      return isFunction;
   },

   /**
    * @getter
    * @param {*} arr
    * @param {Boolean} strict
    * @returns {Boolean}
    */
   isArray(arr, strict = false) {
      const isArray = Array.isArray(arr);

      if (!isArray) {
         if (strict) {
            throw TypeError(ARRAY_TYPE_INVALID);
         }
      }
      return isArray;
   },

   /**
    * @getter
    * @param {*} str
    * @param {Boolean} strict
    * @returns {Boolean}
    */
   isString(str, strict = false) {
      const isString = typeof str == "string";

      if (!isString) {
         if (strict) {
            throw TypeError(STRING_TYPE_INVALID);
         }
      }
      return isString;
   },

   /**
    * @getter
    * @param {*} value
    * @returns {Boolean}
    */
   isNull(value, strict = false) {
      const isNull = value === null;

      if (!isNull) {
         if (strict) {
            throw TypeError(NULL_TYPE_INVALID);
         }
      }
      return isNull;
   },

   /**
    * Returns computed property of description and category
    * as an integer value
    * @getter
    * @param {Object} item
    * @returns {Object}
    */
   transaction(item) {
      return {
         category: itemValue(item, "category"),
         description: itemValue(item, "description"),
         ...item
      };
   },

   /**
    * @getter
    * @param {Array} arr
    * @param {String || Object} item
    * @param {Boolean} strict
    * @returns {Object}
    */
   arrayRemove(arr, item, strict = false) {
      if (this.isArray(arr, strict)) {
         if (this.isValid(item, strict)) {
            if (arr.indexOf(item) !== -1) {
               let removed = arr.splice(arr.indexOf(item), 1);

               return {
                  removed,
                  arr
               };
            }
         }
      }
      return {
         removed: null,
         arr
      };
   },

   /**
    * @method
    * @param {Function} fn
    * @param {Object || Array} iterate
    * @returns {Booolean}
    */
   loopIn(fn, iterate = []) {
      if (this.isFunction(fn)) {
         if (this.isArray(iterate)) {
            for (let el of iterate) {
               fn.call(this, el);
            }
         } else if (this.isObject(iterate)) {
            for (let key in iterate) {
               fn.call(this, key);
            }
         }
         return true;
      }
      return;
   },

   /**
    * @getter
    * @param {Number | String} min
    * @param {Number | String} max
    * @returns {Number}
    */
   percentOf(min, max) {
      min = Math.abs(min);
      max = Math.abs(max);

      const INFINITE = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
      let percent = Math.round((min * 100) / max);

      if (INFINITE.includes(percent)) {
         return 0;
      }
      return Number.isNaN(percent) ? 0 : percent;
   },

   /**
    * @getter
    * @param {Object} returns
    * @param {Number} time
    * @returns {Promise}
    */
   simulateRequest(config = {}, time = 100) {
      let resolves = null;

      switch (config?.returns) {
         case "resolve":
            resolves = true;
            break;
         case "reject":
            resolves = false;
            break;
      }

      if (this.isBoolean(resolves)) {
         return new Promise((resolve, reject) => {
            setTimeout(() => {
               if (resolves) {
                  resolve(config);
               } else {
                  reject(config);
               }
            }, time);
         });
      }
      return null;
   },

   /**
    * Will iterate all arrays to see if they match
    * @param {Array} arr1
    * @param {Array} arr2
    * @returns {Boolean}
    */
   arrayEqual(arr1, arr2 = [], strict = false) {
      if (this.isArray(arr1, strict) && this.isArray(arr2, strict)) {
         const match = arr1.filter(
            (item, index) => JSON.stringify(item) === JSON.stringify(arr2[index])
         );

         return match.length === arr1.length && match.length === arr2.length;
      }
      return false;
   },

   /**
    * Removes special characters and spaces to kebab case
    *
    * @param {String} snippet The string param to be parsed.
    * @returns {String} Parsed to Kebab.
    * @throws {TypeError} When snippet param isn't a String
    */
   toKebab(snippet) {
      if (this.isString(snippet, true)) {
         return snippet
            .toLowerCase()
            .normalize("NFD")
            .replace(/ /g, "-")
            .replace(/[\u0300-\u036f]/g, "");
      }
   },

   /**
    * Checks if object has property
    *
    * @param {object} object The object that should have property
    * @param {string} property The property
    * @returns {boolean} The result
    */
   has(object, property) {
      return Object.hasOwnProperty.call(object, property);
   },

   /**
    * Transforms the first letter into uppercase
    *
    * @param {string} word The word to be capitalized (only 1 word)
    * @returns {string} The word capitalized
    * @example
    * Utils.capitalize("car"): "Car"
    */
   capitalize(word) {
      if (this.isString(word, true)) {
         word = word.toLowerCase().split("");
         word[0] = word[0].toUpperCase();
         word = word.join("");

         return word;
      }
   },

   copyObjectDeep(inObject) {
      let outObject, value, key;

      if (typeof inObject !== "object" || inObject === null) {
         return inObject;
      }

      outObject = Array.isArray(inObject) ? [] : {};

      for (key in inObject) {
         value = inObject[key];

         outObject[key] = this.copyObjectDeep(value);
      }

      return outObject;
   },

   order: Order
};

/**
 * Returns value of transaction being
 * description or category
 * @getter
 * @param {Object} item
 * @param {String} key
 * @returns {Number}
 */
const itemValue = function(item, key = "category") {
   let tagValue = item[key];

   if (Utils.isNull(tagValue)) {
      return null;
   }
   if (!Utils.isNumber(tagValue)) {
      if (Utils.isObject(tagValue)) {
         tagValue = tagValue.value;
      }
   }
   return tagValue;
};
