<template>
   <div>
      <template>
         <v-tooltip v-if="!isMobile" color="primary">
            <template v-slot:activator="{ onTooltip, attrsTooltip }">
               <v-btn
                  class="chatButton"
                  :class="{ chatButtonSmall: zoomLevel >= 1.5 }"
                  fab
                  @click.stop="openChat"
                  color="primary"
                  :small="$vuetify.breakpoint.mdAndDown || zoomLevel >= 1.5"
                  :medium="$vuetify.breakpoint.lgAndUp"
                  v-show="!chatMenu"
                  @click:outside="closeChat"
               >
                  <span class="desktop-label chat-label" v-show="zoomLevel < 1.5">Chat</span>
                  <v-icon color="secondary" :small="zoomLevel >= 1.5">mdi-chat</v-icon>
               </v-btn>
            </template>
            <span v-if="!chatMenu">Chat</span>
         </v-tooltip>
         <v-btn
            v-if="!chatMenu && isMobile"
            class="chatButton chatButtonMobile"
            fab
            text
            @click.stop="openChat"
            small
            v-show="!chatMenu"
            @click:outside="closeChat"
         >
            <v-icon color="primary">mdi-chat-outline</v-icon>
         </v-btn>
      </template>
      <v-menu
         offset-y
         :close-on-content-click="false"
         :right="$vuetify.breakpoint.mdAndUp"
         v-model="chatMenu"
         content-class="chat-menu"
         ref="chatMenuRef"
      >
         <v-card class="card-desktop">
            <v-card-title class="chat-header">
               <img :src="plannerData.photo" width="40" height="40" alt="Imagem do planejador." />
               <h4>
                  {{ plannerData.name }}
               </h4>

               <v-spacer></v-spacer>
               <v-btn icon small @click="closeChat">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-card-title>
            <v-card-text>
               <div v-if="!getChatResult?.length">
                  <div class="messages-container">
                     <transition name="fade" mode="out-in">
                        <div class="planner-message" v-show="showMessages[0]">
                           <div>
                              <p v-if="showMessages[0]" ref="message1">
                                 Olá, {{ nome }}
                                 <v-icon small>mdi-happy</v-icon>
                              </p>
                           </div>
                        </div>
                     </transition>
                  </div>

                  <div class="messages-container">
                     <transition name="fade" mode="out-in">
                        <div class="planner-message" v-show="showMessages[2]">
                           <div>
                              <p v-if="showMessages[2]" ref="message3">
                                 Como posso te ajudar?
                                 <v-icon small>mdi-happy</v-icon>
                              </p>
                           </div>
                        </div>
                     </transition>
                  </div>
               </div>
               <div v-else>
                  <div class="messages-container">
                     <transition-group name="fade" mode="out-in">
                        <div v-for="(item, index) in getChatResult" :key="index">
                           <!-- Bloco da pergunta -->
                           <div class="message user-message">
                              <div class="message-content">
                                 <p><strong>Pergunta: </strong>{{ item.question }}</p>
                              </div>
                              <div class="message-timestamp">
                                 <small>{{ formatDateTime(item.question_timestamp) }}</small>
                              </div>
                           </div>
                           <!-- Bloco da resposta -->
                           <div class="message planner-message" v-if="item.response">
                              <div class="message-content">
                                 <p><strong>Resposta: </strong>{{ item.response }}</p>
                              </div>
                              <div class="message-timestamp">
                                 <small>{{ formatDateTime(item.response_timestamp) }}</small>
                              </div>
                           </div>
                        </div>
                     </transition-group>
                  </div>
               </div>

               <div class="navigation-buttons">
                  <v-btn icon @click="prevPage" v-show="getIsPreviousPage">
                     <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon @click="nextPage" v-show="getIsNextPage">
                     <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
               </div>
               <div class="input-container">
                  <v-textarea
                     ref="userInput"
                     rows="2"
                     v-model="userMessage"
                     label="Digite sua mensagem"
                     class="user-input input-label-margin"
                     :max-rows="4"
                     @keyup.enter="sendOnEnter"
                  >
                     <template #append>
                        <v-btn icon color="primary" @click="sendMessage" :disabled="disabled">
                           <v-icon>mdi-send</v-icon>
                        </v-btn>
                     </template>
                  </v-textarea>
               </div>
            </v-card-text>
         </v-card>
      </v-menu>
   </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import "./scss/_chatMenu.scss";
export default {
   name: "ChatPlanner",

   methods: {
      ...mapActions(["sendChat", "getChat"]),
      ...mapMutations(["setPage", "setIsNextPage", "setIsPreviousPage"]),

      formatDateTime(dateTimeStr) {
         const dateObj = new Date(dateTimeStr);
         const day = dateObj
            .getDate()
            .toString()
            .padStart(2, "0");
         const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
         const year = dateObj.getFullYear();
         const hours = dateObj
            .getHours()
            .toString()
            .padStart(2, "0");
         const minutes = dateObj
            .getMinutes()
            .toString()
            .padStart(2, "0");
         return `${day}-${month}-${year} ${hours}:${minutes}`;
      },
      sendOnEnter(event) {
         event.preventDefault();
         if (!this.disabled && this.userMessage.trim() !== "") {
            this.sendMessage();
         }
      },

      nextPage() {
         if (this.getIsNextPage) {
            this.setPage(this.getPage + 1);
            this.fetchMessages();
         }
      },
      prevPage() {
         if (this.getIsPreviousPage) {
            this.setPage(this.getPage - 1);
            this.fetchMessages();
         }
      },
      fetchMessages() {
         this.getChat({ page: this.getPage }).then(() => {
            this.checkPageLimits();
         });
      },
      checkPageLimits() {},
      async sendMessage() {
         if (this.userMessage) {
            try {
               this.disabled = true;
               await this.sendChat({
                  message: this.userMessage
               });
               this.userMessage = "";
               this.disabled = false;
               this.fetchMessages();
            } catch (error) {
               console.log(error); // eslint-disable-line
            }
         }
      },
      handleMobileChatOpen() {
         if (this.$vuetify.breakpoint.smAndDown) {
            this.$nextTick(() => {
               this.$refs.chatMenuRef.updateDimensions();
            });
         }
      },
      openChat() {
         this.chatMenu = true;
         this.handleMobileChatOpen();
         this.showMessagesWithDelay();
      },
      showMessageWithDelay(messageIndex, delay) {
         if (this.showMessages[messageIndex]) return;
         setTimeout(() => {
            this.$set(this.showMessages, messageIndex, true);
         }, delay);
      },
      showMessagesWithDelay() {
         if (!this.chatMenu) return;

         this.showMessageWithDelay(0, 200);
         this.showMessageWithDelay(1, 400);
         this.showMessageWithDelay(2, 600);
      },
      closeChat() {
         this.chatMenu = false;
         this.userMessage = "";
      },
      updateZoomLevel() {
         this.zoomLevel = window.devicePixelRatio;
      }
   },
   data: () => ({
      chatDialog: false,
      userMessage: "",
      chatMenu: false,
      plannerData: {
         photo: "https://via.placeholder.com/80",
         name: "Fin Chat"
      },
      nome: "Planejador(a)",
      messageTimeouts: [null, null, null],
      showMessages: [false, false, false],
      zoomLevel: window.devicePixelRatio,
      disabled: false
   }),
   computed: {
      ...mapState({
         persona: store => store.user.persona
      }),
      ...mapGetters(["getChatResult", "getPage", "getTotal", "getIsPreviousPage", "getIsNextPage"]),
      isMobile() {
         return window.innerWidth <= 1360;
      }
   },

   mounted() {
      window.addEventListener("resize", this.updateZoomLevel);
      this.fetchMessages();
   },
   beforeDestroy() {
      window.removeEventListener("resize", this.updateZoomLevel);
   }
};
</script>

<style>
.messages-container {
   display: flex;
   flex-direction: column-reverse;
   overflow-y: auto;
   max-height: 350px;
}

.message {
   max-width: 90%;
   margin-bottom: 10px;
   padding: 10px;
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.user-message {
   align-self: flex-end;
   background-color: #e0f7fa;
}

.planner-message {
   align-self: flex-start;
   background-color: #c8e6c9;
}

.message-content {
   margin: 0 0 4px;
}

.message-timestamp {
   text-align: right;
   font-size: 0.75rem;
   align-self: flex-end;
}

@media (min-width: 800px) {
   .card-desktop {
      width: 800px;
      min-height: 500px;
      display: flex;
      flex-direction: column;
   }
}
</style>
