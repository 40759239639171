<template>
   <v-list class="menuLinks">
      <v-list-item-group v-model="selectedItem" mandatory v-if="role.planner">
         <router-link
            v-for="route in links"
            :to="{
               name: route.name,
               params: { clientId: clientId },
               query: { clientId: clientId }
            }"
            :key="route.id"
         >
            <v-tooltip color="primary" right :disabled="isDisabled">
               <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                     <v-icon color="primary">{{ route.icon }}</v-icon>
                     <span class="d-inline-block menu-item-link">{{ route.text }}</span>
                     <span
                        class="notificationBaloon"
                        v-if="newTasks.length && route.text == 'Tarefas e Avisos'"
                     >
                        {{ newTasks.length }}
                     </span>
                  </v-list-item>
               </template>
               <span>{{ route.text }}</span>
            </v-tooltip>
         </router-link>
      </v-list-item-group>
      <v-list-item-group v-model="selectedItem" mandatory v-else>
         <router-link
            v-for="route in links"
            :to="{
               name: route.name
            }"
            :key="route.id"
            :data-v-tour="route.name == 'fluxo-futuro' ? 'tour-categorization-final' : ''"
         >
            <v-tooltip color="primary" right :disabled="isDisabled">
               <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                     <v-icon color="primary">{{ route.icon }}</v-icon>
                     <span class="d-inline-block menu-item-link">{{ route.text }}</span>
                     <span
                        class="notificationBaloon"
                        v-if="newTasks.length && route.text == 'Tarefas e Avisos'"
                     >
                        {{ newTasks.length }}
                     </span>
                  </v-list-item>
               </template>
               <span>{{ route.text }}</span>
            </v-tooltip>
         </router-link>
      </v-list-item-group>
   </v-list>
</template>

<script>
import activeMenuIndex from "../../helpers/active-menu-index";
import { mapGetters } from "vuex";

export default {
   name: "MenuLinks",
   data() {
      return {
         selectedItem: null
      };
   },
   computed: {
      ...mapGetters(["myTasks", "role"]),
      newTasks() {
         return this.myTasks.filter(task => task.status == "pending");
      },

      clientId() {
         if (this.$route.params.clientId) {
            return this.$route.params.clientId;
         } else if (this.$route.query.clientId) {
            return this.$route.query.clientId;
         } else if (this.$route.query.client) {
            return this.$route.query.client;
         } else if (this.$route.params.client) {
            return this.$route.params.client;
         } else {
            return null;
         }
      },

      isDisabled() {
         return this.$vuetify.breakpoint.width >= 1366;
      }
   },
   methods: {
      updateMenuActive(newRoute = {}) {
         this.selectedItem = activeMenuIndex({
            route: newRoute,
            list: this.links
         });
      }
   },
   props: {
      links: {
         type: Array,
         required: true
      }
   },
   watch: {
      $route: {
         handler(newRoute) {
            this.updateMenuActive(newRoute);
         },
         immediate: true
      }
   }
};
</script>
