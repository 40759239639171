import { Utils } from "@/utils/Utils.js";

export const Query = {
   defaultSettings: {
      acceptBoolean: false,
      acceptNull: false,
      acceptNumber: true,
      acceptString: true,
      acceptArray: true,
      acceptObject: false,
      acceptValid: false
   },

   /**
    * @getter
    * @param {Object} payload
    * @param overrideDefault
    * @returns {String}
    */
   build(payload = {}, overrideDefault = null) {
      if (overrideDefault) {
         this._overrideDefaultSettings(overrideDefault);
      }

      if (Utils.isObject(payload)) {
         const nodes = { ...payload };
         let query = "?";

         for (let node in nodes) {
            let keyItem = nodes[node];
            let queryPiece = String();

            if (Utils.isObject(nodes[node])) {
               if (keyItem.name) {
                  queryPiece = escape(keyItem.name);

                  if (queryPiece) {
                     if (this._validationMatchTypes(keyItem)) {
                        if (Utils.isArray(keyItem.value)) {
                           queryPiece += `=${JSON.stringify(keyItem.value)}&`;
                        } else {
                           queryPiece += `=${escape(keyItem.value)}&`;
                        }
                        query += queryPiece;
                     }
                  }
               }
            } else {
               queryPiece += escape(node);

               if (this._validationMatchTypes(keyItem)) {
                  if (Utils.isArray(keyItem)) {
                     queryPiece += `=${JSON.stringify(keyItem)}&`;
                  } else {
                     queryPiece += `=${escape(keyItem)}&`;
                  }
                  query += queryPiece;
               }
            }
         }
         return query;
      }
   },

   _overrideDefaultSettings(settings = {}) {
      for (let key in settings) {
         if (this.defaultSettings.hasOwnProperty(key)) {
            this.defaultSettings[key] = settings[key];
         }
      }
   },

   /**
    * Validates all settings
    * if node doesn't own a setting
    * it will be set to default
    * @getter
    * @param {*} node
    * @returns {Boolean}
    */
   _validationMatchTypes(node) {
      let isAnAcceptedQuery = false;
      const { acceptValid, ...settings } = this.defaultSettings; // eslint-disable-line no-unused-vars

      for (let key in settings) {
         let shortKey = `is${key.substr(6)}`;
         let nodeValue = Utils.isObject(node) ? node.value : node;

         let permissionKey = this._result(node, key) ?? this.defaultSettings[key];

         if (Utils[shortKey].call(this, nodeValue) && permissionKey && nodeValue !== "undefined") {
            isAnAcceptedQuery = true;
            break;
         }
      }
      return isAnAcceptedQuery;
   },

   /**
    * Prevents stack from breaking
    * when node is null
    * @getter
    * @param {*} node
    * @param {String} keyName
    * @returns {String | node}
    */
   _result(node, keyName) {
      const { acceptBoolean, acceptValid } = this.defaultSettings;

      if (node) {
         return node[keyName];
      }
      if (Utils.isBoolean(node) && acceptBoolean) {
         if (acceptValid) {
            if (node) {
               return escape(node);
            }
         } else {
            return escape(node);
         }
      }
      return node;
   }
};

/**
 * Builds and returns query string
 * @getter
 * @param {String} string
 * @param {String} query
 * @param {Boolean} acceptNull
 * @returns {String}
 */
const setQuery = (string, query, acceptNull = false) => {
   if (acceptNull && Utils.isNull(string)) {
      string = "null";
   }
   if (Utils.isString(string)) {
      return `&${query}=${string}`;
   }
   return "";
};

/**
 * Validates if date param is a valid date
 * @getter
 * @param {*} date
 * @returns {Date}
 */
const isValidDate = date => {
   let d = new Date(date);
   let dateLength = date?.length == 10;

   if (Object.prototype.toString.call(d) === "[object Date]" && dateLength) {
      if (isNaN(d.getTime())) {
         return null;
      } else {
         return date;
      }
   } else {
      return null;
   }
};

export { setQuery, isValidDate };
