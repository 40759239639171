import router from "../router/index";

export default class SessionLocal {
   static token() {
      return localStorage.getItem("token") ? "Token " + localStorage.getItem("token") : false;
   }
   static clear() {
      localStorage.clear();
   }
   static set(_token) {
      localStorage.setItem("token", _token);
   }
   static validate() {
      if (!this.token) {
         router.push("login");
      }
   }
   static asynSet(key, data) {
      return Promise.resolve().then(() => localStorage.setItem(key, data));
   }
   static asyncGet(key) {
      return Promise.resolve().then(() => localStorage.getItem(key));
   }
}
