import { Mod } from "@/services/api";

function getOperatingSystem() {
   const userAgent = window.navigator.userAgent;

   if (
      userAgent.includes("Macintosh") ||
      userAgent.includes("MacIntel") ||
      userAgent.includes("iPad") ||
      userAgent.includes("iPhone")
   ) {
      return "IOS";
   } else if (userAgent.includes("Win")) {
      return "WINDOWS";
   } else if (userAgent.includes("Android")) {
      return "ANDROID";
   } else if (userAgent.includes("Linux")) {
      return "LINUX";
   } else {
      return "OTHER";
   }
}

export default {
   state: {
      organization: localStorage.getItem("organization")
   },
   getters: {
      isOrganizationN2: state => {
         const orgN2 = process.env.VUE_APP_N2;
         return state.organization === orgN2;
      }
   },
   actions: {
      createSession(_commit, payload) {
         payload.client_metadata = {
            platform: "BROWSER",
            os: getOperatingSystem()
         };
         return Mod.find("OpenBankingV2", "session").create(payload);
      },
      createConsents(_commit, payload) {
         try {
            payload.client_metadata = {
               platform: "BROWSER",
               os: getOperatingSystem()
            };
            return Mod.find("OpenBankingV2", "consents").create(payload);
         } catch (e) {
            throw e;
         }
      },
      getParticipants() {
         try {
            return Mod.find("OpenBankingV2", "participants").get();
         } catch (e) {
            throw new Error(e);
         }
      },
      getConnections() {
         try {
            return Mod.find("OpenBankingV2", "connect").get();
         } catch (e) {
            throw new Error(e);
         }
      },
      createApprove(_commit, payload) {
         try {
            return Mod.find("OpenBankingV2", "approve").create(payload);
         } catch (e) {
            throw new Error(e);
         }
      }
   }
};
