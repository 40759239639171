import { Expected } from "@/services/requests.js";
import { Query } from "@/services/utils/validators.js";

export default class Pendency {
   constructor(request) {
      this.moduleName = "pendency";
      this.prefix = "pendency/";

      this.request = request;

      this.routes = {
         pendencys: {
            get: async clientId => {
               const query = Query.build(clientId);

               return await this.request.GET(
                  {
                     url: this.prefix + query
                  },
                  Expected.add({
                     200: "Pendencys listed successfully"
                  })
               );
            },
            update: async ({ body, fileId, clientId }) => {
               const endpoint = `complete-file/${fileId}/`;
               const query = Query.build(clientId);

               return this.request.PATCH(
                  {
                     url: endpoint + query,
                     body
                  },
                  Expected.add({
                     200: "Pendencys updated successfully"
                  })
               );
            }
         }
      };
   }
}
