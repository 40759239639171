/**
 * Request configuration, it has Axios as the requester, but this can be changed fairly easily
 *
 * @throws {Error} When doesn't receive the expected status
 *
 * @obs All requests expect a status as the second param
 */

import deprecated from "@/utils/deprecated.js";
import { Utils } from "@/utils/Utils";
import axios from "axios";
import { http } from "@/constants/HTTP";
import Handler from "./handler";

const token = () => (localStorage.token ? `Token ${localStorage.token}` : false);
const server = http.server;

const deprecate = fnName => {
   deprecated({
      filePath: "src/services/requests",
      fnName
   });
};
class Requests {
   static async custom(options) {
      return axios({
         ...options
      });
   }

   /**
    * @deprecated Use GET instead
    * @param {object} data The request config
    * @returns {Promise<object>} The request
    */
   static async get(data) {
      const { url, headers, responseType } = data;

      deprecate("get");

      return axios({
         method: "GET",
         headers: {
            ...(token() && {
               Authorization: token()
            }),
            ...headers
         },
         responseType: responseType || "",
         url: data.customUrl ? url : server + url
      });
   }

   /**
    * Get request
    *
    * @param {object} data The request config
    * @param {object} expectedStatus The expected status, Modules from ModuleFactory should return expected status
    * @returns {Promise<object>} The request
    */
   static async GET(data, expectedStatus) {
      const { url, headers, responseType } = data;

      return axios({
         method: "GET",
         headers: {
            ...(token() && {
               Authorization: token()
            }),
            ...(headers && {
               ...headers
            })
         },
         responseType: responseType || "",
         url: data.customUrl ? url : server + url
      })
         .then(req => {
            return this.statusExpected(req, expectedStatus);
         })
         .catch(e => {
            throw Handler.troubleshoot(e, "Sistema indisponível. Tente novamente mais tarde.");
         });
   }

   /**
    * @deprecated Use POST instead
    * @param {object} data The request config
    * @returns {Promise<object>} The request
    */
   static async post(data) {
      const { url, body, headers, responseType } = data;
      deprecate("post");

      return axios({
         method: "POST",
         headers: {
            ...(typeof headers !== "boolean" && {
               ...(token() && {
                  Authorization: token()
               }),
               ...headers
            })
         },
         data: body,
         responseType: responseType || "",
         url: server + url
      });
   }

   /**
    * Post request
    *
    * @param {object} data The request config
    * @param {object} expectedStatus The expected status, Modules from ModuleFactory should return expected status
    * @returns {Promise<object>} The request
    */
   static async POST(data, expectedStatus) {
      const { url, body, headers, responseType, hasFile } = data;

      return axios({
         method: "POST",
         headers: {
            ...(typeof headers !== "boolean" && {
               ...(token() && {
                  Authorization: token()
               }),
               ...headers
            }),
            ...(!!hasFile && {
               "Content-Type": "multipart/form-data"
            })
         },
         data: body,
         responseType: responseType || "",
         url: server + url
      })
         .then(req => {
            return this.statusExpected(req, expectedStatus);
         })
         .catch(e => {
            if (e?.response && e?.response?.status === 403) {
               return e.response;
            } else {
               throw Handler.troubleshoot(e, "Sistema indisponível. Tente novamente mais tarde.");
            }
         });
   }

   /**
    * @deprecated Use PUT instead
    * @param {object} data The request config
    * @returns {Promise<object>} The request
    */
   static async put(data) {
      const { url, body, headers } = data;

      deprecate("put");

      return axios({
         method: "PUT",
         headers: {
            ...(typeof headers !== "boolean" && {
               ...(token() && {
                  Authorization: token()
               }),
               ...headers,
               "Content-Type": "application/json"
            })
         },
         data: body,
         url: server + url
      });
   }

   /**
    * Put request
    *
    * @param {object} data The request config
    * @param {object} expectedStatus The expected status, Modules from ModuleFactory should return expected status
    * @returns {Promise<object>} The request
    */
   static async PUT(data, expectedStatus) {
      const { url, body, headers } = data;

      return axios({
         method: "PUT",
         headers: {
            ...(typeof headers !== "boolean" && {
               ...(token() && {
                  Authorization: token()
               }),
               ...headers,
               "Content-Type": "application/json"
            })
         },
         data: body,
         url: server + url
      })
         .then(req => {
            return this.statusExpected(req, expectedStatus);
         })
         .catch(e => {
            throw Handler.troubleshoot(e, "Sistema indisponível. Tente novamente mais tarde.");
         });
   }

   /**
    * @deprecated Use DELETE instead
    * @param {object} data The request config
    * @returns {Promise<object>} The request
    */
   static async delete(data) {
      deprecate("delete");

      return axios({
         method: "DELETE",
         headers: {
            ...(token() && {
               Authorization: token()
            })
         },
         url: server + data.url,
         ...(data.body && {
            data: data.body
         })
      });
   }

   /**
    * Delete request
    *
    * @param {object} data The request config
    * @param {object} expectedStatus The expected status, Modules from ModuleFactory should return expected status
    * @returns {Promise<object>} The request
    */
   static async DELETE(data, expectedStatus) {
      return axios({
         method: "DELETE",
         headers: {
            ...(token() && {
               Authorization: token()
            })
         },
         url: server + data.url,
         ...(data.body && {
            data: data.body
         })
      })
         .then(req => {
            return this.statusExpected(req, expectedStatus);
         })
         .catch(e => {
            throw Handler.troubleshoot(e, "Sistema indisponível. Tente novamente mais tarde.");
         });
   }

   /**
    * Patch request
    *
    * @param {object} data The request config
    * @param {object} expectedStatus The expected status, Modules from ModuleFactory should return expected status
    * @returns {Promise<object>} The request
    */
   static async PATCH(data, expectedStatus) {
      const { url, body, headers } = data;

      return axios({
         method: "PATCH",
         headers: {
            ...(typeof headers !== "boolean" && {
               ...(token() && {
                  Authorization: token()
               }),
               ...headers,
               "Content-Type": "application/json"
            })
         },
         data: body,
         url: server + url
      })
         .then(req => {
            return this.statusExpected(req, expectedStatus);
         })
         .catch(e => {
            throw Handler.troubleshoot(e, "Sistema indisponível. Tente novamente mais tarde.");
         });
   }

   /**
    * Upload request, this should be used when expected to receive a progress update
    *
    * @param {object} data The request config
    * @returns {Promise<object>} The request
    * @obs Uses method: Post
    */
   static async upload(data) {
      const { url, body, headers, responseType } = data;

      return axios({
         method: "POST",
         headers: {
            ...(typeof headers !== "boolean" && {
               ...(token() && {
                  Authorization: token()
               }),
               ...headers
            })
         },
         data: body,
         responseType: responseType || "",
         url: server + url
      }).catch(function(error) {
         return error.response;
      });
   }

   /**
    * Every request expects a list of expected status, so if the request receives something different than expected it will throw
    *
    * @param {object} req The request
    * @param {Array<object>} expectedList The expected status list
    * @returns {Promise<object | string>}
    */
   static statusExpected(req, expectedList) {
      return new Promise((resolve, reject) => {
         if (Array.isArray(expectedList)) {
            if (expectedList.includes(req.status)) {
               resolve(req);
            } else {
               const text = { response: { data: ["Recieved unexpected status in request"] } };
               reject(text);
            }
         } else {
            if (req.status in expectedList) {
               resolve({
                  answer: expectedList[req.status],
                  data: req
               });
            } else {
               reject({ defaultError: expectedList.default });
            }
         }
      });
   }
}

export { Requests };

export const Expected = {
   default: "Sistema indisponível. Tente novamente mais tarde.",
   status: null,

   /**
    * Concat expected status,
    * multiple objects is supported
    * @method
    * @param {Object} prop as { status: value }
    */
   add(prop) {
      this._fresh();

      this.status = {};

      if (Utils.isObject(prop)) {
         Object.keys(prop).forEach(key => {
            this.status[key] = prop[key];
         });
      }
      return this.data;
   },
   /**
    * This should be called before using in multiple
    * functions with the same scope, or by demand
    * @method
    */
   _fresh() {
      this.status = null;
   },

   /**
    * Returns all expected status
    * @getter
    * @returns {Object}
    */
   get data() {
      let expectedStatus = { ...this.default };

      if (!Utils.isNull(this.status)) {
         expectedStatus = { ...expectedStatus, ...this.status };
      }
      return expectedStatus;
   }
};
