const unauthorized = 401;
const unknown = 500;
const notFound = 404;
const notAccepted = 204;
const noContent = 204;
const ok = 200;
const created = 201;
const accepted = 202;
const badRequest = 400;
const forbidden = 403;

export default new (class Stats {
   constructor() {
      this.badLogin = {
         text: "E-mail ou senha incorretos.",
         status: unauthorized
      };

      this.noToken = {
         text: "Token is undefined",
         status: unauthorized
      };

      this.notFound = {
         text: "The page doesn't exist",
         status: notFound
      };

      this.ok = {
         text: "Request successful",
         status: ok
      };

      this.created = {
         text: "Updated",
         status: created
      };

      this.done = {
         text: "Request done",
         status: accepted
      };

      this.unknown = {
         text: "Erro inesperado, entre em contato com o suporte.",
         status: unknown
      };

      this.pendingValidation = {
         text: "Seu upload foi concluído com sucesso! Em breve seu planejador entrará em contato.",
         status: accepted
      };

      this.upload = {
         text: "Seu upload foi concluído com sucesso!",
         status: created
      };

      this.custom = {
         text: "",
         status: 0
      };

      this.formError = {
         text: "Verifique todos os campos!",
         status: unknown
      };

      this.clientNotFound = {
         text: "Não encontramos o usuário informado!",
         status: notAccepted
      };

      this.fileNotFound = {
         text: "Não encontramos o cashflow baseado no ID passado",
         status: notAccepted
      };

      this.protectedFile = {
         text:
            "<span class=d-block>Erro!</span> Verifique se algum arquivo está protegido com senha ou se a extensão dos arquivos são válidas!",
         status: badRequest
      };

      this.duplicatedEmailAccount = {
         text: "Esse e-mail possui mais de 1 usuário",
         status: badRequest
      };
      this.badFile = {
         text: "<span class='d-block'>Erro!</span> Arquivo com leitura incompatível com o sistema.",
         status: unknown
      };
      this.bundleNotFound = {
         text: "Não encontramos o pacote que você procura",
         status: notFound
      };
      this.noContent = {
         text: "Não encontramos dados.",
         status: noContent
      };
      this.noArguments = {
         text: "Não foi passado nenhum argumento para a função",
         status: 204
      };
      this.noConnection = {
         text: "Servidor fora  do ar",
         status: 502
      };
      this.unexpected = {
         text: "Erro inesperado",
         status: 500
      };
      this.forbidden = {
         text: "Sem permissão",
         status: forbidden
      };
   }

   customStatus(obj) {
      return obj;
   }
})();
