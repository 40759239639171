import Address from "@/constants/address";
import currency from "./currency.js";

function arrayForSelects(array, lookFor) {
   let newArray = [];
   array.map(item =>
      newArray.push({
         text: item[lookFor[0]],
         value: item[lookFor[1]]
      })
   );
   return newArray;
}
function arrayForOriginSelect(array) {
   let arrayForSelect = [];
   array.map(origin =>
      arrayForSelect.push({
         text: origin.origin_type + " - " + origin.account_number,
         value: origin.id
      })
   );
   return arrayForSelect;
}

function calculatePlannedValues(plan) {
   if (!plan) return (0).toFixed(2);
   let { expense, income } = plan;

   const results = {};

   results.expenses = 0;
   results.expensePlanned = 0;

   expense.map(transaction => {
      transaction.descriptions.map(descriptionTransactions => {
         results.expenses = results.expenses + descriptionTransactions.descriptionAmountActual;
         results.expensePlanned =
            results.expensePlanned + descriptionTransactions.descriptionAmountPlanned;
      });
   });

   results.income = 0;
   results.incomePlanned = 0;

   income.map(transaction => {
      results.income = results.income + transaction.descriptionAmountActual;
      results.incomePlanned = results.incomePlanned + transaction.descriptionAmountPlanned;
   });

   let toSpend = Math.round(results.expenses - results.expensePlanned);
   toSpend = toSpend < 0 ? toSpend : Math.abs(toSpend);

   const percent = (toSpend / -results.expensePlanned) * 100 || 0;

   return {
      planned: Math.abs(results.expensePlanned).toLocaleString("pt-BR"),
      percent: percent.toFixed(2),
      toSpend: currency.toReal(toSpend)
   };
}

function downloadTemplate() {
   forceDownload(Address.xlsTemplate);
}
function forceDownload(xlsLink) {
   let aTag = document.createElement("a");
   aTag.href = xlsLink;
   aTag.setAttribute("download", "template.xlsx");
   aTag.click();
}
function getTargetMonth(date, justDate) {
   const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
   ];
   date += "-15";
   if (justDate) {
      return `${months[new Date(date).getMonth()]} de ${new Date(date).getFullYear()}`;
   }
   return `Tarefa de planejamento para o mês de ${months[new Date(date).getMonth()]} de ${new Date(
      date
   ).getFullYear()}`;
}
function getTotalAmount(item) {
   var total = 0;
   if (item && item.descriptions) {
      if (item.descriptions.length) {
         if (item.descriptions[0] && item.descriptions[0].descriptionAmountActual) {
            item.descriptions.map(desc => (total = total + desc.descriptionAmountActual));
         } else {
            item.descriptions.map(desc => (total = total + desc.descriptionTotal));
         }
      } else return 0;
   } else if (item && item.expense_items.length) {
      item.expense_items.map(desc => (total = total + desc.amount));
   } else return 0;
   return total.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
}
function getPlannedTotal(inc, exp) {
   let incPlanned = 0;
   let expPlanned = 0;

   inc.map(income => (incPlanned = Math.abs(income.descriptionAmountPlanned) + incPlanned));
   exp.map(expense => (expPlanned = Math.abs(expense.categoryAmountPlanned) + expPlanned));
   return {
      income: parseFloat(incPlanned.toFixed(2)).toLocaleString("pt-BR"),
      expense: parseFloat(expPlanned.toFixed(2)).toLocaleString("pt-BR")
   };
}
function getTotalPlanningAmount(budgetPlan, isComparing) {
   if (!budgetPlan) {
      return 0;
   }

   let amount, isBudgetDescription, isBudgetCategory;

   if (isComparing) {
      isBudgetDescription = budgetPlan[0] && budgetPlan[0].descriptionId;
      isBudgetCategory = budgetPlan[0] && budgetPlan[0].categoryAmountActual;

      if (!isNaN(isBudgetDescription)) {
         amount = budgetPlan.map(budgetItem => budgetItem.descriptionAmountActual);
      } else if (!isNaN(isBudgetCategory)) {
         let separator = [];

         amount = budgetPlan.map(budgetItem =>
            budgetItem.descriptions.map(budgetSubItem => budgetSubItem.descriptionAmountActual)
         );

         amount.map(v => separator.push(...v));
         amount = [...separator];
      } else return 0;
   } else {
      isBudgetDescription = budgetPlan[0] && budgetPlan[0].descriptionId;
      isBudgetCategory = budgetPlan[0] && budgetPlan[0].categoryTotal;

      var isPlanningDescription = budgetPlan[0] && budgetPlan[0].description_id;
      var isPlanningCategory = budgetPlan[0] && budgetPlan[0].category_id;

      if (isBudgetDescription) {
         amount = budgetPlan.map(budgetItem => budgetItem.descriptionTotal);
      } else if (isBudgetCategory) {
         let separator = [];

         amount = budgetPlan.map(budgetItem =>
            budgetItem.descriptions.map(budgetSubItem => budgetSubItem.descriptionTotal)
         );

         amount.map(v => separator.push(...v));
         amount = [...separator];
      } else if (isPlanningDescription) {
         amount = budgetPlan.map(budgetItem => budgetItem.amount);
      } else if (isPlanningCategory) {
         let separator = [];

         amount = budgetPlan.map(budgetItem =>
            budgetItem.expense_items.map(budgetSubItem => budgetSubItem.amount)
         );
         amount.map(v => separator.push(...v));
         amount = [...separator];
      } else return 0;
   }

   let total = 0;
   amount.map(v => (total = total + parseFloat(v)));
   return Math.abs(total).toLocaleString("pt-BR", { minimumFractionDigits: 2 });
}
function isExpense(value) {
   value = isNaN(value) ? parseInt(value) : value;

   if (value < 0) {
      return 1;
   } else if (value > 0) {
      return 0;
   }
}
function maxFileName(fileName, maxNumber) {
   let fileExtension, newFileName;
   fileName = fileName || "";
   maxNumber = maxNumber || 10;

   if (!fileName.length) return "Arquivo com nome inválido";
   if (fileName.length <= maxNumber) return fileName;
   if (fileName.substr(0, fileName.indexOf(".")).length <= maxNumber) return fileName;

   fileExtension = fileName.substr(fileName.indexOf(".") + 1);
   while (fileExtension.indexOf(".") >= 0) {
      fileExtension = fileExtension.substr(fileExtension.indexOf(".") + 1);
   }
   newFileName = fileName.substr(0, maxNumber);
   if (newFileName.indexOf(".") >= 0) {
      newFileName = newFileName.substr(0, newFileName.indexOf("."));
   }

   fileName = newFileName + "..." + fileExtension;
   return fileName;
}
function formatDate(date) {
   if (!date) return "";

   const [year, month, day] = date.split("-");
   return `${day}/${month}/${year}`;
}
function toDateString(date) {
   let day, month, year;

   day = date.substr(0, 2);
   month = date.substr(3, 2);
   year = date.substr(6, 4);

   return `${year}-${month}-${day}`;
}

function formatReal(int) {
   if (!int) return "0,00";
   if (!parseFloat(int)) {
      let cents = parseFloat(int.split(",")[1]);
      if (cents) {
         cents = cents / 100;
         if (cents) {
            return cents.toLocaleString("pt-BR", { minimumFractionDigits: 2, currency: "BRL" });
         }
      }
      return "0,00";
   }
   int = int.toString();
   int = int.replace(/,/g, ".");
   return parseFloat(int).toLocaleString("pt-BR", { minimumFractionDigits: 2, currency: "BRL" });
}
function sendCurrency(int) {
   if (!isNaN(int)) return int;
   let cents = parseFloat(int.split(",")[1]);
   int = parseFloat(int.split(",")[0].replace(/\./g, ""));
   if (int < 0) {
      let amount = int * -1;
      return (amount + (cents ? cents / 100 : 0)) * -1;
   }
   return int + (cents ? cents / 100 : 0);
}
function sortById(list) {
   let _compare = (a, b) => {
      const PLAN_A = a.id;
      const PLAN_B = b.id;

      let comparison = 0;

      if (PLAN_A > PLAN_B) {
         comparison = 1;
      } else if (PLAN_A < PLAN_B) {
         comparison = -1;
      }
      return comparison;
   };

   return list.sort(_compare);
}

function signalConversion(isExpense = null, value) {
   if (isExpense != null) {
      let num = Math.abs(value);
      return isExpense ? num * -1 : num;
   }
   return value;
}

function errorsCode(code) {
   const errors = [
      {
         code: 100,
         message: "Erro na data de caixa / vencimento."
      },
      {
         code: 101,
         message: "Erro, múltiplas datas de vencimento em branco."
      },
      {
         code: 102,
         message: "Erro na origem do tipo cartão crédito"
      },
      {
         code: 103,
         message: "Erro, mais de uma origem tipo cartão"
      },
      {
         code: 104,
         message: "Erro na origin tipo conta corrente"
      },
      {
         code: 105,
         message: "Erro mais de uma origem tipo conta"
      },
      {
         code: 106,
         message: "Erro no dia de fechamento"
      },
      {
         code: 107,
         message: "Erro, multíplos dias de fechamento em branco"
      },
      {
         code: 108,
         message: "Erro no mês de referência"
      }
   ];
   let searchCode = null;

   errors.forEach(item => {
      if (code === item.code) {
         searchCode = item.message;
      }
   });
   return searchCode;
}

export {
   isExpense,
   maxFileName,
   formatDate,
   toDateString,
   formatReal,
   sendCurrency,
   arrayForSelects,
   arrayForOriginSelect,
   forceDownload,
   downloadTemplate,
   getTargetMonth,
   getTotalAmount,
   getTotalPlanningAmount,
   calculatePlannedValues,
   getPlannedTotal,
   sortById,
   errorsCode,
   signalConversion
};
