<template>
   <div class="figureHolder" id="userFigureComponent">
      <div ref="userAvatar" :style="backgroundImage" :alt="userData.name" />
   </div>
</template>

<script>
// Style
import "@/shared/scss/_userFigureComponent.scss";

export default {
   name: "UserFigure",
   computed: {
      backgroundImage() {
         if (this.userData) {
            return `background-image: url(${this.userData.avatar});`;
         } else {
            return "";
         }
      }
   },
   props: {
      userData: Object
   }
};
</script>
