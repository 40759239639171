<template>
   <div class="modalParent">
      <div class="smokeScreen"></div>
      <slot></slot>
   </div>
</template>

<script>
export default {
   name: "ModalBuilder",
   mounted() {
      this.applyDeviceSpecificStyles();
   },
   methods: {
      applyDeviceSpecificStyles() {
         const userAgent = navigator.userAgent;
         const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
         const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
         const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

         if (isSafari && (isIOS || isMac)) {
            this.$el.classList.add("safari-ios-mac");
         }
      }
   }
};
</script>

<style lang="scss">
@import "@/style/variable.scss";

.modalParent {
   position: fixed;
   left: 0;
   top: 0;
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 7;

   &.safari-ios-mac {
      height: 70vh;
      max-height: 70vh;
      margin-top: 5vh;
      overflow-y: auto;
   }

   & > .smokeScreen {
      background: $primary;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0.2;
      animation: easyFade 500ms ease-in 1;
      animation-fill-mode: forwards;

      & ~ * {
         opacity: 0;
         animation: liftUp 600ms ease-in 1;
         animation-delay: 400ms;
         animation-fill-mode: forwards;
      }

      &:before {
         content: "";
         background: rgb(249, 242, 254);
         background: radial-gradient(
            circle,
            rgba(249, 242, 254, 1) 0%,
            rgba(255, 255, 255, 1) 37%,
            rgba(255, 255, 255, 1) 64%,
            rgba(249, 242, 254, 1) 100%
         );
         position: absolute;
         left: 0;
         top: 0;
         opacity: 0.75;
         width: 100%;
         height: 100vh;
      }
   }

   & > * {
      &:not(:first-child) {
         z-index: 2;
      }
   }
   .clientTransactions {
      max-width: 85vw;

      .transactionsBody {
         min-height: unset $i;
      }
   }
}

@keyframes easyFade {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 0.6;
   }
}
@keyframes liftUp {
   0% {
      opacity: 0;
      transform: translateY(20px);
      box-shadow: 0px 0px 0px 0px #000;
   }
   100% {
      opacity: 1;
      transform: translateY(0px);
      box-shadow: 0px 0px 35px -25px #000;
   }
}
</style>
