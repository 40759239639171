import organizationConfig from "./organizationConfig";
import sessionDuration from "./sessionDuration";
import tokenConfig from "./tokenConfig";

import sendTo from "./shared/sendTo";

const resetStore = () => {
   const saveStorege = {
      organizationConfig: localStorage.getItem("organizationConfig"),
      themeConfig: localStorage.getItem("themeConfig"),
      organization: localStorage.getItem("organization"),
      org: localStorage.getItem("org")
   };
   localStorage.clear();

   for (const item in saveStorege) {
      if (saveStorege[item] && saveStorege[item] !== undefined && saveStorege[item] != null) {
         localStorage.setItem(item, saveStorege[item]);
      }
   }
};

export default {
   modules: {
      organizationConfig,
      sessionDuration,
      tokenConfig
   },
   getters: {
      appLoaded(state, getters) {
         return getters.organizationLoaded && getters.tokenLoaded;
      }
   },
   actions: {
      /**
       * Starts organization and token validation
       *
       * @param {object} Store
       */
      validateAppAuth({ dispatch }) {
         dispatch("readConfigTheme");
         dispatch("initOrganizationConfig");
      },

      /**
       * When hard kicking a user, he will lose all localStorage config.
       * Gets redirected to 404 page.
       *
       * @param {object} Store
       */
      hardKickUser({ commit }) {
         resetStore();

         commit("resetOrganizationConfig");
         commit("resetTokenConfig");

         commit("organizationDone", false);
         commit("tokenDone", false);

         // sendTo("no-organization");
      },

      hardKickLogin({ commit }) {
         resetStore();

         commit("resetOrganizationConfig");
         commit("resetTokenConfig");

         commit("organizationDone", false);
         commit("tokenDone", false);

         sendTo("login");
      },

      /**
       * Cleans just the token config, keeping organization config.
       * Kicks to login page.
       *
       * @param {object} Store
       */
      kickUser({ getters, commit }) {
         let orgName = getters.organizationName;

         const organizationConfig = JSON.parse(localStorage.getItem("organizationConfig"));

         const linkRedirect =
            localStorage.getItem("clienteExterno") === "true" && organizationConfig?.link_redirect;

         resetStore();

         commit("resetTokenConfig");
         commit("resetFilterMiscConfig");

         commit("organizationDone", false);
         commit("tokenDone", false);

         if (orgName) {
            commit("overRightOrgConfig", orgName);
         }
         if (linkRedirect) {
            window.location.href = linkRedirect;
         } else {
            sendTo("login");
         }
      }
   }
};
