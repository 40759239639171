export default {
   state: {
      alert: {
         status: false,
         text: "",
         color: "primary"
      }
   },
   getters: {
      Alert: state => state.alert
   },
   mutations: {
      setAlert(state, payload) {
         state.alert.status = true;
         state.alert.text = payload.text;
         state.alert.color = payload?.color ? payload.color : "primary";
      },
      resetAlert(state) {
         state.alert.status = false;
         state.alert.text = "";
         state.alert.color = "primary";
      }
   }
};
