export default class {
   constructor() {
      this.default = null;
      this.view = null;
      this.roles = null;
      this.userAccess = null;

      this.updateUserAccess = null;

      this.init();
   }
   init() {
      this.roles = new Object();
   }
   set(access) {
      this.userAccess = access();
      this.updateUserAccess = access;
   }
   setDefault(role) {
      this.default = role;
   }
   grant(role) {
      this.roles[role] = new Object();
      this.roles[role].accessTo = [role];
      this.roles[role].accessLevel = 0;

      const self = this.roles[role];
      const controller = {
         extend(r) {
            self.accessTo.push(r);
            return this;
         },
         level(level) {
            self.accessLevel = level;
            return this;
         }
      };

      return controller;
   }
   can(permissions) {
      let canSee = false;
      let primaryAccess = permissions.accessTo[0];

      this.update();
      if (!this.userAccess || !this.userAccess.profiles.includes(primaryAccess)) {
         return false;
      }

      for (let permission of permissions.accessTo) {
         if (this.userAccess.profiles.includes(permission)) {
            canSee = permission;
            break;
         }
      }
      return canSee;
   }
   only(permissions) {
      return permissions.level == this.userAccess.level;
   }
   update() {
      this.set(this.updateUserAccess);
   }
   primary(info) {
      let roleLevel = info.profiles
         .map(role => this.roles[role].accessLevel)
         .sort()
         .reverse();
      let roleInfo = {};

      for (let role in this.roles) {
         let $role = this.roles[role];

         if ($role.accessLevel == roleLevel[0]) {
            roleInfo.name = $role.accessTo[0];
            roleInfo.level = roleLevel[0];
         }
      }
      return roleInfo;
   }
}
