<template>
   <div id="PendingSubscription">
      <ModalBuilder v-if="modal.show">
         <div class="wrapStepper">
            <v-stepper v-model="step" class="stepperItem">
               <v-stepper-header>
                  <v-stepper-step :complete="step > 1" step="1">Plano proposto</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 2" step="2">Assinatura</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step >= 3" step="3">Concluído</v-stepper-step>
               </v-stepper-header>

               <v-stepper-items>
                  <!--  -->
                  <!-- Item I -->
                  <v-stepper-content step="1">
                     <div class="loaded" v-if="!isLoading">
                        <div class="processWithError" v-if="hasError">
                           <h3 class="mb-3">Erro</h3>
                           <p>Tente novamente mais tarde.</p>
                           <div class="d-flex align-center justify-end flex-row pb-2 mt-10">
                              <v-btn color="primary" @click="modal.show = false">Fechar</v-btn>
                           </div>
                        </div>
                        <div class="complete" v-else>
                           <div class="step-info fadeUp" v-if="user && suggestedPlan">
                              <h3 class="mb-5">
                                 <strong>Olá {{ user.first_name }}!</strong>
                              </h3>
                              <!--  -->
                              <!-- Service briefing -->
                              <p v-if="platformTypeService">
                                 {{ plannerName }} autorizou sua assinatura no modelo gratuito da
                                 Plataforma
                                 <span v-if="organizationData">{{
                                    organizationData.corporate_name
                                 }}</span
                                 >. Fique tranquilo, você continuará tendo acesso aos mesmos
                                 recursos que antes
                              </p>
                              <div v-else>
                                 <p>Seu planejador recomendou a alteração de sua assinatura.</p>
                                 <p v-if="suggestedPlan.name">
                                    <span>Plano: </span> {{ suggestedPlan.name }}
                                 </p>
                                 <p
                                    class="subscriptionDescription"
                                    v-if="suggestedPlan.description"
                                 >
                                    <span>Descrição: </span>{{ suggestedPlan.description }}
                                 </p>
                                 <p>
                                    <span>Valor: </span>
                                    <user-plan-message :subscription="suggestedPlan" />
                                 </p>
                              </div>
                           </div>
                           <div class="d-flex align-center justify-end flex-row pb-2 mt-10">
                              <v-btn text color="error" @click="reject">Recusar</v-btn>
                              <v-btn color="primary" @click="nextStep">Continuar</v-btn>
                           </div>
                        </div>
                     </div>
                     <div class="loading py-10 d-flex align-center justify-center" v-else>
                        <v-progress-circular :size="50" color="primary" indeterminate />
                     </div>
                  </v-stepper-content>
                  <!--  -->
                  <!-- Item II -->
                  <v-stepper-content step="2">
                     <div class="loaded" v-if="!isLoading">
                        <div class="processWithError" v-if="hasError">
                           <h3 class="mb-3">Erro</h3>
                           <p>Tente novamente mais tarde.</p>
                           <div class="d-flex align-center justify-end flex-row pb-2 mt-10">
                              <v-btn color="primary" @click="modal.show = false">Fechar</v-btn>
                           </div>
                        </div>
                        <div class="complete" v-else>
                           <div class="step-info fadeUp" v-if="user">
                              <v-form ref="paymentData">
                                 <!--  -->
                                 <!-- Security Number -->
                                 <strong class="inherit mb-2 d-block">Dados pessoais</strong>
                                 <div class="personalData">
                                    <v-text-field
                                       dense
                                       v-model="panel.user.cpf"
                                       :rules="rules.cpf"
                                       v-mask="panel.masks.cpf"
                                       hide-details
                                       outlined
                                       label="CPF"
                                    />
                                 </div>
                                 <v-divider class="mt-10 mb-8" />
                                 <!--  -->
                                 <!-- address -->
                                 <strong class="inherit mb-2 d-block">Endereço</strong>
                                 <div class="address cardWrap">
                                    <div class="cep">
                                       <!--  -->
                                       <!-- cep -->
                                       <v-text-field
                                          v-model="panel.address.zipcode"
                                          v-mask="panel.masks.cep"
                                          outlined
                                          dense
                                          hide-details
                                          autocomplete="cep"
                                          label="CEP"
                                          :rules="rules.cep"
                                          :loading="isCepLoading"
                                          @blur="searchZipCode"
                                       />
                                    </div>
                                    <div class="street">
                                       <!--  -->
                                       <!-- Neighborhood -->
                                       <v-text-field
                                          v-model="panel.address.neighborhood"
                                          autocomplete="neighborhood"
                                          label="Bairro"
                                          :rules="rules.name"
                                          outlined
                                          dense
                                          hide-details
                                       />
                                       <!--  -->
                                       <!-- Street -->
                                       <v-text-field
                                          v-model="panel.address.street"
                                          autocomplete="street"
                                          label="Rua"
                                          outlined
                                          dense
                                          hide-details
                                          :rules="rules.name"
                                       />
                                    </div>
                                    <div class="number">
                                       <!--  -->
                                       <!-- Street Number -->
                                       <v-text-field
                                          v-model="panel.address.street_number"
                                          autocomplete="street"
                                          label="Número"
                                          :rules="rules.house"
                                          outlined
                                          dense
                                          hide-details
                                       />
                                       <!--  -->
                                       <!-- Complementary -->
                                       <v-text-field
                                          v-model="panel.address.complementary"
                                          autocomplete="complementary"
                                          outlined
                                          dense
                                          hide-details
                                          label="Complemento"
                                       />
                                    </div>
                                 </div>

                                 <v-divider class="mt-10 mb-8" />

                                 <strong class="inherit">Cartão de crédito</strong>
                                 <div class="creditCard card pt-2">
                                    <!--  -->
                                    <!-- Name on card -->
                                    <v-text-field
                                       v-model="panel.creditCard.card_holder_name"
                                       label="Nome no cartão"
                                       prepend-inner-icon="mdi-account-outline"
                                       hide-details
                                       :rules="rules.name"
                                       dense
                                       outlined
                                    />
                                    <!-- -->
                                    <!-- Card number -->
                                    <v-text-field
                                       v-model="panel.creditCard.card_number"
                                       v-mask="panel.masks.creditCard"
                                       placeholder="xxxx xxxx xxxx xxxx"
                                       :rules="rules.name"
                                       label="Cartão de crédito"
                                       hide-details
                                       outlined
                                       dense
                                       prepend-inner-icon="mdi-credit-card-plus-outline"
                                    />
                                 </div>
                                 <div class="creditCard details pt-2">
                                    <!--  -->
                                    <!-- Expire Date -->
                                    <v-text-field
                                       label="Vencimento do cartão"
                                       v-mask="panel.masks.expireDate"
                                       v-model="panel.creditCard.card_expiration_date"
                                       hide-details
                                       dense
                                       prepend-inner-icon="mdi-calendar"
                                       placeholder="mês/ano"
                                       :rules="rules.name"
                                       outlined
                                    />
                                    <!--  -->
                                    <!-- CVV -->
                                    <v-text-field
                                       label="CVV"
                                       v-mask="panel.masks.cvv"
                                       v-model="panel.creditCard.card_cvv"
                                       :rules="rules.name"
                                       hide-details
                                       dense
                                       outlined
                                    >
                                       <template v-slot:append>
                                          <v-tooltip top color="primary">
                                             <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                   color="primary"
                                                   dark
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   >mdi-help-circle-outline</v-icon
                                                >
                                             </template>
                                             <span
                                                >Número de três dígitos no verso do cartão de
                                                crédito</span
                                             >
                                          </v-tooltip>
                                       </template>
                                    </v-text-field>
                                 </div>
                                 <div class="totalToPay">
                                    <div
                                       class="d-flex flex-row align-center justify-space-between mt-5"
                                    >
                                       <span>
                                          <strong>Total a pagar:</strong>
                                       </span>
                                       <span>
                                          <strong class="inherit">R$ {{ suggestedTotal }}</strong>
                                       </span>
                                    </div>
                                 </div>
                              </v-form>
                           </div>
                           <div class="d-flex align-center justify-end flex-row pb-2 mt-10">
                              <v-btn
                                 text
                                 color="error"
                                 @click="step = 1"
                                 :disabled="isSecondaryLoading"
                                 >Voltar</v-btn
                              >
                              <v-btn color="primary" @click="finish" :loading="isSecondaryLoading"
                                 >Concluir</v-btn
                              >
                           </div>
                        </div>
                     </div>
                     <div class="loading py-10 d-flex align-center justify-center" v-else>
                        <v-progress-circular :size="50" color="primary" indeterminate />
                     </div>
                  </v-stepper-content>
                  <!--  -->
                  <!-- Item III -->
                  <v-stepper-content step="3">
                     <h3 class="d-block mb-3">Sucesso!</h3>
                     <p>Parabéns, sua assinatura foi alterada com sucesso.</p>

                     <div
                        v-if="
                           persona?.current_subscription?.last_payment_date_formatted &&
                              persona?.current_subscription?.next_payment_date_formatted
                        "
                     >
                        Como o ultimo pagamento da assinatura anterior foi dia
                        {{ persona?.current_subscription?.last_payment_date_formatted }}, agendamos
                        o primeiro débito de sua nova assinatura para o dia
                        {{ persona?.current_subscription?.next_payment_date_formatted }}.
                     </div>

                     <div class="d-flex align-center justify-end flex-row pb-2 mt-10">
                        <v-btn color="primary" @click="close">Fechar</v-btn>
                     </div>
                  </v-stepper-content>
               </v-stepper-items>
            </v-stepper>
         </div>
      </ModalBuilder>
      <!--  -->
      <!-- Warnings -->
      <Warning ref="Warning" />
   </div>
</template>

<script>
// Components
import ModalBuilder from "@/shared/components/atomicDesign/atoms/ModalBuilder.vue";
import Warning from "@/shared/components/Warning.vue";
import "@/style/_pendingSubscriptionModal.scss";
import currency from "@/utils/currency.js";
import Rules from "@/utils/formRules.js";
import Loader from "@/utils/loader";
import { mapActions, mapGetters, mapState } from "vuex";
import UserPlanMessage from "@/components/messages/UserPlanMessage.vue";

export default {
   name: "PendingSubscriptionModal",
   data() {
      return {
         step: 1,
         panel: {
            address: {
               city: "",
               complementary: "",
               country: "br",
               neighborhood: "",
               state: "",
               street: "",
               street_number: "",
               zipcode: ""
            },
            creditCard: {
               card_cvv: "",
               card_holder_name: "",
               card_number: "",
               card_expiration_date: ""
            },
            user: {
               cpf: ""
            },
            complete: false,
            error: false,
            loading: new Loader(),
            masks: {
               cep: "#####-###",
               creditCard: "#### #### #### ####",
               expireDate: "##/##",
               cvv: "###",
               cpf: "###.###.###-##"
            },
            steps: {
               initial: {
                  loading: new Loader()
               },
               secondary: {
                  loading: new Loader(),
                  cep: new Loader()
               }
            }
         },
         modal: {
            show: false
         }
      };
   },
   components: {
      UserPlanMessage,
      ModalBuilder,
      Warning
   },
   computed: {
      ...mapGetters(["persona", "clientNewSubscription", "suggestedPlan", "organizationData"]),
      ...mapState({
         persona: store => store.user.persona
      }),
      rules: () => Rules,
      hasError() {
         return this.panel.error;
      },
      isCepLoading() {
         return !!this.panel.steps.secondary.cep.status;
      },
      isLoading() {
         return !!this.panel.loading.status;
      },
      isSecondaryLoading() {
         return !!this.panel.steps.secondary.loading.status;
      },
      plannerName() {
         if (this.persona?.planner) {
            return this.persona.planner.user.first_name;
         } else {
            return "";
         }
      },
      platformTypeService() {
         return this.suggestedPlan.type === "platform";
      },
      suggestedTotal() {
         const PLAN = this.suggestedPlan;
         if (PLAN.service_amount && PLAN.service_amount > 0) {
            return currency.calculateReal([PLAN.platform_amount, PLAN.service_amount]);
         }
         return currency.calculateReal([PLAN.platform_amount, PLAN.recurrent_amount]);
      },
      user() {
         if (this.persona) {
            return this.persona;
         } else {
            return false;
         }
      }
   },
   methods: {
      ...mapActions([
         "getSubscriptionPlanned",
         "getSuggestedPlan",
         "acceptSubscription",
         "findCep",
         "hashCreditCard",
         "createManualTransactionCartao"
      ]),
      async accept() {
         this.loadingSecondary();

         const ADDRESS = this.treatedAddressRequirements();

         try {
            let parts = this.panel.creditCard.card_expiration_date.split("/");
            const exp_month = parts[0];
            const exp_year = parts[1];

            const payload = {
               type: "card",
               card: {
                  number: this.panel.creditCard.card_number.replace(/\D/g, ""),
                  holder_name: this.panel.creditCard.card_holder_name,
                  exp_month,
                  exp_year,
                  cvv: this.panel.creditCard.card_cvv
               }
            };
            const card = await this.createManualTransactionCartao(payload);
            await this.acceptSubscription({
               ...this.clientNewSubscription[0],
               address: { ...ADDRESS },
               creditCard: card.data.id,
               cpf: this.panel.user.cpf,
               accepts: true
            });
            this.step = 3;
         } catch (e) {
            console.error(e); // eslint-disable-line no-console
            this.$refs.Warning.throw(
               e ||
                  e.message ||
                  "Erro ao realizar ao pagamento! Tente novamente mais tarde ou entre em contato com o suporte."
            );
         } finally {
            this.loadingSecondary("done");
         }
      },

      treatedAddressRequirements() {
         const { zipcode: rawZipcode, complementary, ...otherAddressFields } = this.panel.address;

         const treatedAddress = {
            ...otherAddressFields,
            zipcode: rawZipcode.replace("-", "")
         };

         if (complementary) {
            treatedAddress.complementary = complementary;
         }

         return treatedAddress;
      },
      close() {
         this.modal.show = false;
         this.$forceUpdate();
         window.location.reload();
      },
      fillCep(address) {
         this.loadCep("done");

         const ADDRESS = this.panel.address;

         if (address?.data) {
            ADDRESS.city = address.data.cidade;
            ADDRESS.country = "br";
            ADDRESS.neighborhood = address.data.bairro;
            ADDRESS.street = address.data.endereco;
            ADDRESS.state = address.data.uf.toLowerCase();
         }
      },
      finish() {
         const FORM = this.$refs.paymentData;
         if (FORM.validate()) {
            this.loadingSecondary();
            this.accept();
         } else {
            const NOT_VALID = "Preencha corretamente os campos obrigatórios";

            this.$refs.Warning.throw(NOT_VALID);
         }
      },
      init() {
         this.loadSubscriptionPlans();
      },

      async reject() {
         try {
            this.loading();

            await this.acceptSubscription({
               ...this.clientNewSubscription[0],
               accepts: false
            });

            this.close();
         } catch (e) {
            this.screenLoaded(false, e);
         } finally {
            this.loading("done");
         }
      },

      reset() {
         this.panel.error = false;
         this.step = 1;
      },
      loading(set) {
         this.panel.loading.setter(set);
      },
      loadingSecondary(set) {
         this.panel.steps.secondary.loading.setter(set);
      },
      loadSubscriptionPlans() {
         this.loading();
         this.getSubscriptionPlanned()
            .then(() => {
               let newSub = this.clientNewSubscription;

               if (newSub?.length) {
                  const NEW_SUBSCRIPTION = this.clientNewSubscription[0];

                  this.getSuggestedPlan(NEW_SUBSCRIPTION.plan)
                     .then(() => this.screenLoaded(true))
                     .catch(e => this.screenLoaded(false, e));
               } else {
                  this.screenLoaded(false, "Sistema indisponível. Tente novamente mais tarde.");
               }
            })
            .catch(e => this.screenLoaded(false, e))
            .finally(() => this.loading("done"));
      },
      loadCep(set) {
         this.panel.steps.secondary.cep.setter(set);
      },
      nextStep() {
         this.validatePlanSubscription();
      },
      screenLoaded(ready, err) {
         if (ready) {
            this.panel.complete = true;
         } else {
            this.panel.complete = false;
            this.panel.error = true;

            this.$refs.Warning.throw(err);
         }
      },
      async searchZipCode() {
         let zipCode = this.panel.address.zipcode;

         if (zipCode && zipCode.length >= 9) {
            try {
               this.loadCep();
               const response = await this.findCep(zipCode);
               this.fillCep(response);
            } catch (e) {
               this.$refs.Warning.throw(e);
            } finally {
               this.loadCep("done");
            }
         }
      },

      validatePlanSubscription() {
         const PLAN = this.suggestedPlan;

         if (PLAN && PLAN.type === "platform") {
            this.loading();
            this.acceptSubscription({
               ...this.clientNewSubscription[0],
               accepts: true
            })
               .then(() => {
                  this.step = 3;
                  this.screenLoaded(true);
               })
               .catch(e => this.screenLoaded(false, e))
               .finally(() => this.loading("done"));
         } else {
            this.step++;
         }
      },
      verifySubscriptionState() {
         const PERSONA = this.persona;

         if (PERSONA) {
            const HAS_SUBSCRIPTION = !!PERSONA.has_pending_subscription;

            if (HAS_SUBSCRIPTION) {
               this.init();
               this.modal.show = true;
               this.reset();
            }
         }
      }
   },
   watch: {
      persona: {
         handler() {
            this.verifySubscriptionState();
         }
      }
   }
};
</script>
