// Services
/**
 * This module is specifically manager profiles actions and mutation control
 * eg: manager related
 *
 * This module should make use only of the new API structure
 * Modules as Mod
 */

import { Mod } from "@/services/api";
import Clients from "./manager/clients";

export default {
   state: {
      planner: {
         list: []
      },
      organization: {
         plans: [],
         bank: [],
         partner: []
      }
   },

   getters: {
      plannerList: state => state.planner.list,
      organizationPlanList: state => state.organization.plans,
      organizationBankData: state => state.organization.bank,
      organizationPartnerData: state => state.organization.partner
   },

   actions: {
      async getPlannerList({ commit }) {
         const plannerList = await Mod.find("profiles", "planner").list();

         commit("updateNewPlannerList", plannerList.data.data);
         return plannerList.answer;
      },
      async updatePlanner(_, payload) {
         return await Mod.find("profiles", "planner").update(payload);
      },
      async getOrganizationPlans({ commit }) {
         const plans = await Mod.find("payment", "plans").get();

         commit("updateOrganizationPlans", plans.data.data);
         return plans.data;
      },
      async editOrganizationPlan(store, plan) {
         return await Mod.find("payment", "plans").edit({
            is_active: plan.is_active || false,
            payday: plan.payday || null,
            id: plan.id
         });
      },
      async getBankAccountData({ commit }) {
         const bankData = await Mod.find("payment", "bank").get();
         const partnerData = await Mod.find("managingPartner", "bank").get();

         commit("updateBankDataStorage", bankData.data.data);
         commit("updatePartnerDataStorage", partnerData.data.data);

         return Promise.all([bankData, partnerData]);
      },
      async addBankAccountData({ dispatch, getters }, bankData) {
         const { managingPartner, bank } = bankData;

         const BANK_INFO = getters.organizationBankData[0];
         const PARTNER_INFO = getters.organizationPartnerData[0];

         if (bank.cnpj) {
            if (PARTNER_INFO) {
               await Mod.find("managingPartner", "bank").edit(managingPartner);
            } else {
               await Mod.find("managingPartner", "bank").create(managingPartner);
            }
         }

         if (BANK_INFO) {
            await Mod.find("payment", "bank").edit(bank);
         } else {
            await Mod.find("payment", "bank").create(bank);
         }

         await dispatch("getBankAccountData");
         return;
      },
      async changeClientPlanner(store, prop) {
         return await Mod.find("profiles", "clients").setPlanner(prop);
      }
   },

   mutations: {
      updateNewPlannerList(state, newList) {
         state.planner.list = newList;
      },
      updateOrganizationPlans(state, plans) {
         state.organization.plans = plans;
      },
      updateBankDataStorage(state, data) {
         state.organization.bank = data;
      },
      updatePartnerDataStorage(state, data) {
         state.organization.partner = data;
      }
   },

   modules: {
      Clients
   }
};
