export default class RestAuth {
   constructor(request) {
      this.moduleName = "restAuth";
      this.prefix = "rest-auth/";

      this.request = request;

      this.endpoints = {
         reset: "password/reset/",
         change: "password/reset/confirm/"
      };

      this.routes = {
         password: {
            requestNew: this.requestNewPassword,
            change: this.changePassword
         }
      };
      this.bind(this.routes, this);
   }

   async requestNewPassword(payload) {
      const expectedStatus = {
         200: "Email enviado!",
         default: "Sistema indisponível. Tente novamente mais tarde., tente novamente mais tarde"
      };

      return await this.request.POST(
         {
            url: this.prefix + this.endpoints.reset,
            body: { ...payload }
         },
         expectedStatus
      );
   }
   async changePassword(payload) {
      const expectedStatus = {
         200: "Senha alterada!",
         default: "Sistema indisponível. Tente novamente mais tarde., tente novamente mais tarde"
      };

      return await this.request.POST(
         {
            url: this.prefix + this.endpoints.change,
            body: { ...payload }
         },
         expectedStatus
      );
   }
}
