<template>
   <div id="sidebarMenu">
      <div id="managerMenu" v-if="isManager">
         <menu-builder menu-type="manager" :organization="organization" />
         <help-desk-button v-if="canSeeHelpFeature" />
      </div>

      <div id="plannerMenu" v-else-if="isPlanner && plannerAsClient">
         <menu-builder menu-type="planner" :organization="organization" />
         <help-desk-button v-if="canSeeHelpFeature" />
      </div>

      <div id="clientMenu" v-else-if="isClient || isPlanner">
         <menu-builder menu-type="client" :organization="organization" :isPlanner="isPlanner" />
         <help-desk-button v-if="canSeeHelpFeature" />
      </div>

      <div id="clientMenu" v-if="!isMobile">
         <chat-button v-if="isClient && chatUserEnable" />
      </div>

      <div id="clientMenu" v-if="!isMobile && isPlanner && chatPlannerEnable">
         <chat-planner />
      </div>
   </div>
</template>

<script>
import HelpDeskButton from "@/shared/components/atomicDesign/atoms/HelpDeskButton.vue";
import ChatButton from "@/shared/components/chat/ChatButton";
import ChatPlanner from "@/shared/components/chat/ChatPlanner";
import { mapGetters, mapState } from "vuex";
import MenuBuilder from "./components/organisms/MenuBuilder";
import "./scss/_userMenu.scss";

export default {
   name: "ClientMenu",
   components: {
      HelpDeskButton,
      MenuBuilder,
      ChatButton,
      ChatPlanner
   },
   computed: {
      ...mapState({
         organization: store => store.user.persona.organization
      }),
      ...mapGetters(["role", "userCanSeeToggleFeature", "chatUserEnable", "chatPlannerEnable"]),

      isClient() {
         return this.role.client;
      },

      isPlanner() {
         return this.role.planner;
      },

      isManager() {
         return this.role.manager;
      },

      canSeeHelpFeature() {
         return this.userCanSeeToggleFeature("help");
      },

      plannerAsClient() {
         return this.$route.name === "dashboard" || this.$route.name == "perfil";
      },
      isMobile() {
         return window.innerWidth <= 1360;
      }
   }
};
</script>
