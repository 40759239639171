export default class Organization {
   constructor(request) {
      this.moduleName = "organization";
      this.prefix = "organization/";
      this.extends = {
         finish: "finish-register/",
         logo: "logo/"
      };

      this.request = request;

      this.routes = {
         register: {
            done: this.completeRegister
         },
         company: {
            verify: this.verifyOrganization
         },
         theme: {
            update: this.changeOrganizationColor,
            updateLogo: this.changeOrganizationLogo
         }
      };
      this.bind(this.routes, this);
   }

   async completeRegister(payload) {
      const expectedStatus = {
         200: "Organização registrada",
         default: "Sistema indisponível. Tente novamente mais tarde."
      };

      return this.request.POST(
         {
            url: this.prefix + `${payload.company}/` + this.extends.finish,
            headers: payload.headers
         },
         expectedStatus
      );
   }
   async verifyOrganization(organizationName) {
      if (organizationName) {
         const expectedStatus = {
            200: "Organização encontrada",
            default: "Organização não encontrada"
         };

         let query = organizationName + "/";

         return this.request.GET(
            {
               url: this.prefix + query
            },
            expectedStatus
         );
      }
   }
   async changeOrganizationColor(payload) {
      const expectedStatus = {
         200: "Organização encontrada",
         default: "Organização não encontrada"
      };

      let organizationName = JSON.parse(localStorage.organizationConfig).name;
      let query = organizationName + "/";

      return this.request.PUT(
         {
            url: this.prefix + query,
            body: {
               primary_color: payload.primary,
               secondary_color: payload.secondary
            }
         },
         expectedStatus
      );
   }
   async changeOrganizationLogo(payload) {
      const expectedStatus = {
         201: "Logo Atualizada com sucesso!",
         default: "Logo Atualizada"
      };

      return this.request.POST(
         {
            url: this.extends.logo,
            body: payload
         },
         expectedStatus
      );
   }
}
