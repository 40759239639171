var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"menuLinks"},[(_vm.role.planner)?_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.links),function(route){return _c('router-link',{key:route.id,attrs:{"to":{
            name: route.name,
            params: { clientId: _vm.clientId },
            query: { clientId: _vm.clientId }
         }}},[_c('v-tooltip',{attrs:{"color":"primary","right":"","disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(route.icon))]),_c('span',{staticClass:"d-inline-block menu-item-link"},[_vm._v(_vm._s(route.text))]),(_vm.newTasks.length && route.text == 'Tarefas e Avisos')?_c('span',{staticClass:"notificationBaloon"},[_vm._v(" "+_vm._s(_vm.newTasks.length)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(route.text))])])],1)}),1):_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.links),function(route){return _c('router-link',{key:route.id,attrs:{"to":{
            name: route.name
         },"data-v-tour":route.name == 'fluxo-futuro' ? 'tour-categorization-final' : ''}},[_c('v-tooltip',{attrs:{"color":"primary","right":"","disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(route.icon))]),_c('span',{staticClass:"d-inline-block menu-item-link"},[_vm._v(_vm._s(route.text))]),(_vm.newTasks.length && route.text == 'Tarefas e Avisos')?_c('span',{staticClass:"notificationBaloon"},[_vm._v(" "+_vm._s(_vm.newTasks.length)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(route.text))])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }