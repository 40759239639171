import { Mod } from "@/services/api";

export default {
   state: {
      bankList: []
   },
   mutations: {
      updateBankList(state, bankList) {
         if (Array.isArray(bankList) && bankList.length > 0) {
            state.bankList = bankList.map(bank => {
               return {
                  value: bank.code,
                  text: bank.name
               };
            });
         } else {
            state.bankList = [];
         }
      }
   },
   actions: {
      async getBankList({ commit, state }) {
         if (state.bankList?.length > 0) return;
         try {
            const bankList = await Mod.find("bank", "list").get();
            commit("updateBankList", bankList.data);
         } catch (e) {
            throw new Error(e);
         }
      }
   },
   getters: {
      listBank: state => state.bankList
   }
};
