import { Query } from "@/services/utils/validators.js";

export default class Integration {
   constructor(request) {
      this.moduleName = "integration";
      this.prefix = "integration/";

      this.request = request;

      this.extends = {
         startSession: "start-session/",
         belvo: "belvo/",
         multiFactorAuth: "mfa/"
      };

      this.routes = {
         session: {
            startQuanto: this.startQuantoSession,
            startBS2: this.startBS2Session,
            startBelvo: this.startBelvoSession
         },
         belvo: {
            createLink: this.belvoCreateLink
         }
      };
      this.bind(this.routes, this);
   }
   async startQuantoSession() {
      const expectedStatus = {
         200: "E-mail verificado",
         default: "Erro na leitura do token"
      };

      let endpoint = this.extends.startSession + "quanto/";

      return this.request.POST(
         {
            url: this.prefix + endpoint
         },
         expectedStatus
      );
   }
   async startBS2Session() {
      const expectedStatus = {
         200: "E-mail verificado",
         default: "Erro na leitura do token"
      };

      let endpoint = this.extends.startSession + "bs2/";

      return this.request.POST(
         {
            url: this.prefix + endpoint
         },
         expectedStatus
      );
   }
   async startBelvoSession(bankCode) {
      const query = Query.build({ bankCode });
      const expectedStatus = {
         200: "Resposta recebida.",
         default: "Erro na leitura do token"
      };

      return this.request.GET(
         {
            url: this.prefix + this.extends.belvo + "access_token/" + query
         },
         expectedStatus
      );
   }
   async belvoCreateLink(body) {
      const expectedStatus = {
         201: "Link criado.",
         default: "Erro na leitura do token"
      };

      return this.request.POST(
         {
            url: this.prefix + this.extends.belvo + "links/",
            body
         },
         expectedStatus
      );
   }
}
