import router from "@/router";
import store from "@/store";

export default class Interceptors {
   constructor() {
      this.clientId = null;
   }

   needClientId(config = null) {
      if (!config || !this.requiresAuth()) return false;
      if (!store.getters.role.planner) return false;
      if (this.haveClientId(config)) return false;
      if (this.userEndpoints(config)) return false;

      this.setClientId();
      return true;
   }

   requiresAuth() {
      return router.currentRoute.matched.some(record => record.meta.requiresAuth);
   }

   haveClientId({ url }) {
      return url.includes("client=") || url.includes("clientId=");
   }

   setClientId() {
      const { clientId } = router.currentRoute.query;
      this.clientId = clientId;
   }

   getParam() {
      return {
         clientId: this.clientId
      };
   }

   userEndpoints({ url }) {
      let endpoints = ["me/", "organization/", "dashboard/", "profiles/"];
      endpoints = endpoints.filter(record => url.split("/").includes(record));
      return endpoints.length;
   }
   /**
    *
    * @param {string} url
    * @returns url, but, user is client and url has clientId,
    * this function return url correctly
    */
   isClient({ url }) {
      if (store.getters.role.client) {
         if (this.haveClientId({ url })) {
            let [baseUrl, query] = url.split("?");
            query = query.split("&");
            query = query.filter(item => !item.includes("client=") && !item.includes("clientId="));
            return baseUrl + "?" + query.join("&");
         }
      }

      return url;
   }
}
