import { Expected } from "@/services/requests.js";

export default class Debts {
   constructor(request) {
      this.moduleName = "debts";
      this.prefix = "debts/";

      this.request = request;

      this.routes = {
         debts: {
            create: async payload => {
               return await this.request.POST(
                  {
                     url: this.prefix + "create/",
                     body: { ...payload }
                  },
                  Expected.add({
                     200: "Dívida criada"
                  })
               );
            },
            delete: async ({ id }) => {
               return await this.request.DELETE(
                  {
                     url: this.prefix + `edit/${id}/`
                  },
                  Expected.add({
                     200: "Resposta recebida",
                     204: "Plano deletado"
                  })
               );
            },
            update: async payload => {
               return await this.request.PUT(
                  {
                     url: this.prefix + `edit/${payload.id}/`,
                     body: { ...payload }
                  },
                  Expected.add({
                     200: "Resposta recebida",
                     204: "Plano deletado"
                  })
               );
            }
         }
      };
   }
}
