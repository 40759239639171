<template>
   <div id="HelpDeskAutoPlay">
      <CardBodyV2 modal ref="HelpDeskAutoPlay" :title="hasTitle">
         <template v-slot:content>
            <div class="tutorial-content overflow-sm-auto">
               <iframe
                  class="tutorial-video"
                  :src="source"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
               ></iframe>
               <div class="d-flex flex-column align-center ma-4">
                  <p>{{ helpDeskAutoPlayInfos.description | substring }}</p>
                  <v-checkbox v-model="showVideo" label="Não exibir novamente"></v-checkbox>
                  <v-btn color="primary" @click="closeHelpDesk">
                     Fechar
                  </v-btn>
               </div>
            </div>
         </template>
      </CardBodyV2>
   </div>
</template>

<script>
//Components
import CardBodyV2 from "@/shared/components/atomicDesign/molecules/CardBodyV2.vue";
//Vuex
import { mapGetters, mapActions } from "vuex";
//Styles
import "@/style/_helpDeskAutoPlay.scss";

export default {
   name: "HelpDeskAutoPlay",
   data() {
      return {
         showVideo: false
      };
   },
   computed: {
      ...mapGetters(["helpDeskAutoPlayInfos"]),
      hasTitle() {
         return this.helpDeskAutoPlayInfos?.title ? this.helpDeskAutoPlayInfos.title : "Tutorial";
      },
      source() {
         return (
            "https://www.youtube.com/embed/" +
            this.helpDeskAutoPlayInfos.videoSource +
            "?autoplay=1"
         );
      }
   },
   methods: {
      ...mapActions(["updateUserPreference"]),
      openHelpDesk() {
         const AUTOPLAY = this.$refs.HelpDeskAutoPlay;

         if (AUTOPLAY) {
            AUTOPLAY.openModal();
         } else {
            throw "Autoplay not found.";
         }
      },
      closeHelpDesk() {
         const AUTOPLAY = this.$refs.HelpDeskAutoPlay;
         if (this.showVideo) {
            this.updateUserPreference(this.helpDeskAutoPlayInfos.id);
         }

         if (AUTOPLAY) {
            AUTOPLAY.closeModal();
            this.showVideo = false;
         } else {
            throw "Autoplay not found.";
         }
      }
   },
   filters: {
      substring(value) {
         return value.substring(0, 1000);
      }
   },
   components: {
      CardBodyV2
   },
   watch: {
      helpDeskAutoPlayInfos: {
         deep: true,
         handler(info) {
            this.closeHelpDesk();
            if (info?.autoplay) {
               this.openHelpDesk();
            }
         }
      }
   }
};
</script>
