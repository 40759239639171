const ENDPOINTS = {
   PREFIX: "custom_emails/"
};

const MSG = {
   CREATE_SUCCESS: "Email customizado criado com sucesso",
   EDIT_SUCCESS: "Email customizado editado com sucesso",
   DELETE_SUCCESS: "Email customizado deletado com sucesso",
   LIST_SUCCESS: "E-mails customizados listados com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};

import { Requests } from "@/services/requests"; // ajuste o import para o seu arquivo de Requests

export default class CustomEmailService {
   constructor(request) {
      this.request = request;
      this.moduleName = "CustomEmailService";
      this.routes = {
         createEmail: {
            create: this.createCustomEmail.bind(this)
         },
         editEmail: {
            edit: this.editCustomEmail.bind(this)
         }
      };
   }

   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async createCustomEmail(payload) {
      return this.makeRequest({
         url: ENDPOINTS.PREFIX,
         method: "post",
         body: payload,
         expectedStatus: {
            201: MSG.CREATE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async editCustomEmail(id, payload) {
      return this.makeRequest({
         url: `${ENDPOINTS.PREFIX}edit/${id}`,
         method: "put",
         body: payload,
         expectedStatus: {
            200: MSG.EDIT_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async listCustomEmails() {
      return this.makeRequest({
         url: ENDPOINTS.PREFIX,
         method: "get",
         expectedStatus: {
            200: MSG.LIST_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async deleteCustomEmail(id) {
      return this.makeRequest({
         url: `${ENDPOINTS.PREFIX}${id}`,
         method: "delete",
         expectedStatus: {
            204: MSG.DELETE_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}
