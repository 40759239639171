<template>
   <div>
      <div id="cardBodyWrapper" v2>
         <div id="modalWrapper" v-if="modal" modal>
            <ModalBuilder v-if="isModalVisible">
               <div
                  id="cardBodyComponent"
                  :class="customClass"
                  :loading="loading"
                  :remove-shadow="removeShadow"
               >
                  <v-progress-linear :active="isLoading" color="primary" :indeterminate="true" />
                  <div class="componentHeader">
                     <slot name="header" v-if="title && title === 'Fintask'">
                        <div class="headerWrapper" :align-title-top="alignTitleTop">
                           <div class="centerBlock">
                              <span v-if="title">{{ title }}</span>
                              <slot name="header-title" v-else-if="headerCustomTitle" />
                           </div>
                           <div class="rightBlock">
                              <slot name="actions" />
                           </div>
                        </div>
                     </slot>
                     <slot name="header" v-else-if="title || headerCustomTitle">
                        <div class="headerWrapper" :align-title-top="alignTitleTop">
                           <div class="leftBlock">
                              <span v-if="title">{{ title }}</span>
                              <slot name="header-title" v-else-if="headerCustomTitle" />
                           </div>
                           <div class="rightBlock">
                              <slot name="actions" />
                           </div>
                        </div>
                     </slot>
                  </div>
                  <slot name="body">
                     <div class="componentBody">
                        <slot name="content" />
                     </div>
                  </slot>
                  <slot name="footer" v-if="showFooter">
                     <div class="componentFooter">
                        <slot name="footerController">
                           <v-btn color="error" @click="cancel" text>{{ footerCancelText }}</v-btn>
                           <v-btn color="primary" dark @click="confirm">{{
                              footerConfirmText
                           }}</v-btn>
                        </slot>
                     </div>
                  </slot>
               </div>
            </ModalBuilder>
         </div>
         <div
            id="cardBodyComponent"
            :class="customClass"
            v-else
            :loading="loading"
            :remove-shadow="removeShadow"
            ref="CardComponent"
            :style="mixinPropHeaderHeight"
         >
            <v-progress-linear :active="isLoading" color="primary" :indeterminate="true" />
            <div class="componentHeader" :sticky="shouldStick" :style="headerWidth">
               <slot name="header" v-if="title || headerCustomTitle">
                  <div class="headerWrapper" :align-title-top="alignTitleTop">
                     <div class="leftBlock">
                        <span v-if="title">{{ title }}</span>
                        <slot name="header-title" v-else-if="headerCustomTitle" />
                     </div>
                     <div class="rightBlock">
                        <slot name="actions" />
                     </div>
                  </div>
               </slot>
            </div>
            <slot name="body">
               <div class="componentBody" :class="noBorderRadius ? 'noBorderRadius' : ''">
                  <slot name="content" />
               </div>
            </slot>
            <slot name="footer" v-if="showFooter">
               <div class="componentFooter">
                  <slot name="footerController">
                     <v-btn color="error" @click="cancel" text>{{ footerCancelText }}</v-btn>
                     <v-btn color="primary" dark @click="confirm">{{ footerConfirmText }}</v-btn>
                  </slot>
               </div>
            </slot>
         </div>
      </div>
   </div>
</template>

<script>
// Style
import "@/shared/scss/_cardBodyComponent.scss";
// Components
import ModalBuilder from "@/shared/components/atomicDesign/atoms/ModalBuilder.vue";
// Mixins
import CardBodyStickyFunction from "@/shared/mixins/CardBodyStickyFunction.vue";
import HelpDeskManager from "@/shared/mixins/HelpDeskManager.js";
// Store
import { mapMutations } from "vuex";

export default {
   name: "CardBody",
   components: {
      ModalBuilder
   },
   data() {
      return {
         showModal: false
      };
   },
   computed: {
      isLoading() {
         return !!this.loading;
      },
      isModalVisible() {
         return this.modal && this.showModal;
      },
      mixinPropHeaderHeight() {
         if (this.headerHeight) {
            return this.headerHeight;
         } else {
            return "";
         }
      }
   },
   methods: {
      ...mapMutations(["setHasModal"]),
      cancel() {
         this.footerCancelAction();
      },
      confirm() {
         this.footerConfirmAction();
      },
      openModal() {
         this.showModal = true;
         // this.pushStack(this.slugStack);
      },
      closeModal() {
         this.showModal = false;
         this.destroyObservableStacks(this.slugStack);
      }
   },
   watch: {
      isModalVisible(value) {
         this.setHasModal(value);
      }
   },
   mixins: [CardBodyStickyFunction, HelpDeskManager],
   props: {
      title: {
         type: [String, Boolean],
         default: false
      },
      loading: {
         type: Boolean,
         default: false
      },
      modal: {
         type: Boolean,
         default: false
      },
      "align-title-top": {
         type: Boolean,
         default: false
      },
      "custom-class": {
         type: String,
         default: ""
      },
      "header-custom-title": {
         type: Boolean,
         default: false
      },
      "remove-shadow": {
         type: Boolean,
         default: false
      },
      "show-footer": {
         type: Boolean,
         default: false
      },
      "sticky-header": {
         type: Boolean,
         default: false
      },
      "footer-cancel-text": {
         type: String,
         default: "Cancelar"
      },
      "footer-confirm-text": {
         type: String,
         default: "Confirmar"
      },
      "footer-cancel-action": {
         type: Function,
         default: () => {}
      },
      "footer-confirm-action": {
         type: Function,
         default: () => {}
      },
      "slug-stack": {
         type: [String, Boolean],
         default: false
      },
      noBorderRadius: {
         type: Boolean,
         default: false
      },
      "alter-footer": {
         type: Function,
         default: () => {
            this.showFooter = this.showFooter ? false : true;
         }
      }
   },
   created() {
      if (!this.modal) {
         // this.pushStack(this.slugStack);
      }
   },
   beforeDestroy() {
      if (!this.modal) {
         this.destroyObservableStacks(this.slugStack);
      }
   }
};
</script>
