class ReturnError {
   constructor(response) {
      this.status = response && response.status ? response.status : "400";
      this.text = response && response.text ? response.text : "";
      this.init();
   }

   init() {
      return {
         status: this.status,
         text: this.text
      };
   }
}

export { ReturnError };
