<template>
   <div id="clientAvatarComponent" v-if="registeredClient">
      <UserFigure class="medium" :userData="{ avatar, name: clientData.name }" />
      <div class="clientMeta">
         <p>
            <strong>{{ clientData.name }}</strong>
            <small v-if="lastLogin">Último Login: {{ lastLogin }}</small>
         </p>
      </div>
   </div>
</template>

<script>
// Components
import UserFigure from "@/shared/components/atomicDesign/atoms/UserFigure";
import anonymous from "@/shared/assets/user.jpeg";
import Transform from "@/utils/dateManager.js";
import "@/shared/scss/_clientAvatarComponent.scss";

export default {
   name: "ClientAvatar",
   components: {
      UserFigure
   },
   computed: {
      registeredClient() {
         return !!this.clientData;
      },
      avatar() {
         if (this.registeredClient) {
            return this.clientData.avatar || anonymous;
         }
         return null;
      },
      lastLogin() {
         if (this.clientData?.lastLogin) {
            return Transform.format(this.clientData.lastLogin);
         } else {
            return false;
         }
      }
   },
   props: {
      clientData: {
         type: [Object, Boolean],
         default: false
      }
   }
};
</script>
