<template>
   <div v-if="!isMobile" class="version-info">Versão: {{ appVersion }}</div>
   <v-dialog v-model="dialog" max-width="300px" v-else>
      <template v-slot:activator="{ on, attrs }">
         <div v-bind="attrs" v-on="on" class="version-info">Versão: {{ appVersion }}</div>
      </template>
      <v-card>
         <v-card-title>
            Versão do Aplicativo
         </v-card-title>
         <v-card-text>
            {{ appVersion }}
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import version from "@/version";

export default {
   name: "VersionInfo",
   data() {
      return {
         appVersion: version,
         dialog: false
      };
   },
   computed: {
      isMobile() {
         return this.$vuetify.breakpoint.mobile;
      }
   }
};
</script>

<style scoped>
.version-info {
   position: fixed;
   bottom: -4px;
   right: 0;
   padding: 10px;
   text-align: right;
   cursor: pointer;
   color: #a9a9a9;
}
</style>
