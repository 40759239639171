import { Requests } from "@/services/requests";
export default class Bank {
   constructor() {
      this.moduleName = "bank";
      this.prefix = "bank/";
      this.routes = {
         list: {
            get: this.listBanks.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async listBanks() {
      return this.makeRequest({
         url: this.prefix,
         method: "get"
      });
   }
}
