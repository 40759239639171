import { Utils } from "@/utils/Utils";
import Store from "@/store";
import clientLink from "./client-links";
import plannerLink from "./planner-links";
import managerLink from "./manager-links";

const canAccess = Store.getters.userCanSeeToggleFeature;

const MenuLinkGenerator = {
   /**
    * Returns client links
    *
    * @returns {Array<object>} Client menu
    */
   client() {
      return this._generateLink(clientLink);
   },

   /**
    * Returns planner links
    *
    * @returns {Array<object>} Planner menu
    */
   planner() {
      return this._generateLink(plannerLink);
   },

   /**
    * Returns the manager links
    *
    * @returns {Array<object>} Manager menu
    */
   manager() {
      return this._generateLink(managerLink);
   },

   /**
    * Creates an array list checking permissions based on toggle features.
    * Removing every link without permission.
    *
    * @param {Array<object>} linkList The role link list
    * @returns {Array<object>}
    */
   _generateLink(linkList) {
      return linkList.reduce((arr, item) => {
         if (this._readPermission(item.permission)) {
            const { name, text, icon } = item;

            arr.push({
               name,
               text,
               icon,
               permission: true
            });
         }

         return arr;
      }, []);
   },

   /**
    * Validates if the user has permission.
    *
    * @param {string} permission
    * @returns {boolean} The permission granted or denied
    *
    * @obs
    * Uses Vuex getters (userCanSeeToggleFeature) to validate permission.
    *
    * Using "!" in front of a toggle feature code will act as a negative permission.
    * @example
    * _readPermission("!my_assets_permission"): false // if my_assets_permission is true
    * _readPermission("my_assets_permission"): true // if my_assets_permission is true
    */
   _readPermission(permission) {
      if (Utils.isBoolean(permission)) {
         return permission;
      }

      if (Utils.isString(permission)) {
         if (this._deniedPermission(permission)) {
            return !canAccess(permission.substr(1));
         }
         return canAccess(permission);
      }

      return true;
   },

   /**
    * Identifies if the first letter of the code snippet is a negative statement.
    *
    * @param {string} permission The feature code snippet.
    * @returns {boolean} If the code snippet is a negative statement.
    */
   _deniedPermission(permission) {
      return permission.substr(0, 1) === "!";
   }
};

export default MenuLinkGenerator;
