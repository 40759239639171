import { Utils } from "@/utils/Utils";

const Currency = {
   /**
    * From pt-BR currency to number
    *
    * @param {string} value The string to be parsed
    * @returns {number}
    */
   toNumber(value) {
      if (Utils.isString(value)) {
         value = value.replace(/[.]/g, "").replace(/[,]/g, ".");
         return parseFloat(value);
      } else {
         return value;
      }
   },

   /**
    * From number to real
    *
    * @param {number} value The value to real
    * @returns {string} Parsed to pt-BR currency
    */
   toReal(value) {
      return value.toLocaleString(["pt-BR", "de-DE", "en-DK"], { minimumFractionDigits: 2 });
   },

   /**
    * Sums a list of numbers and returns to real value
    *
    * @param {Array>number} list The array list with number to sum
    * @returns {string} Parsed to pt-BR currency
    */
   calculateReal(list) {
      return this.toReal(list.reduce((v1, v2) => v1 + v2, 0));
   },

   /**
    * Parses an integer value (cents in pt-BR currency) to Real
    *
    * @param {number} value The value divided by 100 parsed to pt-BR
    * @returns {string} in pt-BR currency format
    */
   fromIntegerToReal(value) {
      if (Utils.isNumber(value, true)) {
         return this.toReal(parseInt(value) / 100);
      }
      return null;
   },

   /**
    * Converts from currency to raw numbers (no symbols)
    *
    * @param {string} value The value to be parsed
    * @returns {number}
    * @note The method will probably only be used for pipeline tests
    */
   rawNumbers(value) {
      if (Utils.isString(value, true)) {
         return Number(value.replace(/[,.]/g, ""));
      }
   },

   /**
    * Converts from real to number
    *
    * @param {string} value The value to be parsed
    * @returns {number}
    * @note The method will probably only be used for pipeline tests
    */
   fromRealToNumber(value) {
      if (Utils.isString(value, true)) {
         return Number(value.replace(/[.]/g, "").replace(/[,]/g, "."));
      }
   },

   /**
    * Converts from real to cents
    *
    * @param {number} value The value to be parsed
    * @returns {number}
    */
   realToCents(value) {
      return parseInt(value * 100);
   }
};

export default Currency;
