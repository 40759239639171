import Api, { Mod } from "@/services/api";
import { ReturnError } from "@/utils/error";
import { arrayForSelects } from "@/utils/helpers";

function filterValuePatrimonialExpense(filter, includeTransfer) {
   function criterio(elemento) {
      return includeTransfer
         ? elemento.is_asset_transfer === true
         : elemento.is_asset_transfer !== true;
   }

   if (!filter || filter.length === 0) {
      return filter;
   }

   for (const expense of filter) {
      for (let i = expense.expense_items.length - 1; i >= 0; i--) {
         if (!criterio(expense.expense_items[i])) {
            expense.expense_items.splice(i, 1);
         }
      }
   }
   return filter.filter(expense => expense.expense_items.length > 0);
}

export default {
   state: {
      budgetExpense: [],
      budgetIncome: [],
      budgetExpenseTransferer: [],
      budgetIncomeTransferer: [],
      budgetID: null,
      categoryList: [],
      targetMonth: null
   },

   getters: {
      budgetID: state => state.budgetID,
      budgetIncome: state => state.budgetIncome,
      budgetExpense: state => state.budgetExpense,
      budgetCategoryList: state => state.categoryList,
      targetMonth: state => state.targetMonth
   },

   actions: {
      addBudgetPlan(store, budgetPlanConfig) {
         return Mod.find("budget", "manage").add(budgetPlanConfig);
      },

      addBudgetIdeal() {
         return Mod.find("budget", "manage").ideal();
      },

      async getBudgetIdeal({ commit }, { transferer }) {
         if (transferer) {
            commit("cleanPlans");
         }
         const plans = await Mod.find("budget", "manage").getIdeal(transferer);
         if (!transferer) {
            commit("fillPlans", plans.data);
         } else {
            commit("fillPlansTransferer", plans.data);
         }

         return plans.data;
      },

      deleteBudgetPlan(store, budgetId) {
         return Mod.find("budget", "manage").delete(budgetId);
      },

      deleteBudgetCategoryPlan(store, planConfig) {
         return Mod.find("budget", "manage").deleteMultiple(planConfig);
      },

      async addBudgetItem({ dispatch }, budgetType) {
         const request = await Api.addBudgetItem({ ...budgetType });
         if (!request) throw new ReturnError({ text: "Sem conexão com servidor", status: 502 });

         if (request.status === 201) {
            dispatch("getBudgetPlan", budgetType.budget);
         } else {
            throw new ReturnError({ text: "Não foi possível criar item", status: 417 });
         }
      },

      async deleteBudgetItem({ dispatch }, { id, budget }) {
         const deleteCallback = isNaN(id) ? Api.deleteBudgetItemList : Api.deleteBudgetItem;

         const request = await deleteCallback.call(this, { id });
         if (!request) throw new ReturnError({ text: "Sem conexão com servidor", status: 502 });

         if ([200, 204].includes(request.status)) {
            dispatch("getBudgetPlan", budget);
         } else {
            throw new ReturnError({ text: "Não foi possível deletar", status: 417 });
         }
      },

      async finishBudgetPlanning({ getters }, taskID) {
         let taskStatus;

         if (getters.role.planner) {
            taskStatus = "pending";
         } else {
            taskStatus = "done";
         }

         let plans = await Api.finishBudgetPlan({ task: taskID, status: taskStatus });

         if (!plans)
            throw new ReturnError({
               text: "Sem conexão com servidor",
               status: 502
            });

         switch (plans.status) {
            case 200:
               return;
            default:
               throw new ReturnError({
                  text: "Planejamento não finalizado",
                  status: plans.status
               });
         }
      },

      async getBudgetPlan({ commit }, { budget, transferer }) {
         if (transferer) {
            commit("cleanPlans");
         }

         const plans = await Api.getBudgetPlan(budget, !!transferer);
         if (!transferer) {
            commit("fillPlans", plans.data);
         } else {
            commit("fillPlansTransferer", plans.data);
         }

         return plans.data;
      },

      async getCategoryList({ commit }) {
         let categoryList = await Api.getCategories();
         if (!categoryList)
            throw new ReturnError({
               text: "Sem conexão com servidor",
               status: 502
            });
         switch (categoryList.status) {
            case 200:
               commit("fillCategory", categoryList.data);
               return categoryList.data;
            default:
               throw new ReturnError({
                  text: "Categorias não encontradas",
                  status: 417
               });
         }
      },

      async getBudgetTask({ commit }, budget) {
         let task = await Api.getBudgetTask(budget);
         if (!task)
            throw new ReturnError({
               text: "Sem conexão com servidor",
               status: 502
            });
         switch (task.status) {
            case 200:
               commit("setTargetMonth", task.data.target_month);
               commit("setBudgetID", task.data.budget);
               return task.data.target_month;
            default:
               throw new ReturnError({
                  text: "Mês alvo não encontrado",
                  status: 400
               });
         }
      },

      async saveBudgetItem({ dispatch }, budgetData) {
         const transferer = budgetData.transfer;
         let request = await Api.saveBudgetItem(budgetData);
         if (!request)
            throw new ReturnError({
               text: "Sem conexão com servidor",
               status: 502
            });
         switch (request.status) {
            case 200:
               if (!budgetData.budget) {
                  return await dispatch("getBudgetIdeal", { transferer });
               }
               return await dispatch("getBudgetPlan", { budget: budgetData.budget, transferer });
            default:
               throw new ReturnError({
                  text: "Não foi possível editar o valor",
                  status: 417
               });
         }
      }
   },

   mutations: {
      setBudgetID(state, budgetID) {
         state.budgetID = budgetID;
      },
      cleanPlans(state) {
         state.budgetPlans = [];
      },
      clearBudgetEntries(state) {
         state.budgetExpense = [];
         state.budgetIncome = [];
         state.budgetExpenseTransferer = [];
         state.budgetIncomeTransferer = [];
      },
      fillCategory(state, categories) {
         state.categoryList = arrayForSelects([...categories], ["name", "id"]);
      },
      fillPlans(state, plans) {
         const expense = filterValuePatrimonialExpense(plans.expense_items_by_category, false);
         const income = plans.income_items.filter(income => income && !income.is_asset_transfer);

         state.budgetExpense = [...expense];
         state.budgetIncome = [...income];
      },
      fillPlansTransferer(state, plans) {
         const expense = filterValuePatrimonialExpense(plans.expense_items_by_category, true);
         const income = plans.income_items.filter(income => income && income.is_asset_transfer);

         state.budgetExpenseTransferer = [...expense];
         state.budgetIncomeTransferer = [...income];
      },

      setTargetMonth(state, month) {
         state.targetMonth = month;
      }
   }
};
