import { Mod } from "@/services/api";

export default {
   state: {
      notices: {}
   },
   getters: {
      getterNotices: state => state.notices,
      channels: state => state.notices.channels,
      notificationTrigger: state => state.notices.notificationTrigger
   },
   actions: {
      async getNotices({ commit }) {
         const req = await Mod.find("notices", "notices").get();
         commit("setNotices", req.data.data);
         return req.data.data;
      },
      async updateNotices(_dispatch, payload) {
         await Mod.find("notices", "notices").patch(payload);
      }
   },
   mutations: {
      setNotices(state, payload) {
         state.notices = payload;
      }
   }
};
