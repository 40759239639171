const isFilterProp = el => typeof el == "object" && !!el;

export default function(clientList, props) {
   clientList = clientList || false;
   if (!clientList) return false;

   let { planFilter, plannerFilter, clientFilter } = props;
   let filter = [];

   if (isFilterProp(planFilter)) {
      let thisFilter = clientList.filter(data => data.current_plan === planFilter.value);
      filter.push({ plan: thisFilter });
   }
   if (isFilterProp(plannerFilter)) {
      let thisFilter = clientList.filter(
         data => data.budget_planner.user.id === plannerFilter.value
      );
      filter.push({ planner: thisFilter });
   }
   if (isFilterProp(clientFilter)) {
      let thisFilter = clientList.filter(data => data.user.id === clientFilter.value);
      filter.push({ client: thisFilter });
   }

   if (!filter.length) {
      return false;
   } else if (filter.length === 1) {
      return Object.values(filter[0])[0];
   } else {
      let computedFilter = filter.map(item => Object.values(item)[0]);
      let groupFilter = [];

      for (let arr in computedFilter) {
         groupFilter.push(...computedFilter[arr]);
      }
      groupFilter = groupFilter.filter(item => {
         let group = [...groupFilter];

         for (let x = 0; x < filter.length - 1; x++) {
            group.splice(group.indexOf(item), 1);
         }

         return group.includes(item);
      });

      groupFilter = Array.from(new Set(groupFilter));

      return groupFilter;
   }
}
