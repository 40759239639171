import { Utils } from "@/utils/Utils";

export default {
   computed: {
      stacks() {
         return this.$store.getters.helpDeskSlug;
      }
   },
   methods: {
      destroyObservableStacks(stack = "") {
         this.removeSlugStack(stack);
      },

      openHelpDesk() {
         this.$store.commit("help");
      },

      popStack() {
         this.$store.commit("helpDeskPopStack");
      },

      pushStack(stackName = "") {
         if (Utils.isValid(stackName) && !Utils.isBoolean(stackName)) {
            this.$store.commit("helpDeskPushStack", stackName);
         }
      },

      removeSlugStack(slug) {
         this.$store.commit("helpDeskRemoveSlugStack", slug);
      },

      _stackInit() {
         this.$store.commit("helpDeskResetStack");
      }
   },
   watch: {
      $route() {
         this._stackInit();
         // this.pushStack(this.$route.meta.slug);
      }
   },
   beforeDestroy() {
      this.destroyObservableStacks();
   },
   created() {
      // this.pushStack(this.$route.meta.slug);
   }
};
