const pendency = [
   {
      filename: "Nomearquivo1.ofx",
      fileId: 1,
      pendency: [
         { balance: 100, dueDate: null, closeDay: 21, newAccount: 1 },
         { balance: 100, dueDate: "15/09/2021", closeDay: 21, newAccount: 2 }
      ]
   },
   {
      filename: "Nomearquivo2.ofx",
      fileId: 2,
      pendency: [
         {
            balance: 100,
            dueDate: "15/09/2021",
            closeDay: 21,
            newAccount: 3
         },
         { balance: 100, dueDate: "15/09/2021", closeDay: 21, newAccount: 4 }
      ]
   }
];

export default pendency;
