export default class Profile {
   constructor(request) {
      this.moduleName = "profiles";
      this.prefix = "profiles/";

      this.extends = {
         clients: "clients/",
         email: "email/",
         confirm: "confirm/",
         planner: "planner/",
         password: "check-password/",
         customer: "customer-manager"
      };

      this.request = request;

      this.routes = {
         email: {
            confirm: this.confirmEmail
         },
         planner: {
            list: this.getPlannerList,
            update: this.updatePlanner
         },
         password: {
            check: this.checkPassword
         },
         clients: {
            get: this.getClients,
            setPlanner: this.changeClientPlanner
         },
         subscription: {
            cancel: this.cancelSubscription
         }
      };
      this.bind(this.routes, this);
   }
   async cancelSubscription() {
      const expectedStatus = {
         200: "Assinatura cancelada",
         default: "Não foi possível cancelar sua assinatura"
      };

      return await this.request.post(
         {
            url: this.prefix + this.extends.clients + "cancel-subscription/"
         },
         expectedStatus
      );
   }
   async confirmEmail(payload) {
      const expectedStatus = {
         200: "E-mail verificado",
         default: "Erro na leitura do token"
      };

      return await this.request.POST(
         {
            url: this.prefix + this.extends.email + this.extends.confirm,
            body: { ...payload }
         },
         expectedStatus
      );
   }
   async getPlannerList() {
      const expectedStatus = {
         200: "Atualizado",
         404: "Nenhum planejador",
         default: "Erro ao buscar planejadores"
      };

      return await this.request.GET(
         {
            url: this.prefix + this.extends.planner
         },
         expectedStatus
      );
   }

   async updatePlanner({ id, status }) {
      const expectedStatus = {
         200: "Alterado com sucesso",
         default: "Erro ao alterar"
      };

      return await this.request.PUT(
         {
            url: `${this.prefix}${this.extends.customer}?clientId=${id}`,
            body: { status }
         },
         expectedStatus
      );
   }
   async checkPassword(payload) {
      const expectedStatus = {
         200: "Verifcado",
         default: "Erro inesperado"
      };

      return await this.request.POST(
         {
            url: this.prefix + this.extends.password,
            body: { ...payload }
         },
         expectedStatus
      );
   }
   async getClients(profile) {
      const expectedStatus = {
         200: "Clientes recebidos",
         default: "Sistema indisponível. Tente novamente mais tarde."
      };

      profile = profile || "planner";
      let query = "";

      switch (profile) {
         case "planner":
            query = "?isActive=true";
            break;
         case "manager":
            query = "?profile=manager";
            break;
      }

      return await this.request.GET(
         {
            url: this.prefix + this.extends.clients + query
         },
         expectedStatus
      );
   }
   async changeClientPlanner(payload) {
      const expectedStatus = {
         200: "Verifcado",
         default: "Erro inesperado"
      };

      const endpoint = "change-planner/?profile=manager";

      return await this.request.PUT(
         {
            url: this.prefix + this.extends.clients + endpoint,
            body: { ...payload }
         },
         expectedStatus
      );
   }
}
