<template>
   <div id="logoPicker">
      <croppa
         v-model="myCroppa"
         class="mb-2"
         ref="croppa"
         placeholder="Clique aqui para inserir sua logo"
         :placeholder-font-size="18"
         :width="300"
         :accept="'.png, .bmp, .jpeg, .jpg'"
         :file-size-limit="2097152"
         :zoom-speed="3"
         :prevent-white-space="false"
         :show-remove-button="true"
         :disable-scroll-to-zoom="true"
         initial-size="contain"
         @image-remove="persona.organization.logo = oldLogo"
         @new-image-drawn="handleCroppaFileChoose"
         @move="handleCroppaFileChoose"
         @zoom="handleCroppaFileChoose"
      ></croppa>

      <div class="controlers">
         <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  large
                  icon
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click="myCroppa.zoomIn()"
                  :disabled="imgUrl == ''"
                  ><v-icon>mdi-plus</v-icon></v-btn
               >
            </template>
            <span>Aumentar zoom</span>
         </v-tooltip>
         <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                  large
                  icon
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="myCroppa.zoomOut()"
                  :disabled="imgUrl == ''"
                  ><v-icon>mdi-minus</v-icon></v-btn
               >
            </template>
            <span>Diminuir zoom</span>
         </v-tooltip>
      </div>
   </div>
</template>

<script>
//Assets
import "@/shared/scss/_logoPicker.scss";

//Core
import { mapGetters, mapMutations } from "vuex";

export default {
   name: "LogoPicker",
   data() {
      return {
         myCroppa: null,
         imgUrl: "",
         oldLogo: null
      };
   },
   computed: {
      ...mapGetters(["persona"])
   },
   methods: {
      ...mapMutations(["setInfo"]),
      handleCroppaFileChoose() {
         const original = this.myCroppa.$el.firstChild.files[0];
         this.oldLogo = this.persona.organization.logo;
         let url = this.myCroppa.generateDataUrl();
         if (!url) {
            alert("Selecione uma imagem");
            return;
         }
         this.imgUrl = url;
         this.persona.organization.logo = url;
         let logo = this.dataURLtoFile(url, original.name);
         this.$emit("updated", logo);
      },
      dataURLtoFile(dataurl, filename) {
         let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

         while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
         }

         return new File([u8arr], filename, { type: mime });
      }
   }
};
</script>
