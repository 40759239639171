<script>
// Core
import { mapActions, mapGetters } from "vuex";

export default {
   name: "MixinWatchClientId",
   data() {
      return {
         storedClientData: null,
         clientList: null,
         clientId: null
      };
   },
   computed: {
      ...mapGetters(["role"]),
      clientData() {
         if (this.storedClientData) {
            const CLIENT = this.storedClientData;

            let bundle = CLIENT.last_import_bundle;

            return {
               name: `${CLIENT.user.first_name} ${CLIENT.user.last_name}`,
               avatar: CLIENT.profile_picture,
               lastImport: bundle ? bundle.created_at : null,
               lastLogin: CLIENT.last_login
            };
         } else {
            return null;
         }
      }
   },
   methods: {
      ...mapActions(["getActiveClients"]),

      recieveClients(clientList) {
         this.clientList = clientList;

         let filter = this.clientList.filter(c => c.user.id == this.clientId);
         this.storedClientData = filter[0] || null;
      },
      userHasPlannerAuth() {
         let hasAccess = false;

         if (this.role.planner || this.role.manager) {
            hasAccess = true;
         }
         return new Promise(resolve => resolve(hasAccess));
      },
      updateClient() {
         let hasAccess = this.role.planner || this.role.manager;

         if (hasAccess) {
            const ROUTE = this.$route;

            const QUERY = ROUTE.query || null;
            const PARAM = ROUTE.params || null;

            if (QUERY || PARAM) {
               let variations = ["clientId", "clientID", "client"];

               let QueryClient = variations.filter(v => !!QUERY && QUERY[v])[0];
               let ParamClient = variations.filter(v => !!PARAM && PARAM[v])[0];

               this.clientId = QUERY[QueryClient] || PARAM[ParamClient] || null;
            }
            this.getClientData();
         }
      },
      getClientData() {
         if (this.clientId) {
            this.getActiveClients()
               .then(clients => this.recieveClients(clients))
               .catch(e => {
                  throw e;
               });
         } else {
            this.storedClientData = null;
         }
      }
   },
   watch: {
      $route() {
         this.updateClient();
      },
      role() {
         this.updateClient();
      }
   },
   created() {
      this.updateClient();
   }
};
</script>
