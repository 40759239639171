import Access from "./access";
import store from "@/store/index";

const profiles = new Access();

const userInfo = () => store?.getters?.persona;

profiles.set(userInfo);

profiles.setDefault("visitor");
profiles.grant("visitor").level(0);

profiles.grant("client").level(1);

profiles
   .grant("planner")
   .extend("client")
   .level(2);

profiles
   .grant("manager")
   .extend("planner")
   .extend("client")
   .level(3);

export default profiles;

export const roles = profiles.roles;
