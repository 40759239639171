//Mocks
import Mock from "../../__mocks__/calendar-events";
//Requests
import { Mod } from "@/services/api";
//Helpers
import Events from "@/utils/events";

import vuetify from "@/plugins/vuetify";

const TESTING = false;

export default {
   state: {
      events: []
   },
   getters: {
      events: state =>
         state.events.map(event => {
            return {
               name: event.title,
               start: event.reminder_date,
               end: event.reminder_date,
               color: vuetify.framework.theme.themes.light.primary,
               ...event
            };
         })
   },
   actions: {
      async getEvents({ commit }) {
         let req;

         if (TESTING) {
            req = await Mock.events();
         } else {
            req = await Mod.find("reminder", "reminder").get();
         }

         commit("setEvents", req.data.data);
      },
      async deleteEvent({ dispatch }, id) {
         if (TESTING) {
            await Mock.deleteEvent(id);
         } else {
            await Mod.find("reminder", "reminder").delete(id);
         }
         await dispatch("getEvents");
      },
      async createEvent({ dispatch }, event) {
         await Mod.find("reminder", "reminder").create(event);
         await dispatch("getEvents");
      },
      async updateEvent({ dispatch }, event) {
         await Mod.find("reminder", "reminder").update(event);
         await dispatch("getEvents");
      },
      async createRecommend({ dispatch, commit }) {
         await Mod.find("reminder", "reminder")
            .recommend()
            .then(async res => {
               commit("setAlert", {
                  text: res.answer
               });
               await dispatch("getEvents");
            });
      }
   },
   mutations: {
      setEvents(state, events) {
         Events.setEvents(events);
         state.events = Events.createPeriodicity();
      }
   }
};
