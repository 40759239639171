// store.js or store/index.js
export default {
   state: {
      favicon: null
   },
   actions: {
      setFavicon({ state }, faviconUrl) {
         const link = document.querySelector("link[rel*='icon']");

         if (link && link.href === faviconUrl) return;
         state.favicon = faviconUrl;

         const newLink = link || document.createElement("link");
         newLink.type = "image/x-icon";
         newLink.rel = "shortcut icon";
         newLink.href = faviconUrl || "/img/icons/favicon-16x16.png";

         if (!link) {
            document.getElementsByTagName("head")[0].appendChild(newLink);
         }
      }
   }
};
